import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ThemeService } from 'src/app/services/theme.service';
import { RegistersuccessComponent } from '../../userregister/registersuccess/registersuccess.component';
import { PAYPAL_FEE, STRIPE_FEE } from 'src/app/services/constants';
import { WarningPopupComponent } from '../../collections/warning-popup/warning-popup.component';
import { PopupService } from 'src/app/services/popup.service';
import { UtilsService } from 'src/app/services/utils.service';


@Component({
  selector: 'app-addbalance',
  templateUrl: './addbalance.component.html',
  styleUrls: ['./addbalance.component.scss']
})
export class AddbalanceComponent implements OnInit {
  amount: any;
  currentUser: any;
  selectedPaymentOption: string;
  methodSelected: boolean = true;
  amountAdded: boolean = true;
  error: boolean = false;
  rechargeAmount: number = 0;
  payment: boolean;
  isInIframe: boolean;
  onlineFees: number;

  constructor(public dialogRef: MatDialogRef<any>, private authService: AuthService, public dialog: MatDialog,
    private router: Router,
    private themeService: ThemeService,
    private popupService: PopupService,
    private utilsService: UtilsService
    ) { 
    this.isInIframe = this.themeService.isInIframe();
    }

  ngOnInit(): void {
    this.authService.user.subscribe(user => this.currentUser = user);
    if (this.currentUser?.id) {
      this.currentUser = this.currentUser;
    }

    if (localStorage.getItem('taskData')) {
      this.payment = true;
    } else this.payment = false;
  }
  somethingChanged(event: any): void {
    this.selectedPaymentOption = '';
    this.methodSelected = true;
    this.amount = event.target.value;
    this.amountAdded = true;
    const pattern = /^(\d+(\.\d{0,2})?)$/;
    if (this.amount) {
      if (!pattern.test(this.amount)) {
        this.error = true;
      } else this.error = false;
    } else {
      this.amountAdded = true;
      this.error = false;
    }

    if (this.amount > 0) {
      this.amountAdded = false;
      this.rechargeAmount = parseFloat(JSON.parse(this.amount.replace(/^0+/, '')));      
    } else this.amountAdded = true;
  }
  onNoClick(): void {
    this.dialogRef.close("NO");
  }

  deposit(): void {
    let data: any;
    if (this.selectedPaymentOption != 'Bank Transfer') {
    this.authService.addMoneyToWallet(this.rechargeAmount, this.currentUser, this.selectedPaymentOption,0,0,this.rechargeAmount).subscribe((response) => {
      data = response;
      if (data?.['paypalUrl']) {
        window.location.href = data?.['paypalUrl'];
      }
      else if (data?.['stripeUrl']) {
        window.open(data.stripeUrl, "_self")
      }
      localStorage.setItem('paymentMethod', this.selectedPaymentOption);
      localStorage.setItem('keyType', 'recharge');
      localStorage.setItem('stripeId', data?.stripeId);
      this.amount = 0;
    })
    this.dialogRef.close("OK");
  } else {
    const dialogRef = this.dialog.open(RegistersuccessComponent, {
      data: { user: this.currentUser, type: 'bank', process: 'deposit' },
      backdropClass: 'blurred',
      disableClose: true,
      width: '60%',
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      this.methodSelected = true;
      if (result === 'yes') {
        this.authService
        .addMoneyToWallet(
          this.rechargeAmount,
          this.currentUser,
          this.selectedPaymentOption,
          0,0,
          this.rechargeAmount
        )
        .subscribe((response) => {
          this.dialogRef.close("OK");
          this.router.navigate(['/deposits']);
        });
      } else {
        this.selectedPaymentOption = '';
      }
    });
  }
  }

  select() {
    this.methodSelected = false;
    if (this.selectedPaymentOption === 'PayPal') this.onlineFees = PAYPAL_FEE;
    else if (this.selectedPaymentOption === 'Stripe') this.onlineFees = STRIPE_FEE;

    let grandTotalWithCharges = (this.rechargeAmount / this.onlineFees);
    let additionalCharge = (grandTotalWithCharges - this.rechargeAmount); 

    if (this.selectedPaymentOption != 'Bank Transfer') {
      const additionalChargeFormatted = `<span style="color: #3f9aff; font-weight: 500">$${additionalCharge.toFixed(2)}</span>`;
      const message = `The ${this.selectedPaymentOption} charge for this transaction is ${additionalChargeFormatted}.`;
      const type = 'pleaseNote'
      this.popupService.openPopup(WarningPopupComponent, message, type, '40%');  
    }
  }
}
