import { Component, Input, OnInit } from '@angular/core';
import { OPERATORS } from '../services/constants';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent implements OnInit {
  @Input() metadata: any;
  @Input() product: any;
  @Input() operator: any;
  @Input() refreshDataType: any;
  operators: any;

  constructor() {
    this.operators = OPERATORS;
   }

  ngOnInit(): void {
  }

  getObjectEntries(obj: any): any[] {
    if (obj != null)
    return Object.entries(obj);
    else return [];
  }

  formatKey(key: string): string {
    return key.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

}
