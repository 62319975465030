import { ElementRef, Component, OnInit, Input, ViewChild, Renderer2, ViewEncapsulation } from '@angular/core';
import { Cartesian3, Color } from 'cesium';
import * as Cesium from 'cesium';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OpenconfirmwarningComponent } from './openconfirmwarning/openconfirmwarning.component';
import { environment } from 'src/environments/environment';
import { map, Observable, startWith, interval, Subscription } from 'rxjs';
import { OPERATORS, RF_RADIUS } from 'src/app/services/constants';
import { HttpClient } from '@angular/common/http';
import { CollectionPopupComponent } from '../collections/collection-popup/collection-popup.component';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { CountrycheckComponent } from '../taskcost/countrycheck/countrycheck.component';
import { ConfirmDialog } from '../taskcost/confirmdialog';
import { ThemeService } from 'src/app/services/theme.service';
import { DatePipe } from '@angular/common';
import { UtilsService } from 'src/app/services/utils.service';
import { PopupService } from 'src/app/services/popup.service';
import { WarningPopup } from '../taskcost/warning-popup/warning-popup';
import { BudgetpopupComponent } from './budgetpopup/budgetpopup.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { NgxMaterialTimepickerComponent } from 'ngx-material-timepicker';
import { OperatorPopupComponent } from '../taskcost/operator-popup/operator-popup.component';
import { TaskcostSensorPreviewComponent } from './taskcost-sensor-preview/taskcost-sensor-preview.component';

const cesiumContainerNew = document.createElement('div');
cesiumContainerNew.id = 'cesiumGlobeContainerNew';
document.getElementsByTagName('body')[0].appendChild(cesiumContainerNew);
var turf = require('@turf/turf');
var geoParse = require('wellknown');

export interface ProjectModel {
  project: string;
  _id: number;
  customerid: any,
}

@Component({
  selector: 'app-newtask',
  templateUrl: './newtask.component.html',
  styleUrls: ['./newtask.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class NewtaskComponent implements OnInit {
  @Input() target: any = { geoJSON: '', area: 0 };
  @Input() accept = '.topojson, .geojson, .kml';
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('llInput') llInput: ElementRef;
  @ViewChild('cloudPanel') cloudPanel: MatExpansionPanel;
  @ViewChild('timepicker', { static: true }) timepicker: NgxMaterialTimepickerComponent;
  drawingMode = '';
  mapSnap: any;
  defaultLL: any[] = [82.9739, 25.3176];
  roll: any = 0;
  jumpLocation: any;
  locationInvalid = true;
  keyIn = false;
  dateFlag = false;
  isFirst = true;
  accuracy = 15;
  jsonData = '';
  fileAttr = 'Choose File';
  acceptFile = '.topojson, .geojson, .kml';
  MYENTITY;
  drawingOn = false; // AT21 = false; satllogic = false;
  viewer: any;
  scene: any = null;
  longLatStr: string = '';
  latlonOverlay = document.createElement('div');
  satEntity;
  datafile: any;
  imgUrls: any;
  dataForm: FormGroup | any;
  options: any;
  compassOverlay: any;
  currentUser: any;
  projName: any;
  taskName: any;
  taskTarget: any;
  frequency: any;
  taskArea: 0;
  formGroup: any = FormGroup;
  taskparams: any;
  date: any;
  proceed = false;
  frequencyOption = [
    { id: 0, name: 'Specific Date', selected: true },
    { id: 1, name: 'Recurrent', selected: false },
    { id: 2, name: 'Period', selected: false },
  ];
  selectedOption = 0;
  freqOption = 'Daily';
  showResults = false;
  DateFilter: any;
  DateRange = 30;
  monthRange = 13;
  minDate: Date;
  maxDate: Date;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  defaultWindow = 30; //30 days
  sensors: any = this.apiService.selectSensors;
  TASKFREQUENCY: any;
  purposeOption: any;
  intentOption: any;
  useCaseOption: any;
  strategyOptions: any
  selectedSensorTypes: any;
  allComplete: boolean = false;
  state: any;
  showSensors = false;
  tasking: FormGroup;
  panelOpenState = true;
  isExpanded = true;
  taskid: any;
  coordinates: string;
  ph: string;
  title = 'Confirmation';
  cancelBtn = 'Cancel Task';
  btnParams = 'CANCEL';
  successMsg: any;
  taskMap: any;
  chkNum = 0;
  dailyCount: any;
  todayDate: Date;
  clickFrequency: boolean = false;
  others: boolean = false;
  description: any;
  purposeTask: any;
  projectData: ProjectModel[] = [];
  filteredProjects: Observable<ProjectModel[]>;
  buttonDisable: boolean = false;
  callCounter: any = 0;
  geocoder: Cesium.Geocoder;
  satellites: any[] = [];
  satelliteEntity: any;
  entityValues: any;
  cesiumToken: any;
  orbitalPath: any;
  randomColor: string;
  subDaily: boolean = false;
  taskStrategy: any;
  tipAndCueBasedOn: string;
  observationSection: boolean = false;
  observationOptions: any;
  rulesOptions: any;
  observations: any;
  ruleBased: any;
  parentVal: any;
  observation: any;
  rule: any;
  pointArray: any[] = [];
  pointEntities: any[] = [];
  isDrawingEnabled: boolean = false;
  retask: boolean;
  selectedTasks: any;
  polygons: number[][][] = [];
  weatherData: any = [];
  cloudclicked: boolean;
  outComeForm: any = FormGroup;
  SensorsTypes = this.apiService.SENSORSTYPE;
  demoView: boolean = false;
  outComesensor: any;
  outComeError: boolean = true;
  expectedAge: any;
  filteredSensorTypes: any[];
  outComeSubmit: boolean = false;
  darkTheme: boolean;
  tooltipImagePath: string;
  isInIframe: boolean;
  isChecked: boolean = false;
  hourly: boolean = true;
  timeError: number;
  isPast: boolean = false;
  isEndPast: boolean = false;
  measurmentTooltipEntities: any[] = [];
  measurmentEntities: any[] = [];
  entityId = '';
  drawStarted = false;
  drawCompleted = false;
  isSearched = false;
  pinDisable: boolean = false;
  handler: any;
  restrictedUser: boolean = false;
  shapeSelected: boolean = false;
  budget: boolean;
  budgetForm:  any = FormGroup;
  budgetInputs: any={resolutionArr:[],cloudCoverKeys:[],holdbackArr:[],tierArr:[]};     
  cloudCoverOptions: string[] = []; 
  resolutionOptions: string[] = [];
  taskingTierOptions: string[] = [];
  holdBackOptions: string[] = [];     
  budgetSensorType:any;                                              
  selectedFrequencyID:number;
  currentLocalTime: string;
  private timeSubscription: Subscription;
  taskPoint: number = 0;
  totalTaskNumber: number = 0;
  updateFrequencies: any[] = this.apiService.updateFrequencies;
  outcomePopUpData:any = {};
                                                                  
  constructor(private el: ElementRef, private apiService: ApiService, private userService: UserService,
    public dialog: MatDialog, private http: HttpClient, private route: ActivatedRoute, private dataService: DataService,
    private renderer: Renderer2,
    private themeService: ThemeService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private utilsService: UtilsService,
    private popupService: PopupService,
    private router: Router,
    ) {
      this.isInIframe = this.themeService.isInIframe();

    this.dataService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;

      this.tooltipImagePath = this.darkTheme ? '../../../assets/images/union-dark.svg' : '../../../assets/images/union.svg';
    });

    if (environment.demoView === 'true') {
      this.demoView = true;
    }
    else {
      this.demoView = false;
    }

    this.retask = false;
    this.budget = false;
    if (this.route.snapshot.url.join('/').includes('retask')) {
      this.retask = true;
      const data = localStorage.getItem('rowData');
      if (data != null) {
        this.selectedTasks = JSON.parse(data);
      }
    } else {
      this.retask = false;
    }

      if (this.route.snapshot.url.join('/').includes('newbudget')) {
        this.budget = true;
      } else {
        this.budget = false;
      }

    if (!this.ph) {
      this.ph = 'init';
    }
    this.cesiumToken = environment.cesiumAccessToken;
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();

    this.date = new FormControl(new Date(year, month - 1, day + 1));

    this.range = new FormGroup({
      start: new FormControl(new Date(year, month - 1, day + 1)),
      end: new FormControl(new Date(year, month - 1, day + this.defaultWindow)),
    });

    this.formGroup = new FormGroup({
      project: new FormControl(this.projName, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(25),
      ]),
      taskname: new FormControl(this.taskName, [
        Validators.required,
        Validators.minLength(5)
      ]),
      strategyRadio: new FormControl('', [Validators.required]),
      tipAndCueBasedOn: new FormControl(this.tipAndCueBasedOn),
      description: new FormControl(this.description, [Validators.maxLength(50),]),
      target: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      observation: new FormControl(this.observation),
      rule: new FormControl(this.rule),
      purpose: new FormControl(this.purposeTask, [Validators.required]),
      frequency: new FormControl(this.frequency, [Validators.required]),
      jumplocation: new FormControl(this.jumpLocation),
      start: new FormControl(new Date(year, month - 1, day + 1)),
      end: new FormControl(new Date(year, month - 1, day + this.defaultWindow)),
      projectId: new FormControl(''),
      intent: new FormControl('', [Validators.required]),
      startTime: new FormControl('', [Validators.required]),
      endTime: new FormControl('', [Validators.required]),
      useCase: new FormControl(''),
      useCaseDescription: new FormControl('', [Validators.maxLength(50),]),
    });

    this.updateFormControls(this.taskStrategy);
    this.formGroup.get('strategyRadio')?.valueChanges.subscribe((value) => {
      this.updateFormControls(value);
    });

    this.authService.user.subscribe(user => this.currentUser = user);
    this.outComeForm = new FormGroup({
      sensorType: new FormControl(),
      resolution: new FormControl(),
      cloudCover: new FormControl(),
      latency: new FormControl('', [Validators.required, Validators.min(24), Validators.pattern(/^[0-9]\d*$/)]),
      budget: new FormControl('', [Validators.required, Validators.min(this.currentUser?.pointsEligible ? this.convertToPoints(150) : 150), Validators.pattern(/^[0-9]\d*(\.\d+)?$/)]),
      updateFrequency: new FormControl(),
    });

    this.budgetForm = new FormGroup({
      target: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      frequency: new FormControl(this.frequency, [Validators.required]),
      start: new FormControl(new Date(year, month - 1, day + 1)),
      end: new FormControl(new Date(year, month - 1, day + this.defaultWindow)),
      startTime: new FormControl('', [Validators.required]),
      endTime: new FormControl('', [Validators.required]),
      resolution: new FormControl(''),
      taskingTier: new FormControl(''),
      cloudCover: new FormControl(''),
      holdBack: new FormControl(''),
      sensorType: new FormControl('',[Validators.required]),
      intent: new FormControl('',[Validators.required])
    });

    this.outComeForm.valueChanges.subscribe(() => {
      if (this.isAnyFieldEmpty()) {
        this.outComeError = true;
      } else {
        this.outComeError = false;
      }
    });

    this.getProjects();
    this.filteredProjects = this.formGroup.get('project').valueChanges
      .pipe(startWith(null), map((data: string) => data ? this._filter(data) : this.projectData.slice())
      );

      this.setUTCTodayDate();
      this.currentLocalTime = moment().format('HH:mm A');
  }

  getProjects() {
    this.apiService.getProjects().subscribe((res: any) => {
      this.projectData = res.result;
    });
  }

  private _filter(value: string): ProjectModel[] {
    const filterValue = value.toString().toLocaleLowerCase();
    return this.projectData.filter((data) => data.project?.toLocaleLowerCase().indexOf(filterValue) === 0);
  }

  setProjectid(value) {
    this.formGroup.controls.projectId.setValue(value._id);
    this.target.projectId = value._id;
    this.projName = value.project;
    this.updateTarget(this.target, '0');
  }

  updateFormControls(taskStrategy: string): void {
    const useCaseControl = this.formGroup.get('useCase');
    if (taskStrategy === 'Help Select Sensor(s)') {
      useCaseControl?.setValidators(Validators.required);
    } else {
      useCaseControl?.clearValidators();
    }
    useCaseControl?.updateValueAndValidity();
  }

  ngOnInit(): void {
    const cesiumContainerNew = document.createElement('div');

    cesiumContainerNew.id = 'cesiumGlobeContainerNew';
    document.getElementsByTagName('body')[0].appendChild(cesiumContainerNew);
    this.TASKFREQUENCY = this.apiService.TASKFREQUENCY;
    this.apiService.getObservations().subscribe((res: any) => {
      this.observationOptions = res.observations;
      this.rulesOptions = res.ruleBased;
    });

    this.apiService.getPurpose().subscribe((res: any) => {
      this.purposeOption = this.utilsService.sortPurposeOptions(res?.purposeList);
    });

    this.intentOption = this.apiService.intentOption;
    this.strategyOptions = this.apiService.strategyOptions;
    this.dailyCount = this.apiService.dailyCount;
    var west = 40.0;
    var south = -20.0;
    var east = 120.0;
    var north = 70.0;
    var _this = this;

    var rectangle = Cesium.Rectangle.fromDegrees(west, south, east, north);
    Cesium.Ion.defaultAccessToken = this.cesiumToken;
    Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
    Cesium.Camera.DEFAULT_VIEW_RECTANGLE = rectangle;

    const imageryViewModels: any = [];
    const terrainViewModels: any = [];

    //Bing Maps Aerial
    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'Bing Maps Aerial',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/bingAerial.png'
        ),
        tooltip: 'Bing Maps Aerial',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 2 });
        },
      })
    );

    //Sentinel data
    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'Sentinel-2',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/sentinel-2.png'
        ),
        tooltip: 'Sentinel-2 cloudless',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 3954 });
        },
      })
    );

    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'Bing Maps Road',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/bingRoads.png'
        ),
        tooltip: 'Bing Maps Road',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 4 });
        },
      })
    );
    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'Blue Marble',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/blueMarble.png'
        ),
        tooltip: 'Blue Marble Next Generation July, 2004 imagery from NASA.',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 3845 });
        },
      })
    );

    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'OpenStreetMap',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/openStreetMap.png'
        ),
        tooltip: 'Open Street Map.',
        creationFunction: function () {
          return new Cesium.OpenStreetMapImageryProvider({
            url: 'https://a.tile.openstreetmap.org/',
          });
        },
      })
    );
    const worldTerrain = Cesium.createWorldTerrain({ requestWaterMask: false });

    terrainViewModels.push(worldTerrain);
    var credit = new Cesium.Credit(
      "<a style='padding-left: 3px' class='credit' target='_blank' href='https://tasking.eartheye.space'><img width='120px' src='assets/images/white-logo.png' alt='EarthEye'></a>"
    );



    const viewer = new Cesium.Viewer('cesiumGlobeContainerNew', {
      imageryProviderViewModels: imageryViewModels,
      terrainProviderViewModels: [],
      // imageryProvider: baseLayer,
      // terrainExaggeration: 2.0,
      // terrainProvider: worldTerrain,
      baseLayerPicker: true,
      geocoder: true,
      homeButton: true,
      // navigationHelpButton: false,
      fullscreenButton: true,
      selectionIndicator: true,
      shouldAnimate: true,
      animation: false,
      timeline: false,
      sceneMode: Cesium.SceneMode.SCENE2D,
      sceneModePicker: true
    });

    this.viewer = viewer
    if (!this.isInIframe) {
      this.viewer.scene.frameState.creditDisplay.addDefaultCredit(credit);
    }
    this.showLatLon();

    var target: any = this.target;
    this.viewer.dataSources.dataSourceAdded.addEventListener(function (
      collection,
      dataSource
    ) {
      var entities = dataSource.entities.values;
      for (var i = 0; i < entities.length; i++) {
        var entity = entities[i];
        if (entity.polygon) {
          // material : Cesium.Color.YELLOW.withAlpha(0.5),
          // entity.polygon.color = Cesium.Color.RED;
          entity.polygon.material = Cesium.Color.BLUE.withAlpha(0.5);
          createAOI(entity.polygon.hierarchy.valueOf().positions);
        }
      }
      function createAOI(cartesianPts) {
        const accuracy = 15;
        let aoiWkt = 'POLYGON ((';
        let polyPts: any = [];
        let lat, lng;

        for (let i = 0; i < cartesianPts.length; i++) {
          let cartoPoint = Cesium.Cartographic.fromCartesian(cartesianPts[i]);
          lng = Cesium.Math.toDegrees(cartoPoint.longitude);
          lat = Cesium.Math.toDegrees(cartoPoint.latitude);
          aoiWkt = aoiWkt + `${lng.toFixed(accuracy)} ${lat.toFixed(accuracy)}`;
          if (i < cartesianPts.length - 1) aoiWkt = aoiWkt + ', ';
          polyPts.push([lng, lat]);
        }

        aoiWkt = `${aoiWkt}))`;
        var polygon = turf.polygon([polyPts]);
        var aoiArea = (turf.area(polygon) / 1000000).toFixed(2); //in Sq km
        target.geoJSON = aoiWkt;
        // target.area = aoiArea;
        const aoi = { task: 'newtask', area: aoiArea, wkt: aoiWkt };
        getCountryAndPlace(lat, lng);
      }

      function getCountryAndPlace(latitude: number, longitude: number) {
        const apiKey = environment.openCageApiKey;
        const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`;
        let place: any, country: any;
        let location = {
          longitude : longitude,
          latitude : latitude
        }

        _this.apiService.checkIsWater(location).subscribe((res: any) => {
        target.openWater = res?.water;
        fetch(url)
          .then(response => {
            if (!response.ok) {
              throw new Error('Request failed');
            }
            return response.json();
          })
          .then(data => {
            if (data.results.length > 0) {
              const firstResult = data.results[0];
              if (firstResult.components?.body_of_water) {
                country = firstResult.components.body_of_water;
                place = firstResult.components.body_of_water;
             } else {
                country = firstResult.components.country;
                place = firstResult.components.city || firstResult.components.state || firstResult.components.county;
             }
              target.place = place;
              target.country = country;
              _this.updateTarget(target, '0');
            }
          })
          .catch(error => { });
        });
      }
    });



    this.scene = this.viewer.scene;
    this.scene = this.viewer.scene;
    if (!this.scene.pickPositionSupported) {
      window.alert('This browser does not support pickPosition.');
    }
    this.plotTarget();

    // Show point when type lat and long
    this.geocoder = this.viewer.geocoder;

    this.geocoder.viewModel.destinationFound = (viewModel, destination) => {
      this.isSearched = true;
      this.pinDisable = true;
      var positionString = viewModel.searchText.trim();
      var positionArray = positionString.split(',');
      const isValid = isArrayValid(positionArray);

      function isArrayValid(arr: string[]): boolean {
        const regex = /^-?\d*\.?\d+\s*-?\d*\.?\d+$/;
        return arr.every((str) => regex.test(str.trim()));
      }

      if (positionArray?.length === 2 && isValid && !this.retask) {
        this.resetMap();
        var longitude = parseFloat(positionArray[0]);
        var latitude = parseFloat(positionArray[1]);
        const accuracy = 15;
        let poiWkt = `POINT (${longitude.toFixed(accuracy)} ${latitude.toFixed(accuracy)})`;
        target.geoJSON = poiWkt;
        target.area = 0;
        // this.updateTarget(target, '1');
        var pin = this.viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(longitude, latitude),
          billboard: {
            image: 'assets/images/marker-icon.png',
            show: true,
            eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            alignedAxis: Cesium.Cartesian3.ZERO,
            width: 20,
            height: 30,
          },
        });
        this.viewer.flyTo(pin, {
          offset: new Cesium.HeadingPitchRange(0, Cesium.Math.toRadians(-20), 5000),
          duration: 3, // Adjust the duration as needed
        });
        this.getCountryAndPlace(latitude,longitude,'search');
        if (this.restrictedUser) {
          let mousePoint = [longitude, latitude];
          this.checkPointInsidePolygon(mousePoint)
        }
      } else if (!this.retask) {
        this.resetMap();
        let centerLongitude, centerLatitude;

        if (destination instanceof Cesium.Rectangle) {
          const center = Cesium.Rectangle.center(destination);
          centerLongitude = Cesium.Math.toDegrees(center.longitude);
          centerLatitude = Cesium.Math.toDegrees(center.latitude);
        }

        const accuracy = 15;
        let poiWkt = `POINT (${centerLongitude.toFixed(accuracy)} ${centerLatitude.toFixed(accuracy)})`;
        target.geoJSON = poiWkt;
        target.area = 0;

        var location = this.viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(centerLongitude, centerLatitude),
          billboard: {
            image: 'assets/images/marker-icon.png',
            show: true,
            eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            alignedAxis: Cesium.Cartesian3.ZERO,
            width: 20,
            height: 30,
          },
        });

        this.viewer.flyTo(location, {
          offset: new Cesium.HeadingPitchRange(0, Cesium.Math.toRadians(-20), 5000),
          duration: 3, // Adjust the duration as needed
        });
        this.getCountryAndPlace(centerLatitude,centerLongitude,'search');
        if (this.restrictedUser) {
          let mousePoint = [centerLongitude, centerLatitude];
          this.checkPointInsidePolygon(mousePoint)
        }
      }
    };

    if (this.retask) {
      this.selectRecord(this.selectedTasks);
    }

    if (_this.currentUser?.isTaskableAoi && !this.retask) {
      this.restrictedUser = true;
      let targetData = _this.currentUser?.taskableAoi;
      let type: any, coordinates: any;
      type = targetData?.type.toUpperCase();
      coordinates = targetData.coordinates;
      if (type === 'POLYGON') {
        this.drawTaskableAOI(coordinates);
      }
    } else this.restrictedUser = false;
 
    function zoomToEntities() {
      var entities = viewer.entities;
      var dataSourceCollection = viewer.dataSources;

      // Get an array of entities
      var entityArray = entities.values;

      for (var i = 0; i < entityArray.length; i++) {
        var entity = entityArray[i];
        viewer.flyTo(entity);
      }

      // Loop through data sources
      for (var i = 0; i < dataSourceCollection.length; i++) {
        var dataSource = dataSourceCollection.get(i);
        if (dataSource.entities && dataSource.entities.values.length > 0) {
          viewer.flyTo(dataSource, { duration: 3 });
          viewer.scene.globe.depthTestAgainstTerrain = false;
        }
      }
    }

    // viewer.scene.morphStart.addEventListener(function () {
    //   zoomToEntities();
    // });

    function zoomToLocation() {
      var entities = viewer.entities;
      var entityArray = entities.values;
      var myEntity = entityArray[0];
      var dataSourceCollection = viewer.dataSources;
      var dataSource = dataSourceCollection.get(0);

      if (myEntity) {
        var entities = viewer.entities;
        var entityArray = entities.values;
        var myEntity = entityArray[0];

        for (var i = 0; i < entityArray.length; i++) {
          var entity = entityArray[i];
          if (entity.name != 'orbit') {
            viewer.flyTo(entity);
          } else {
            if (dataSource) {
              viewer.flyTo(dataSource);
            }
          }
        }
      } else {
        if (dataSource) {
          viewer.flyTo(dataSource);
        }
      }
    }

    const homeButton = this.el.nativeElement.querySelector('.cesium-home-button');

    if (homeButton) {
      this.renderer.listen(homeButton, 'click', function () {
        zoomToLocation();
      });
    }

    this.filteredSensorTypes = this.SensorsTypes.filter(item => item.feasibility === true);

    this.timeSubscription = interval(30000).subscribe(() => {
      this.updateCurrentLocalTime();
    });
  }
  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    if (this.timeSubscription) {
      this.timeSubscription.unsubscribe();
    }
  }

  async selectRecord(rowData: any) {
    try {
      const res: any = await this.apiService.getObservations().toPromise();
      this.observationOptions = res.observations;
      this.rulesOptions = res.ruleBased;
      this.formGroup.get('tipAndCueBasedOn').setValue(rowData?.tipAndCueBasedOn);
      const ruleFilter = this.rulesOptions.filter(el => el.rule === rowData?.rule);
      this.formGroup.get('rule').setValue(ruleFilter[0]?.name);
      if (rowData?.observation) this.formGroup.get('observation').setValue(rowData?.observation[0]?.name);
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    this.formGroup.get('project').setValue(rowData?.project);
    const modifiedName = rowData?.taskname.replace('_retask', '');
    let taskName = modifiedName + '_retask';
    this.formGroup.get('taskname').setValue(taskName);
    this.formGroup.controls.projectId.setValue(rowData?.projectid);
    this.formGroup.get('purpose').setValue(rowData?.purpose);
    const purposeFilter = this.purposeOption.filter(el => el.purpose === rowData.purpose);    
    if (purposeFilter?.length > 0) {
      this.onPurposeChange(purposeFilter[0]);
    } else {
      let option = [{
        code: "8",
        purpose: rowData.purpose,
      }];
      this.target.purposeOption = option[0];
    }

    const frequencyFilter = this.frequencyOption.filter(el => el.id === rowData.frequency);
    this.formGroup.get('frequency').setValue(frequencyFilter[0]?.name);
    this.onOptionChange(frequencyFilter[0]?.name);
    if (rowData.taskStrategy) {
      const strategyFilter = this.strategyOptions.filter(el => el.value === rowData.taskStrategy);
      this.formGroup.get('strategyRadio').setValue(strategyFilter[0]?.name);
    }

    if (rowData?.intent?.key) {
      const intentFilter = this.intentOption.filter(el => el.key === rowData?.intent.key);
      this.formGroup.get('intent').setValue(intentFilter[0]?.name);
    } else this.formGroup.get('intent').setValue(rowData?.intent);

    if (rowData?.useCase) {
      this.formGroup.get('useCase').setValue(rowData.useCase)
    }
    if (rowData?.useCaseDescription) {
      this.formGroup.get('useCaseDescription').setValue(rowData.useCaseDescription)
    }
   

    this.selectedOption = rowData.frequency;
    this.clickFrequency = true;
    this.subDaily = false;
    if (rowData.frequency === 1) {
      this.target.freqOption = rowData?.repeatFrequency;
      if (this.target.freqOption === 'Sub-daily') {
        this.subDaily = true;
        this.target.subDailyCount = rowData?.subDailyCount;
      }
    }

    let targetData = JSON.parse(rowData.target);
    let type: any, coordinates: any;

    let targetPoints = rowData?.targetPoints ? JSON.parse(rowData.targetPoints) : null;
    const isLBandIncluded = rowData.request.some(item => (item.operatorKey === OPERATORS.LBAND));

    if (targetData?.length > 1) {
      type = targetData[0]?.type.toUpperCase();
      this.createGeoJSONFile(targetData);
    } else {
      type = targetData?.type.toUpperCase();
      coordinates = JSON.parse(rowData.target).coordinates;

      if (type === 'POINT') {
        this.drawPoint(coordinates);
        if (isLBandIncluded) {
         this.drawCircle(coordinates);
        }
      }
      else if (type === 'POLYGON') {
        this.drawpolygon(coordinates, rowData.area);
        if (isLBandIncluded && targetPoints) {
          let points = targetPoints[0].replace('POINT (', '').replace(')', '').split(' ');
          let coordinates = [parseFloat(points[0]), parseFloat(points[1])]
         this.drawPoi(coordinates);
        }
      }
    }
   
    this.projName = rowData?.project;
    this.taskName = taskName;
    this.target.projectId = rowData?.projectid;
    this.target.selectedOption = this.selectedOption;
    this.target.fileName = rowData?.fileName;
    this.target.intent = rowData?.intent;
    this.target.taskStrategy = rowData?.taskStrategy;
    this.taskStrategy = rowData?.taskStrategy;
    this.tipAndCueBasedOn = rowData?.tipAndCueBasedOn;
    this.target.tipAndCueBasedOn = rowData?.tipAndCueBasedOn;
    this.rule = rowData?.rule;
    this.target.rule = rowData?.rule;
    this.observation = rowData?.observation;
    this.target.observation = rowData?.observation;
    this.target.useCase = rowData?.useCase
    this.target.useCaseDescription = rowData?.useCaseDescription

    this.updateTarget(this.target, '0');
  }

  drawPoint(positionArray) {
    var longitude = parseFloat(positionArray[0]);
    var latitude = parseFloat(positionArray[1]);
    const accuracy = 15;
    let poiWkt = `POINT (${longitude.toFixed(accuracy)} ${latitude.toFixed(accuracy)})`;
    this.target.geoJSON = poiWkt;
    this.formGroup.get('target').setValue(poiWkt);
    var pin = this.viewer.entities.add({
      position: Cesium.Cartesian3.fromDegrees(longitude, latitude),
      billboard: {
        image: 'assets/images/marker-icon.png',
        show: true,
        eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        alignedAxis: Cesium.Cartesian3.ZERO,
        width: 20,
        height: 30,
      },
    });
    this.getCountryAndPlace(latitude, longitude,'search');

    var cameraHeight = 1000;
    var cameraPosition = Cesium.Cartesian3.fromDegrees(longitude, latitude, cameraHeight);
    this.viewer.camera.setView({
      destination: cameraPosition,
      orientation: {
        heading: 0.0,
        pitch: Cesium.Math.toRadians(-90.0),
        roll: 0.0
      }
    });

    this.viewer.flyTo(pin, {
      duration: 1.0,
      offset: new Cesium.HeadingPitchRange(0, Cesium.Math.toRadians(-45), 1000000)
    });
  }

  drawpolygon(positionArray, area) {
    this.polygons.push(positionArray[0]);
    let aoiWkt: any = 'POLYGON ((';
    const accuracy = 15;
    let polyPts: any = [], aoi: any, lng0 = 0, lat0 = 0;
    this.polygons.forEach((coordinates) => {
      const polygon = this.viewer.entities.add({
        polygon: {
          hierarchy: Cesium.Cartesian3.fromDegreesArray(coordinates.flat()),
          material: Cesium.Color.BLUE.withAlpha(0.5)
        }
      });
    });
    this.viewer.zoomTo(this.viewer.entities);
    for (let i = 0; i < positionArray[0]?.length - 1; i++) {
      let lng = positionArray[0][i][0];
      let lat = positionArray[0][i][1];
      if (i === 0) {
        lng0 = lng;
        lat0 = lat;
      }
      polyPts.push([lng, lat]);
      aoiWkt =
        aoiWkt + `${lng.toFixed(accuracy)} ${lat.toFixed(accuracy)}, `;
    }
    polyPts.push([lng0, lat0]);
    aoi = turf.polygon([polyPts]);
    aoiWkt = aoiWkt + `${lng0.toFixed(accuracy)} ${lat0.toFixed(accuracy)}`;
    aoiWkt = aoiWkt + `))`;
    this.target.geoJSON = aoiWkt;
    this.target.area = area;
    this.formGroup.get('target').setValue(aoiWkt);
    this.getCountryAndPlace(lat0, lng0,geoParse(this.target.geoJSON));
  }

  async createGeoJSONFile(features: any[]) {
    const geoJSON = {
      type: 'FeatureCollection',
      features: features.map((feature) => ({
        type: 'Feature',
        geometry: {
          type: feature.type,
          coordinates: feature.coordinates,
        },
      })),
    };

    let selectData: any;
    selectData = Cesium.GeoJsonDataSource.load(geoJSON);

    selectData.then((dataSource) => {
      const entities = dataSource.entities.values;
      for (let i = 0; i < entities.length; i++) {
        const entity = entities[i];
        entity.billboard = new Cesium.BillboardGraphics({
          image: 'assets/images/marker-icon.png',
          show: true,
          eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          alignedAxis: Cesium.Cartesian3.ZERO,
          width: 20,
          height: 30,
        });
        entity.label = new Cesium.LabelGraphics({
          text: (i + 1).toString(), // Set the label to the index + 1
          font: '14pt sans-serif',
          fillColor: Cesium.Color.WHITE,
          outlineColor: Cesium.Color.BLACK,
          outlineWidth: 2,
          style: Cesium.LabelStyle.FILL_AND_OUTLINE,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          pixelOffset: new Cesium.Cartesian2(0, -35) // Adjust the offset to position the label correctly
        });
      }

      this.viewer.flyTo(dataSource, { duration: 3 });
      this.viewer.dataSources.add(dataSource);
      this.viewer.scene.globe.depthTestAgainstTerrain = false;
    });

    const selctval = await selectData;
    let point = selctval.entities.values;
    const accuracy = 15;

    let poiWkt: any;
    let pointArray: any = [];
    let latValue, longValue;
    for (const entity of point) {
      if (entity) {
        const position = entity.position.getValue(this.viewer.clock.currentTime); // Get the position in Cartesian3 format
        const cartographic = Cesium.Cartographic.fromCartesian(position);
        const lng = Cesium.Math.toDegrees(cartographic.longitude);
        const lat = Cesium.Math.toDegrees(cartographic.latitude);
        poiWkt = `POINT (${lng.toFixed(accuracy)} ${lat.toFixed(accuracy)})`;
        pointArray.push(poiWkt);
        latValue = Cesium.Math.toDegrees(cartographic.latitude);
        longValue = Cesium.Math.toDegrees(cartographic.longitude);
      }
    }

    function geoParsePoint(wkt: string) {
      const regex = /POINT \(([^)]+)\)/g;
      const points: { type: string; coordinates: [number, number] }[] = [];
      let match;

      while ((match = regex.exec(wkt)) !== null) {
        const coordinates = match[1].split(' ').map(parseFloat);
        points.push({
          type: "Point",
          coordinates: [coordinates[0], coordinates[1]]
        });
      }

      return points;
    }

    this.target.multiTarget = pointArray;
    this.target.geoJSON = pointArray.join(', ');
    const geoPoints = geoParsePoint(this.target.geoJSON);
    this.target.geotargets = JSON.stringify(geoPoints);
    this.target.area = 0;
    this.getCountryAndPlace(latValue, longValue,'search');
  }
  usecasedescChng(event: any){
    this.target.useCaseDescription = event.target.value
    this.updateTarget(this.target, '0')
  }

  descChng(event: any) {
    let option = [{
      code: "8",
      purpose: event.target.value,
    }];
    this.target.purposeOption = option[0];
    this.updateTarget(this.target, '0');
  }

  Space(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space") {
      event.preventDefault();
    }
  }

  get budgetFields() {
    return this.budgetForm.controls;
  }

  get outcomeFields() {
    return this.outComeForm.controls;
  }

  get fields() {
    return this.formGroup.controls;

  }
  getTooltip(name: string) {
    if (name === 'Period') return 'Tasked once within the period specified';
    else if (name === 'Specific Date') return 'Tasked once on the date specified';
    else if (name === 'Recurrent') return 'Tasked repeatedly, either sub-daily, daily, weekly or monthly, within the period specified';
    else return 'Description for ' + name.toLowerCase() + ' frequency';
  }

  updateTarget(target, idx) {
    if (idx != 0 && target?.geoJSON && !target?.geoJSON.includes('POINT')) this.callCounter++;
    this.target = target;
    this.target['project'] = this.projName?.trim();
    this.target['taskname'] = this.taskName?.trim();

    if (idx != 0 && this.callCounter === 1) this.isAreaOk(target.area);

    this.resetOutcome();
    this.apiService.updateTaskParams(this.target);
    if(this.isSearched == true) {
      this.drawingMode = 'search';
      this.buttonDisable = true;
      this.pinDisable = true;
    }
    else if(this.isSearched == false && this.drawingMode == 'search') {
      this.drawingMode = '';
      this.buttonDisable = false;
      this.pinDisable = false;
    } 
    else {
      this.drawingMode = '';
    }
    this.weatherData = [];
    this.cloudPanel.close();
  }

   onOptionChange(chosenOption: any) {
    this.resetDateDefault();
    this.dateFlag = false;
    this.subDaily = false;
    this.target.subDailyCount = '';
    const id = this.frequencyOption.findIndex(
      (option) => option.name === chosenOption
    );
    this.selectedFrequencyID = id;
    this.selectedOption = id;
    this.clickFrequency = true;
    this.target.selectedOption = id;
    this.formGroup.get('startTime').setValue(null);
    this.formGroup.get('endTime').setValue(null);
    this.budgetForm.get('startTime').setValue(null);
    this.budgetForm.get('endTime').setValue(null);
    this.range.value.start = null;
    this.range.value.end = null;

    if (id === 1) {
      this.target.freqOption = 'Daily';
    }
    if (id === 1 || id === 2) {
      this.range.value.start = this.date.value;
      const day = this.todayDate.getDate();
      const month = this.todayDate.getMonth() + 1;
      const year = this.todayDate.getFullYear();
      this.range.value.end = new Date(
        year,
        month - 1,
        day + this.defaultWindow
      );
    } else {
      this.range.value.start = this.date.value;
      this.range.value.end = this.date.value;
    }

  }
  handleDateChange(event: any, datePicker: any) {
    this.formGroup.get('startTime').setValue(null);
    this.formGroup.get('endTime').setValue(null);
    this.budgetForm.get('startTime').setValue(null);
    this.budgetForm.get('endTime').setValue(null);

    if (datePicker === 1) {
      this.range.value.start = event.value;
      const day = event.value.getDate();
      const month = event.value.getMonth() + 1;
      const year = event.value.getFullYear();
      this.range.value.end = event.value;
    } else if (datePicker === 2) {
      this.range.value.start = event.value;
    } else if (datePicker === 3) {
      this.range.value.end = event.value;
    }

    if (this.range.value.start !== null && this.range.value.end !== null) {
      if (this.range.value.start < this.range.value.end) {
        this.dateFlag = false;
      } else {
        this.dateFlag = true;
      }
      if (this.selectedOption === 1 && this.subDaily) {
        this.dateFlag = false;
      }

    } else {
      this.dateFlag = false;
    }
    this.target['openWindow'] = this.range.value.start;
    this.target['closeWindow'] = this.range.value.end;
    this.updateTarget(this.target, '0');
  }

  freqChange(chosenOption: any) {
    this.subDaily = false;
    this.target.subDailyCount = '';
    this.target.freqOption = chosenOption.name;
    this.target.repeatOption = chosenOption.value;
    if (this.target.repeatOption === 4) {
      this.subDaily = true;
      this.target.subDailyCount = 1;
    }
    this.updateTarget(this.target, '0');
  }

  recurrentCountChange(chosenOption: any) {
    this.target.subDailyCount = chosenOption.label;
    this.updateTarget(this.target, '0');
  }

  onPurposeChange(option: any) {
    this.useCaseOption = null;
    this.target.useCase = null;
    if (option.purpose === 'Others') {
      this.others = true;
    }
    else {
      this.others = false;
      this.target.purposeOption = option;
    }
    if(this.taskStrategy === "Help Select Sensor(s)"){
      this.userService.getUseCases(option._id).subscribe((res: any) => {
        this.useCaseOption = res.data.sort((a, b) => {
          return a.useCase.toLowerCase().localeCompare(b.useCase.toLowerCase());
        });
      });
    } else this.updateTarget(this.target, '0');
  }

  intentChange(option: any) {
    this.target.intent = option;
    this.updateTarget(this.target, '0');
    if (this.budget) {
      this.budgetForm.patchValue({
        resolution: '',
        cloudCover: '',
        taskingTier:'',
        holdBack: '',
        sensorType: ''
      });
      
      this.budgetInputs.resolutionArr = [];
      this.budgetInputs.tierArr = [];
      this.budgetInputs.cloudCoverKeys = [];
      this.budgetInputs.holdbackArr = [];
      this.budgetSensorType = [];

      this.apiService.getBudgetSensorType(option).subscribe((res: any) => {
        this.budgetSensorType=res.response
        });
    }
  }

  useCaseChange(option: any) {
    this.target.useCase = option;
    this.updateTarget(this.target, '0');

  }



  nameChng(e: any, i: number) {
    if (i === 1) {
      this.target.projectId = null;
      if (e.target.value.length > 3) {
        this.projName = e.target.value;
      } else {
        this.projName = undefined;
      }
    } else {
      if (e.target.value.length > 4) {
        this.taskName = e.target.value;
      } else {
        this.taskName = undefined;
      }
    }
    this.updateTarget(this.target, '0');
  }

  inValid() {
    if (!this.taskTarget) return true;
    if (
      this.taskName &&
      this.projName &&
      this.taskTarget.includes('P') &&
      this.selectedSensorTypes.length > 2
    ) {
      this.showSensors = true;
      return false;
    } else {
      this.showSensors = false;
      return true;
    }
  }

  isAreaOk(area) {
    this.target.area = area;
    if (this.target)
      if (
        (this.target.area > 2000 || this.target.area < 10) &&
        this.target.geoJSON?.includes('POLY')
      ) {
        setTimeout(() => {
          const dialogRef = this.dialog.open(OpenconfirmwarningComponent, {
            width: '60%', backdropClass: 'blurr',
            data: this.target,
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this.proceed = true;
              this.buttonDisable = true;
              this.target.buttonDisable = true;
            } else {
              this.proceed = false;
              this.resetMap();
            }
          });
          return this.proceed;
        }, 1);
      }
    return
  }

  openAreaPopup () {    
    if (
      (this.target.area > 2000 || this.target.area < 10) &&
      !this.target.geoJSON
    ) {
      const dialogRef = this.dialog.open(OpenconfirmwarningComponent, {
        width: '60%', backdropClass: 'blurr',
        data: this.target,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.proceed = true;
          this.buttonDisable = true;
          this.target.buttonDisable = true;
        } else {
          this.proceed = false;
          this.resetMap();
        }
      });
    }
  }

  getFileData() {
    this.drawingMode = 'fileUpload';
    const entities = this.viewer.entities.values;
    for (const entity of entities) {
      if ((entity.id == undefined || entity.id == null) && entity?.name !== 'allowedArea') {
        this.viewer.entities.remove(entity);
      }
    }

    const doc = document.getElementById('filedatas');
    if (doc) {
      doc.click();
    }
  }

  onFilesSelected($event): void {
    this.buttonDisable = true;
    this.pinDisable = true;
    this.readThis($event.target);
    $event.target.value = '';
    this.shapeSelected = true;
  }

  readThis(inputValue: any): void {
    this.resetMap();
    let viewer = this.viewer;
    let options = {
      camera: viewer.scene.camera,
      canvas: viewer.scene.canvas,
      screenOverlayContainer: viewer.container,
    };
    let file: File = inputValue.files[0];
    let fileReader: FileReader = new FileReader();
    let selectData: any, vals: any, res: any, target = this.target;
    let _this = this;
    const accuracy = 15;
    target.polygon = false;
    if (file) {
      fileReader.onloadend = async function (e) {
        target.fileName = file.name;
        res = fileReader.result;
        if (file.name.includes('json')) {
          vals = JSON.parse(fileReader.result as string);
          selectData = Cesium.GeoJsonDataSource.load(vals);

          selectData.then((dataSource) => {
            const entities = dataSource.entities.values;
            for (let i = 0; i < entities.length; i++) {
              const entity = entities[i];
              entity.billboard = new Cesium.BillboardGraphics({
                image: 'assets/images/marker-icon.png',
                show: true,
                eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
                horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                alignedAxis: Cesium.Cartesian3.ZERO,
                width: 20,
                height: 30
              });
              entity.label = new Cesium.LabelGraphics({
                text: (i + 1).toString(), // Set the label to the index + 1
                font: '14pt sans-serif',
                fillColor: Cesium.Color.WHITE,
                outlineColor: Cesium.Color.BLACK,
                outlineWidth: 2,
                style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(0, -35) // Adjust the offset to position the label correctly
              });
            }
          });
        }
        if (file.name.includes('kml') || file.name.includes('kmz')) {
          selectData = Cesium.KmlDataSource.load(file, options);
        }
        const selctval = await selectData;
        let point = selctval.entities.values;
        let sumOfArea = 0;
        let count = 0;
        target.buttonDisable = false;
        let isInside = true;

        for (let j = 0; j < point.length; j++) {
          if (point[j]?.polygon !== undefined) {
            count = count + 1;
            let polyPts: any = [], aoi: any;
            let poly_point = point[j].polygon.hierarchy.getValue(
              Cesium.JulianDate.now()
            );
            let cartesianPts = poly_point.positions.length;
            let lng0 = 0, lat0 = 0;
            for (let i = 0; i < cartesianPts; i++) {
              let cartoPoint = Cesium.Cartographic.fromCartesian(
                poly_point.positions[i]
              );
              let lng = Cesium.Math.toDegrees(cartoPoint.longitude);
              let lat = Cesium.Math.toDegrees(cartoPoint.latitude);
              if (i === 0) {
                lng0 = lng;
                lat0 = lat;
              }
              polyPts.push([lng, lat]);
            }

            aoi = turf.polygon([polyPts]);
            const areaVal = turf.area(aoi) / 1000000;
            var aoiArea = areaVal.toFixed(2);
            sumOfArea += parseFloat(aoiArea)
          }
        }
        target.area = sumOfArea.toFixed(2);

        if (point[0].position !== undefined && point?.length < 2) {
          let pointss = point[0].position.getValue(Cesium.JulianDate.now());
          let cartographicPosition = Cesium.Cartographic.fromCartesian(pointss);
          let lat = Cesium.Math.toDegrees(cartographicPosition.latitude);
          let lng = Cesium.Math.toDegrees(cartographicPosition.longitude);
          createBB(lng, lat);
          function createBB(lng, lat) {
            let poiWkt = `POINT (${lng.toFixed(accuracy)} ${lat.toFixed(
              accuracy
            )})`;
            target.geoJSON = poiWkt;
            target.area = 0;
            _this.getCountryAndPlace(lat,lng,'search');
            if (_this.restrictedUser) {
              isInside = _this.utilsService.pointInPolygon(
                geoParse(target.geoJSON).coordinates,
                _this.currentUser.taskableAoi.coordinates[0]
              );
              if (!isInside) {
                const message = `This tasking request is outside the authorised area. Please task within the allowed area.`;
                _this.popupService.openPopup(WarningPopup, message,'', '50%');
                _this.resetMap();
              }
            }
            _this.updateTarget(target, '1');
          }
        } else if (point[0].polygon) {
          let poly_point = point[0].polygon.hierarchy.getValue(
            Cesium.JulianDate.now()
          );
          let cartesianPts = poly_point.positions.length;
          let polyPts: any = [],
            aoi;
          let aoiWkt: any = 'POLYGON ((';
          let lng0 = 0,
            lat0 = 0;
          for (let i = 0; i < cartesianPts; i++) {
            let cartoPoint = Cesium.Cartographic.fromCartesian(
              poly_point.positions[i]
            );
            let lng = Cesium.Math.toDegrees(cartoPoint.longitude);
            let lat = Cesium.Math.toDegrees(cartoPoint.latitude);
            if (i === 0) {
              lng0 = lng;
              lat0 = lat;
            }
            polyPts.push([lng, lat]);

            if (i === cartesianPts - 1) {
              aoiWkt =
                aoiWkt + `${lng.toFixed(accuracy)} ${lat.toFixed(accuracy)} `;
            } else {
              aoiWkt =
                aoiWkt + `${lng.toFixed(accuracy)} ${lat.toFixed(accuracy)}, `;
            }
          }
          aoi = turf.polygon([polyPts]);
          aoiWkt = aoiWkt + `))`;
          if (!aoi) return;
          const areaVal = turf.area(aoi) / 1000000;
          let areaMsg = '';
          var aoiArea = areaVal.toFixed(2); //in Sq km
          target.geoJSON = aoiWkt;
          target.area = aoiArea;
          _this.getCountryAndPlace(lat0,lng0,geoParse(target.geoJSON));
          if (_this.restrictedUser) {
            isInside = _this.utilsService.comparePolygons(
              geoParse(target.geoJSON).coordinates[0],
              _this.currentUser.taskableAoi.coordinates[0]
            );
            if (!isInside) {
              const message = `This tasking request is outside the authorised area. Please task within the allowed area.`;
              _this.popupService.openPopup(WarningPopup, message,'', '50%');
              _this.resetMap();

            }
          }
          _this.updateTarget(target, '1');
        } else if (point?.length > 1 && point[0].position !== undefined) {
          target.buttonDisable = true;
          let poiWkt: any;
          let pointArray: any = [];
          for (const entity of point) {
            if (entity) {
              const position = entity.position.getValue(viewer.clock.currentTime); // Get the position in Cartesian3 format
              const cartographic = Cesium.Cartographic.fromCartesian(position);
              const lng = Cesium.Math.toDegrees(cartographic.longitude);
              const lat = Cesium.Math.toDegrees(cartographic.latitude);
              poiWkt = `POINT (${lng.toFixed(accuracy)} ${lat.toFixed(accuracy)})`;
              pointArray.push(poiWkt);
            }
          }

          function geoParsePoint(wkt: string) {
            const regex = /POINT \(([^)]+)\)/g;
            const points: { type: string; coordinates: [number, number] }[] = [];
            let match;

            while ((match = regex.exec(wkt)) !== null) {
              const coordinates = match[1].split(' ').map(parseFloat);
              points.push({
                type: "Point",
                coordinates: [coordinates[0], coordinates[1]]
              });
            }

            return points;
          }

          target.multiTarget = pointArray;
          target.geoJSON = pointArray.join(', ');
          const geoPoints = geoParsePoint(target.geoJSON);
          target.geotargets = JSON.stringify(geoPoints);
          target.area = 0;
          if (_this.restrictedUser) {            
            isInside = _this.utilsService.pointInPolygon(geoParse(pointArray[pointArray.length-1]).coordinates, _this.currentUser.taskableAoi.coordinates[0]);
            if (!isInside) {
              const message = `This tasking request is outside the authorised area. Please task within the allowed area.`;
              _this.popupService.openPopup(WarningPopup, message,'', '50%');
              _this.resetMap();
            }
          }
          _this.updateTarget(target, '1');

        }
        _this.openAreaPopup();

        if (!_this.restrictedUser || (_this.restrictedUser && isInside))
        selectData.then((dataSource) => {
          viewer.flyTo(dataSource, { duration: 3 });
          viewer.dataSources.add(dataSource);
        });

        setTimeout(() => {
          if (count > 1) {
            _this.openDialog();      
          }
        }, 4000);
      };
    }

    fileReader.readAsText(file);
  }

  openDialog() {
    const dialogRef = this.dialog.open(CollectionPopupComponent, {
      width: '50%', backdropClass: 'blurred', disableClose: true,
      data: { message: 'Multi-polygon AOI is not supported at this time. Please submit a single contiguous polygon with any internal boundaries dissolved.' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.resetMap();
    });
  }

  resetMe(parentVal: any) {
    if (parentVal === 'Reset') {
      this.formGroup.controls['taskname'].setValue('');
      this.target['taskname'] = '';
      this.target.polygon = false;
      this.apiService.updateTaskParams(this.target);
      this.formGroup = new FormGroup({
        project: new FormControl(),
        taskname: new FormControl(),
        target: new FormControl()
      });
    }
  }

  drawOnMap(drawMode) {
    if(drawMode != 'point') {
    this.drawStarted = false;
    this.drawCompleted = false;
    this.drawingMode = drawMode;
    let drawingMode = drawMode;
    this.callCounter = 0;
    this.shapeSelected = true;
    var activeShapePoints: any = [];
    var activeShape;
    var floatingPoint;
    var viewer = this.viewer;
    this.target.project = this.projName;
    this.target.taskname = this.taskName;
    var _this = this;
    var target = this.target;
    viewer._container.style.cursor = 'crosshair';
    if (this.handler) {
        this.handler.destroy();
        this.handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
    } else {
      this.handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
    }
      
    target.polygon = false;
    this.pointArray = [];
    this.pointArray.length = 0;
    this.isDrawingEnabled = false;

    if(this.drawingMode != 'line') {
      this.pointEntities = [];
      this.target.multiTarget = [];
      this.target.geoJSON = null;
      this.target.geotargets = null;
    }

    if (this.drawingMode == 'line') {
      this.proceed = false;
    }

    // Double click the left mouse button to clear the default event
    viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
    );
    // draw a point
    function createPoint(worldPosition) {
      var point = viewer.entities.add({
        position: worldPosition,
        point: {
          color: Cesium.Color.BLUE,
          pixelSize: 5,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        },
      });
      return point;
    }
    const createBB = (lng, lat) => {
      var entitiesToRemove: any = [];
      // Loop through all entities in the viewer.entities collection
      viewer.entities.values.forEach(function (entity) {
        // Check if the entity is not a line or a point

        if ((entity.polyline === undefined && entity.point === undefined) && entity?.name !== 'allowedArea') {
          // If the entity is not a line or a point, remove it from the collection
          viewer.entities.remove(entity);
        }
      });
      var lastEllipseEntity = null;
      var entities = viewer.entities.values;
      for (var i = entities.length - 1; i >= 0; i--) {
        var entity = entities[i];
        if (entity.ellipse) {
          lastEllipseEntity = entity;
          break;
        }
      }
      if (lastEllipseEntity != null)
        this.removeEntity();
      const accuracy = 15;
      let poiWkt = `POINT (${lng.toFixed(accuracy)} ${lat.toFixed(accuracy)})`;
      const bb = viewer.entities.add({
        position: Cesium.Cartesian3.fromDegrees(lng, lat), //  bbPosition,
        billboard: {
          image: 'assets/images/marker-icon.png',
          show: true, // default
          eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0), // default
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // default
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // default: CENTER
          alignedAxis: Cesium.Cartesian3.ZERO, // default
          width: 20, // default: undefined
          height: 30, // default: undefined
        },
      });
      target.geoJSON = poiWkt;
      target.area = 0;
      _this.updateTarget(target, '1');
      this.getCountryAndPlace(lat,lng,'search');
      target.fileName = '';
    }

    const createCZML = (mode, cartesianPts) => {
      const accuracy = 15;
      let polyPts: any = [],
        aoi;
      let aoiWkt: any = 'POLYGON ((';
      let lng0 = 0,
        lat0 = 0;
      if (mode === 'polygon') {
        target.polygon = true;

        for (let i = 0; i < cartesianPts.length; i++) {
          let cartoPoint = Cesium.Cartographic.fromCartesian(cartesianPts[i]);
          let lng = Cesium.Math.toDegrees(cartoPoint.longitude);
          let lat = Cesium.Math.toDegrees(cartoPoint.latitude);
          if (i === 0) {
            lng0 = lng;
            lat0 = lat;
          }
          polyPts.push([lng, lat]);
          aoiWkt =
            aoiWkt + `${lng.toFixed(accuracy)} ${lat.toFixed(accuracy)}, `;
        }
        polyPts.push([lng0, lat0]);
        aoi = turf.polygon([polyPts]);
        aoiWkt = aoiWkt + `${lng0.toFixed(accuracy)} ${lat0.toFixed(accuracy)}`;
      } else if (mode === 'rectangle') {
        let i = 0;
        let cartoPoint = Cesium.Cartographic.fromCartesian(cartesianPts[0]);
        let lng0 = Cesium.Math.toDegrees(cartoPoint.longitude);
        let lat0 = Cesium.Math.toDegrees(cartoPoint.latitude);
        cartoPoint = Cesium.Cartographic.fromCartesian(cartesianPts[1]);
        let lng2 = Cesium.Math.toDegrees(cartoPoint.longitude);
        let lat2 = Cesium.Math.toDegrees(cartoPoint.latitude);
        var bbox = [lng0, lat0, lng2, lat2];
        aoi = turf.bboxPolygon(bbox);

        aoiWkt =
          aoiWkt + `${lng0.toFixed(accuracy)} ${lat0.toFixed(accuracy)}, `;
        aoiWkt =
          aoiWkt + `${lng2.toFixed(accuracy)} ${lat0.toFixed(accuracy)}, `;
        aoiWkt =
          aoiWkt + `${lng2.toFixed(accuracy)} ${lat2.toFixed(accuracy)}, `;
        aoiWkt =
          aoiWkt + `${lng0.toFixed(accuracy)} ${lat2.toFixed(accuracy)}, `;
        aoiWkt = aoiWkt + `${lng0.toFixed(accuracy)} ${lat0.toFixed(accuracy)}`;
      } else if (mode === 'circle') {
        // let dx = 0, dy = 0, delta = 0;
        let cartoPoint = Cesium.Cartographic.fromCartesian(cartesianPts[0]);
        let lng0 = Cesium.Math.toDegrees(cartoPoint.longitude);
        let lat0 = Cesium.Math.toDegrees(cartoPoint.latitude);
        cartoPoint = Cesium.Cartographic.fromCartesian(cartesianPts[1]);
        let lng2 = Cesium.Math.toDegrees(cartoPoint.longitude);
        let lat2 = Cesium.Math.toDegrees(cartoPoint.latitude);
        var options = { units: 'kilometers' }; //var options = {units: 'miles'};
        let radius = turf.distance(
          turf.point([lng0, lat0]),
          turf.point([lng2, lat2]),
          options
        );
        const circle = turf.circle([lng0, lat0], radius, { steps: 64 });
        aoi = turf.bboxPolygon(turf.bbox(circle));
        lng0 = aoi.bbox[0];
        lat0 = aoi.bbox[1];
        lng2 = aoi.bbox[2];
        lat2 = aoi.bbox[3];

        aoiWkt =
          aoiWkt + `${lng0.toFixed(accuracy)} ${lat0.toFixed(accuracy)}, `;
        aoiWkt =
          aoiWkt + `${lng2.toFixed(accuracy)} ${lat0.toFixed(accuracy)}, `;
        aoiWkt =
          aoiWkt + `${lng2.toFixed(accuracy)} ${lat2.toFixed(accuracy)}, `;
        aoiWkt =
          aoiWkt + `${lng0.toFixed(accuracy)} ${lat2.toFixed(accuracy)}, `;
        aoiWkt = aoiWkt + `${lng0.toFixed(accuracy)} ${lat0.toFixed(accuracy)}`;
        var circleEntity = viewer.entities.add({
          name: 'Circle',
          position: Cesium.Cartesian3.fromDegrees(
            (lng0 + lng2) / 2,
            (lat0 + lat2) / 2
          ),
          ellipse: {
            semiMinorAxis: radius * 1000.0,
            semiMajorAxis: radius * 1000.0,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
            outline: true,
            outlineColor: Cesium.Color.RED,
            outlineWidth: 4,
            material: new Cesium.ColorMaterialProperty(
              Cesium.Color.BLUE.withAlpha(0.5)
            ),
          },
        });
      }
      aoiWkt = aoiWkt + `))`;
      if (!aoi) return;
      const areaVal = turf.area(aoi) / 1000000;

      let areaMsg = '';

      var aoiArea = areaVal.toFixed(2); //in Sq km
      target.geoJSON = aoiWkt;
      target.area = aoiArea;
      viewer.render();
      target.fileName = '';
      this.getCountryAndPlace(lat0,lng0,geoParse(target.geoJSON));
      
      if (this.currentUser?.isTaskableAoi) {
        let isInside = this.utilsService.comparePolygons(
          geoParse(target.geoJSON).coordinates[0],
          this.currentUser.taskableAoi.coordinates[0]
        );
        if (!isInside) {
          const message = `This tasking request is outside the authorised area. Please task within the allowed area.`;
          this.popupService.openPopup(WarningPopup, message, '', '50%');
          this.resetMap();
        }
      }
      _this.updateTarget(target, '1');

    }

    // Initialize to line
    // draw graphics
    let drawShape = (positionData) => {
      var shape;
      if (this.drawingMode === 'point') {
        this.buttonDisable = true;
      } else if (this.drawingMode === 'line') {
        this.entityId = Date.now() + ''
        shape = viewer.entities.add({
          id: this.entityId + 'line',
          polyline: {
            positions: positionData,
            clampToGround: true,
            width: 2,
          },
        });
      } else if (this.drawingMode === 'polygon') {
        this.pinDisable = true;
        this.buttonDisable = true;
        if (positionData.length > 0) {
          shape = viewer.entities.add({
            polygon: {
              hierarchy: positionData,
              material: new Cesium.ColorMaterialProperty(
                Cesium.Color.BLUE.withAlpha(0.7)
              ),
            },
          });
        } else {
          shape = viewer.entities.add({
            polyline: {
              positions: positionData,
              clampToGround: true,
              width: 1,
            },
          });
        }
      } else if (this.drawingMode === 'circle') {
        this.buttonDisable = true;
        this.pinDisable = true;
        //Draw the final graph when positionData is an array, and draw a dynamic graph if it is function
        var value =
          typeof positionData.getValue === 'function'
            ? positionData.getValue(0)
            : positionData;
        shape = viewer.entities.add({
          position: activeShapePoints[0],
          name: 'Blue translucent, rotated, and extruded ellipse with outline',
          type: 'Selection tool',
          ellipse: {
            semiMinorAxis: new Cesium.CallbackProperty(function () {
              //radius distance between two points
              var r = Math.sqrt(
                Math.pow(value[0].x - value[value.length - 1].x, 2) +
                Math.pow(value[0].y - value[value.length - 1].y, 2)
              );
              return r ? r : r + 1;
            }, false),
            semiMajorAxis: new Cesium.CallbackProperty(function () {
              var r = Math.sqrt(
                Math.pow(value[0].x - value[value.length - 1].x, 2) +
                Math.pow(value[0].y - value[value.length - 1].y, 2)
              );
              return r ? r : r + 1;
            }, false),
            material: Cesium.Color.BLUE.withAlpha(0.5),
            outline: true,
            show: false,
          },
        });
        // viewer.entities.remove(shape)
      } else if (this.drawingMode === 'rectangle') {
        this.buttonDisable = true;
        this.pinDisable = true;
        //Draw the final graph when positionData is an array, and draw a dynamic graph if it is function
        var arr =
          typeof positionData.getValue === 'function'
            ? positionData.getValue(0)
            : positionData;
        shape = viewer.entities.add({
          name: 'Blue translucent, rotated, and extruded ellipse with outline',
          rectangle: {
            coordinates: new Cesium.CallbackProperty(function () {
              var obj = Cesium.Rectangle.fromCartesianArray(arr);
              //if(obj.west==obj.east){ obj.east+=0.000001};
              //if(obj.south==obj.north){obj.north+=0.000001};
              return obj;
            }, false),
            material: Cesium.Color.BLUE.withAlpha(0.5),
          },
        });
      }
      return shape;
    }

    let tooltipEntity: any = null;
    let totalDistance = 0;
    var previousPoint: any;
    let measuretooltipEntity: any = null;

    //left mouse button

    if(this.handler) {
      this.handler.setInputAction((event) => {
        
        if(this.isDrawingEnabled == false && this.drawCompleted == false) {
        this.drawStarted = true;
        if (this.proceed == false) {
          var earthPosition = viewer.camera.pickEllipsoid(
            event.position,
            viewer.scene.globe.ellipsoid
          );
          if (Cesium.defined(earthPosition)) {
            if (activeShapePoints.length === 0) {
              floatingPoint = createPoint(earthPosition);
              activeShapePoints.push(earthPosition);
              var dynamicPositions = new Cesium.CallbackProperty(function () {
                return activeShapePoints;
              }, false);
              activeShape = drawShape(dynamicPositions); //Draw dynamic graph
  
              if (this.drawingMode === 'polygon') {
                tooltipEntity = viewer.entities.add({
                  label: {
                    show: false,
                    showBackground: true,
                    font: "14px Arial",
                    horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                    pixelOffset: new Cesium.Cartesian2(15, -10),
                    backgroundColor: Cesium.Color.fromCssColorString('black'),
                    fillColor: Cesium.Color.WHITE
                  },
                });
              } else if (this.drawingMode === 'rectangle') {

                if(tooltipEntity) {
                  viewer.entities.remove(tooltipEntity);
                }
                
                tooltipEntity = viewer.entities.add({
                  label: {
                    show: false,
                    showBackground: true,
                    font: "14px Arial",
                    horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                    pixelOffset: new Cesium.Cartesian2(15, -10),
                    backgroundColor: Cesium.Color.fromCssColorString('black'),
                    fillColor: Cesium.Color.WHITE
                  },
                });
              } else if (this.drawingMode === 'line') {
                if(tooltipEntity) {
                  viewer.entities.remove(tooltipEntity);
                }
                measuretooltipEntity = viewer.entities.add({
                  label: {
                    show: false,
                    showBackground: true,
                    font: "14px Arial",
                    horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                    pixelOffset: new Cesium.Cartesian2(15, -10),
                    backgroundColor: Cesium.Color.fromCssColorString('black'),
                    fillColor: Cesium.Color.WHITE
                  },
                });
              }
            }
            activeShapePoints.push(earthPosition);
            if (this.drawingMode === 'point') {
              let cartoPoint = Cesium.Cartographic.fromCartesian(earthPosition);
              let lng = Cesium.Math.toDegrees(cartoPoint.longitude);
              let lat = Cesium.Math.toDegrees(cartoPoint.latitude);
              createBB(lng, lat);  
            }
  
            if (activeShapePoints.length > 1) {
              if (this.drawingMode == 'polygon') {
                if (previousPoint) {
                  var distance = (Cesium.Cartesian3.distance(previousPoint, earthPosition)) / 1000;
                  totalDistance = totalDistance + distance;
                  if (tooltipEntity) {
                    tooltipEntity.position = earthPosition;
                    tooltipEntity.label.text =
                      "Distance: " + distance.toFixed(2) + " km\n" +
                      "Total Distance: " + totalDistance.toFixed(2) + " km";
                    tooltipEntity.label.show = true;
                  }
                }
              } else if (this.drawingMode == 'rectangle') {
                if (previousPoint) {
                  if (tooltipEntity) {
                    tooltipEntity.position = earthPosition;
                    tooltipEntity.label.text = 'Total distance : ' + totalDistance.toFixed(2) + " km";;
                    tooltipEntity.label.show = true;
                    this.buttonDisable = true;
                    this.target.buttonDisable = true;
  
                  }
                }
              } else if (this.drawingMode == 'line') {
                if (previousPoint) {
                  var distance = (Cesium.Cartesian3.distance(previousPoint, earthPosition)) / 1000;
                  totalDistance = totalDistance + distance;
                  if (measuretooltipEntity) {
                    measuretooltipEntity.position = earthPosition;
                    measuretooltipEntity.label.text = 'Total : ' + totalDistance.toFixed(2)+" km";
                    measuretooltipEntity.label.show = true;
                  }
                }
              }else if (this.drawingMode == 'circle') {
                if (previousPoint) {
                  this.buttonDisable = true;
                  this.pinDisable = true;
                }}
            }
            previousPoint = earthPosition;
          }
  
          viewer.screenSpaceEventHandler.setInputAction((movement) => {
                var pickedObject = viewer.scene.pick(movement.position);
                if (Cesium.defined(pickedObject) && pickedObject.id) {
                    var clickedId = pickedObject.id.id; 
                    let pointEntityindex = this.pointEntities.findIndex((pointEntity) => clickedId == pointEntity.uniqueId);
                    if(pointEntityindex == -1) {
                      // Remove the tooltip entity with the clicked id
                    var indexToRemove = this.measurmentTooltipEntities.findIndex(function (entity) {
                        return entity.id === clickedId;
                    });
    
                    if (indexToRemove !== -1) {
                  
                        var entityToRemove = viewer.entities.getById(clickedId+'line');
                  
                        if (entityToRemove !== undefined) {
                            viewer.entities.remove(this.measurmentTooltipEntities[indexToRemove]);
                            this.measurmentTooltipEntities.splice(indexToRemove, 1);
                            this.measurmentEntities.splice(indexToRemove, 1);
                            viewer.entities.remove(entityToRemove);
                        } else {
                      }
                    }
                    } else if (pickedObject.id.name != 'allowedArea') {
                      const clickedEntity = this.viewer.entities.getById(clickedId);
                      this.pointEntities.splice(pointEntityindex, 1);
                      this.viewer.entities.remove(clickedEntity);
                    }
  
                    this.pointArray = [];
                    for (const items of this.pointEntities) {
                      this.pointArray.push(items.poiWkt);
                    }

                  if(this.target?.geoJSON?.includes('POINT') && this.isSearched == false) {                    
                    if (this.pointArray?.length === 1) {
                      this.target.geoJSON = this.pointArray[0];
                      this.target.multiTarget = [];
                    } else {
                      this.target.multiTarget = this.pointArray;
                      this.target.geoJSON = this.pointArray.join(', ');
                      const geoPoints = this.geoParsePoint(this.target.geoJSON);
                      this.target.geotargets = JSON.stringify(geoPoints);
                    }     
                  }

                }
          }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
        }
      }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    }

    // Function to calculate the distance between two Cartesians
    function calculateDistance(cartesian1, cartesian2) {
      var p1 = Cesium.Cartographic.fromCartesian(cartesian1);
      var p2 = Cesium.Cartographic.fromCartesian(cartesian2);
      var a = Math.sin(p1.latitude) * Math.sin(p2.latitude) +
        Math.cos(p1.latitude) * Math.cos(p2.latitude) * Math.cos(p2.longitude - p1.longitude);
      var distance = Cesium.Ellipsoid.WGS84.maximumRadius * Math.acos(Math.min(a, 1.0));
      return distance;
    }

    //Mouse movement
    this.handler.setInputAction((event) => {
      
      if(this.drawStarted == false) {
        activeShapePoints.length = 0;
      }
      
      if (Cesium.defined(floatingPoint)) {
        var newPosition = viewer.camera.pickEllipsoid(
          event.endPosition,
          viewer.scene.globe.ellipsoid
        );

        if (Cesium.defined(newPosition)) {
          floatingPoint.position.setValue(newPosition);
          if (activeShapePoints.length > 0) {
            activeShapePoints.pop();
          }
          var newPositionCopy = Cesium.Cartesian3.clone(newPosition);
          activeShapePoints.push(newPositionCopy);

          if (activeShapePoints.length > 1) {
            var distance = (Cesium.Cartesian3.distance(previousPoint, newPosition)) / 1000;

            if (tooltipEntity && this.drawingMode == 'polygon') {              
              tooltipEntity.position = newPosition;
              tooltipEntity.label.text =
                "Distance: " + distance.toFixed(2) + " km";
              tooltipEntity.label.show = true;
            }
            if (measuretooltipEntity && this.drawingMode == 'line') {
              measuretooltipEntity.position = newPosition;
              measuretooltipEntity.label.text =
                "Distance: " + distance.toFixed(2) + " km";
              measuretooltipEntity.label.show = true;
            }

            if (activeShapePoints.length === 2 && this.drawingMode == 'rectangle') {
              let cartoPoint = Cesium.Cartographic.fromCartesian(activeShapePoints[0]);
              let lng0 = Cesium.Math.toDegrees(cartoPoint.longitude);
              let lat0 = Cesium.Math.toDegrees(cartoPoint.latitude);
              cartoPoint = Cesium.Cartographic.fromCartesian(activeShapePoints[1]);
              let lng2 = Cesium.Math.toDegrees(cartoPoint.longitude);
              let lat2 = Cesium.Math.toDegrees(cartoPoint.latitude);
              var bbox = [lng0, lat0, lng2, lat2];


              // Calculate the width of the rectangle
              let width = turf.distance([lng0, lat0], [lng2, lat0], { units: 'kilometers' });

              // Calculate the height of the rectangle
              let height = turf.distance([lng0, lat0], [lng0, lat2], { units: 'kilometers' });

              // Calculate the total area
              totalDistance = 2 * (width + height);

              // Display or use the calculated area
              if (tooltipEntity) {
                tooltipEntity.position = newPosition;
                tooltipEntity.label.text =
                  "Distance: " + totalDistance.toFixed(2) + " km";
                tooltipEntity.label.show = true;
              }
            }
          }


        }
        if (activeShapePoints.length === 1 && this.drawStarted) {
          if (this.drawingMode === 'point') terminateShape();
        } else if (activeShapePoints.length > 2 && this.drawStarted) {
          if (this.drawingMode === 'rectangle' || this.drawingMode === 'circle'){
            terminateShape();
          }
        }
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    // Redraw the shape so it's not dynamic and remove the dynamic shape.
    let terminateShape = () => {     
      this.drawStarted = false;
      
      activeShapePoints.pop(); //remove the last dynamic point
      if (activeShapePoints.length) {
        drawShape(activeShapePoints); //Draw the final graph
        createCZML(this.drawingMode, activeShapePoints);
      }
      viewer.entities.remove(floatingPoint); //Remove dynamic dot graphics (current mouse point)
      viewer.entities.remove(activeShape); //Remove dynamic graphics
      floatingPoint = undefined;
      activeShape = undefined;
      activeShapePoints = [];
      drawingMode = undefined;
      viewer._container.style.cursor = 'default';
    }


    this.handler.setInputAction((event) => {
      if (this.drawingMode != 'rectangle') {
        var earthPosition = viewer.camera.pickEllipsoid(
          event.position,
          viewer.scene.globe.ellipsoid
        );

        if (this.drawingMode === 'polygon') {
          if(tooltipEntity) {
            viewer.entities.remove(tooltipEntity);
          }
          tooltipEntity = viewer.entities.add({
            label: {
              show: false,
              showBackground: true,
              font: "13px Arial",
              horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
              pixelOffset: new Cesium.Cartesian2(15, -10),
              backgroundColor: Cesium.Color.fromCssColorString('black'),
            },
          });

          tooltipEntity.position = earthPosition;
          tooltipEntity.label.text =
            "Total Distance: " + totalDistance.toFixed(2) + " km";
          tooltipEntity.label.show = true;
          this.buttonDisable = true;
          this.target.buttonDisable = true;
        }

        terminateShape();
      }

      if (this.drawingMode === 'line') {
        viewer.entities.remove(measuretooltipEntity);
        measuretooltipEntity = viewer.entities.add({
          id: this.entityId,
          label: {
            show: false,
            showBackground: true,
            font: "13px Arial",
            horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
            pixelOffset: new Cesium.Cartesian2(15, -10),
            backgroundColor: Cesium.Color.fromCssColorString('black'),
          },
        });

        measuretooltipEntity.position = earthPosition;
        measuretooltipEntity.label.text =
          "Total Distance: " + totalDistance.toFixed(2) + " km";
        measuretooltipEntity.label.show = true;
        this.measurmentTooltipEntities.push(measuretooltipEntity); 
      }
    
      this.drawCompleted = true;
    }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
  }
  }

  resetMap() {
    if(this.drawingMode == 'search' || this.drawingMode == 'line') {
      this.isSearched = false;
    }
    
    this.pinDisable = false;
    this.isDrawingEnabled = false;
    this.pointArray = [];
    this.pointEntities = [];
    this.target.multiTarget = [];
    this.buttonDisable = false;
    this.target.buttonDisable = false;
    this.viewer.dataSources.removeAll(true);
    this.target.area = null;
    this.target.geoJSON = null;
    this.target.geotargets = null;
    this.callCounter = 0;
    this.shapeSelected = false;
    this.target.targetPoints = null;

    if(this.drawingMode == 'fileUpload') {
      this.pinDisable = true;
      const entities = this.viewer.entities.values;
      for (const entity of entities) {
        if (
          (entity.id == undefined || entity.id == null) &&
          entity?.name !== 'allowedArea'
        ) {
          this.viewer.entities.remove(entity);
        }
      }
    } else {
      this.removeEntity();
    }

    if(this.drawingMode != 'fileUpload') {
      this.proceed = false;
    }

    this.updateTarget(this.target, '0');
  }

  plotTarget() {
    let target: any,
      viewer = this.viewer;

    if (this.taskTarget)
      if (this.taskTarget.type === 'Polygon') {
        const fpPos = this.taskTarget.coordinates[0]
          .toString()
          .split(',')
          .map(Number);
        // const pts = this.taskTarget.coordinates[0]
        var bbox = turf.bbox(this.taskTarget);
        // var rectangle = Cesium.Rectangle.fromDegrees(west, south, east, north);
        var rectangle = Cesium.Rectangle.fromDegrees(
          bbox[0],
          bbox[1],
          bbox[2],
          bbox[3]
        );
        Cesium.Camera.DEFAULT_VIEW_RECTANGLE = rectangle;
        target = viewer.entities.add({
          name: this.taskName,
          polygon: {
            hierarchy: {
              positions: Cesium.Cartesian3.fromDegreesArray(fpPos),
            },
            fill: false,
            // material : Cesium.Color.BLUE.withAlpha(0.2),
            height: 0,
            outlineColor: Cesium.Color.YELLOW,
            outline: true, // height is required for outline to display
          },
        });
        // viewer.zoomTo(target);
        viewer.flyTo(target, { duration: 3 });
      } else if (this.taskTarget.type === 'Point') {
        const fpPos = this.taskTarget.coordinates;
        const lng = fpPos[0],
          lat = fpPos[1];
        var rectangle = Cesium.Rectangle.fromDegrees(
          lng - 1,
          lat - 1,
          lng + 1,
          lat + 1
        );
        Cesium.Camera.DEFAULT_VIEW_RECTANGLE = rectangle;
        target = viewer.entities.add({
          id: this.taskName,
          position: Cesium.Cartesian3.fromDegrees(lng, lat), //  bbPosition,
          billboard: {
            image: 'assets/images/marker-icon.png',
            show: true, // default
            // pixelOffset: new Cesium.Cartesian2(0, 5), // default: (0, 0)
            eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0), // default
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // default
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // default: CENTER
            // scale: 0.75, // default: 1.0
            // color: Cesium.Color.LIME, // default: WHITE
            // rotation: Cesium.Math.PI_OVER_FOUR, // default: 0.0
            alignedAxis: Cesium.Cartesian3.ZERO, // default
            width: 20, // default: undefined
            height: 30, // default: undefined
          },
        });
        viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(lng, lat), // bbPosition,
          billboard: {
            image: 'assets/images/marker-shadow.png',
            pixelOffset: new Cesium.Cartesian2(5, 0), // default: (0, 0)
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // default
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // default: CENTER
            scale: 0.6,
          },
        });
        this.defaultLL = [lng, lat];
        this.viewer.camera.flyTo(
          {
            destination: Cesium.Cartesian3.fromDegrees(lng, lat, 100000.0),
            orientation: {
              heading: Cesium.Math.toRadians(0),
              pitch: Cesium.Math.toRadians(-90.0),
              roll: 0.0,
            },
          },
          { duration: 3 }
        );
      }
  }

  flyHome() {
    this.viewer.camera.flyHome(2); // take 2 seconds
  }

  showLatLon() {
    const viewer = this.viewer;
    const latlonOverlay = this.latlonOverlay;
    const scene = this.viewer.scene;
    const canvas = this.viewer.canvas;
    // this.latlonOverlay.id = 'latlon';
    viewer.container.appendChild(latlonOverlay);
    // latlonOverlay.className = 'llDisp';
    // latlonOverlay.style.display = 'none';
    latlonOverlay.style.position = 'absolute';
    latlonOverlay.style['z-index'] = '10';
    latlonOverlay.style.right = '3px';
    latlonOverlay.style.bottom = '5px';
    // latlonOverlay.style.marginTop = '-30px';
    latlonOverlay.style.height = '30px';

    // latlonOverlay.style.width = 'calc(100% - 37px)';
    latlonOverlay.style.width = 'fit-content';
    latlonOverlay.style['pointer-events'] = 'none';
    latlonOverlay.style.textAlign = 'center';
    latlonOverlay.style.justifyContent = 'center';
    latlonOverlay.style.padding = '5px 4px 0 4px';
    latlonOverlay.style.color = 'rgba(255, 255, 255, 1)';
    // latlonOverlay.style.color = '#026FC2';
    // latlonOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0.2)';
    let mousePosition;
    const handler = new Cesium.ScreenSpaceEventHandler(canvas);
    const accuracy = 15;

    handler.setInputAction((movement) => {
      mousePosition = movement.endPosition;
      const cartesian = viewer.camera.pickEllipsoid(
        movement.endPosition,
        viewer.scene.globe.ellipsoid
      );
      if (cartesian) {
        const cartographic = Cesium.Cartographic.fromCartesian(cartesian);

        const longString = Cesium.Math.toDegrees(cartographic.longitude).toFixed(accuracy);
        const latString = Cesium.Math.toDegrees(cartographic.latitude).toFixed(accuracy);
        const latitude = parseFloat(latString);
        const longitude = parseFloat(longString);

        const latitudeDMS = convertDecimalToDMS(latitude) + (latitude >= 0 ? 'N' : 'S');
        const longitudeDMS = convertDecimalToDMS(longitude) + (longitude >= 0 ? 'E' : 'W');

        if (this.restrictedUser) {
          let mousePoint = [longitude, latitude];
          let insideAllowedArea = this.utilsService.pointInPolygon(
            mousePoint,
            this.currentUser.taskableAoi.coordinates[0]
          );
          viewer.entities.values.forEach(function (entity) {
            if (entity.name === 'allowedAreaTooltip') {
              viewer.entities.remove(entity);
            }
          });

          if (insideAllowedArea && !this.shapeSelected) {
            viewer.entities.add({
              position: cartesian,
              name: 'allowedAreaTooltip',
              label: {
                text: 'This is your allowed tasking area',
                show: true,
                showBackground: true,
                font: '14px Arial',
                backgroundColor: Cesium.Color.fromCssColorString('black'),
                fillColor: Cesium.Color.WHITE,
              },
            });
          }
        }
        
        function convertDecimalToDMS(decimal) {
          const degrees = Math.floor(Math.abs(decimal));
          const minutesDecimal = (Math.abs(decimal) - degrees) * 60;
          const minutes = Math.floor(minutesDecimal);
          const seconds = (minutesDecimal - minutes) * 60;

          const formattedDegrees = degrees !== 0 ? `${degrees}°` : '';
          const formattedMinutes = minutes !== 0 ? `${minutes}'` : '';
          const formattedSeconds = `${seconds.toFixed(1)}"`;

          return `${formattedDegrees}${formattedMinutes}${formattedSeconds}`;
        }

        this.longLatStr = 'Lng: ' + longitudeDMS + ', ' + 'Lat: ' + latitudeDMS;

        latlonOverlay.innerHTML =
          "<div style='padding:0px; padding-right: 30px'>" + this.longLatStr + '</div>';
      } else {
        this.longLatStr = '';
        latlonOverlay.innerHTML = "<div style='padding:0px;padding-right: 25px'>Out of Globe</div>";
        // latlonOverlay.style.display = 'none';
      }
      this.roll = Cesium.Math.toDegrees(this.viewer.camera.heading).toFixed(0);
      if (this.roll > 359.5) this.roll = 0;
      // this.compassOverlay.style.transform = `rotate(${360-this.roll}deg)`;
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  }

  zoomIn() {
    var height = this.viewer.camera.positionCartographic.height;
    this.viewer.camera.zoomIn(height / 2);
  }
  zoomOut() {
    var height = this.viewer.camera.positionCartographic.height;
    this.viewer.camera.zoomOut(height * 2);
  }

  receivedMessageHandler(result: any) {
    this.satellites = [];
    this.viewer.entities.remove(this.satelliteEntity);

    if (result?.length > 0) {
      this.satellites = result;

      for (let i = 0; i < this.satellites.length; i++) {
        let satelliteData = this.satellites[i];
        let orbitPosition: any = [];
        let satellite = satelliteData;
        orbitPosition.push(satellite.longitude, satellite.latitude, satellite.position[satellite.position.length - 1][0], satellite.position[satellite.position.length - 1][1]);

        this.satelliteEntity = this.viewer.entities.add({
          name: 'orbit',
          position: Cartesian3.fromDegrees(
            satellite.longitude,
            satellite.latitude,
          ),
          billboard: {
            image: 'assets/images/satellite.png',
            show: true,
            eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            alignedAxis: Cesium.Cartesian3.ZERO,
            width: 25,
            height: 20,
          },
          label: {
            text: ((this.currentUser.obfuscated && satellite.obfuscateName) ? satellite.obfuscateName : satellite.name) + '\n' + satellite.time,
            font: '11px Barlow',
            fillColor: Color.YELLOW,
            outlineColor: Color.YELLOW,
            outlineWidth: 1,
            style: 2,
            pixelOffset: new Cesium.Cartesian2(0, 10),
          },
          satellitesToRemove: true,
        });

        if (satellite.operatorKey === OPERATORS.CAPELLA) {
          this.randomColor = 'YELLOW';
        } else if (satellite.operatorKey === OPERATORS.SATELLOGIC) {
          this.randomColor = 'YELLOWGREEN';
        } else if (satellite.operatorKey === OPERATORS.UMBRA) {
          this.randomColor = 'BLUE';
        } else if (satellite.operatorKey === OPERATORS.HEAD) {
          this.randomColor = 'GREENYELLOW';
        } else if (satellite.operatorKey === OPERATORS.STE) {
          this.randomColor = 'BLUEVIOLET';
        } else if (satellite.operatorKey === OPERATORS.AT21) {
          this.randomColor = 'GREEN';
        } else {
          this.randomColor = 'ROYALBLUE';
        }

        // Render orbital path     
        this.orbitalPath = this.viewer.entities.add({
          name: 'orbit',
          polyline: {
            positions: Cesium.Cartesian3.fromDegreesArray(orbitPosition),
            width: 5,
            material: new Cesium.PolylineArrowMaterialProperty(
              Cesium.Color.fromCssColorString(this.randomColor)
            )
          },
          satellitesToRemove: true
        });

        this.viewer.flyTo(this.viewer.entities); // Zoom to show the entire orbital path
        // }
      }
      this.entityValues = this.viewer.entities.values;
    }

  }

  receiveHandler(data: any) {
    if (data?.length > 0) {
      const operatorExists = data.some(item => item.key === OPERATORS.LBAND);
      if (operatorExists) this.drawCircles();

      const spireExists = data.some(item => item.key === OPERATORS.SPIRE);
      if (spireExists) this.drawRectangle(data);
    }
  }

  drawRectangle(data: any) {
    if (data[0]?.centroid) {
      const { longitude, latitude } = this.getCoordinatesFromPoint(data[0].centroid);

      // Step 2: Calculate square corners
      const corners = this.getSquareCorners(longitude, latitude);
    
      // Step 3: Draw the square
      this.drawSquare(corners);
      
    }
  }

  getCoordinatesFromPoint(pointString: string): { longitude: number; latitude: number } {
    const match = pointString.match(/POINT\s*\(([-\d.]+)\s+([-\d.]+)\)/);
  
    if (!match) {
      throw new Error('Invalid POINT format');
    }
  
    return {
      longitude: parseFloat(match[1]),
      latitude: parseFloat(match[2]),
    };
  }

  getSquareCorners(
    longitude: number,
    latitude: number,
    sideLength: number = 50
  ): Cesium.Cartesian3[] {
    // Convert 50 km to degrees approximately (~0.5 degrees for simplicity, refine as needed)
    const degreeOffset = sideLength / 111.32;
  
    return [
      Cesium.Cartesian3.fromDegrees(longitude - degreeOffset, latitude - degreeOffset),
      Cesium.Cartesian3.fromDegrees(longitude + degreeOffset, latitude - degreeOffset),
      Cesium.Cartesian3.fromDegrees(longitude + degreeOffset, latitude + degreeOffset),
      Cesium.Cartesian3.fromDegrees(longitude - degreeOffset, latitude + degreeOffset),
    ];
  }
  
  drawSquare(corners: Cesium.Cartesian3[]) {
    let newEntity = this.viewer.entities.add({
      name:'sqaure',
      polygon: {
        hierarchy: new Cesium.PolygonHierarchy(corners),
        material: Cesium.Color.RED.withAlpha(0.5),
        outline: true,
        outlineColor: Cesium.Color.BLACK,
      },
    });
    this.viewer.flyTo(newEntity);
  }

  removeRectangles() {
    let entitiesToRemove: any = [];

    const entities = this.viewer.entities.values;
    for (const entity of entities) {
      if (entity?.name === 'sqaure') {
        entitiesToRemove.push(entity);
      }
    }

    for (let i = 0; i < entitiesToRemove.length; i++) {
      this.viewer.entities.remove(entitiesToRemove[i]);
    }
  }

  removeCircles() {
    let entitiesToRemove: any = [];

    const entities = this.viewer.entities.values;
    for (const entity of entities) {
      if (entity?.name === 'circles' || entity?.name === 'sqaure') {
        entitiesToRemove.push(entity);
      }
    }

    for (let i = 0; i < entitiesToRemove.length; i++) {
      this.viewer.entities.remove(entitiesToRemove[i]);
    }
  }

  clearSatllite() {
    let entitiesToRemove: any = [];

    for (let i = 0; i < this.entityValues?.length; i++) {
      let entity = this.entityValues[i];
      if (entity.satellitesToRemove) {
        entitiesToRemove.push(entity);
      }
    }

    for (let i = 0; i < entitiesToRemove.length; i++) {
      this.viewer.entities.remove(entitiesToRemove[i]);
    }
  }

  getCountryAndPlace(latitudes: number, longitudes: number,target: any) {
    let longitude: any, latitude: any
    if (target?.type === 'Point') {
      longitude = target?.coordinates[0];
      latitude = target?.coordinates[1];
    } else if (target === 'search') {
      longitude = longitudes;
      latitude = latitudes;
    } else {
      let totalLat = 0;
      let totalLng = 0;
      for (let i = 0; i < target?.coordinates[0].length; i++) {
          totalLat += target?.coordinates[0][i][1];
          totalLng += target?.coordinates[0][i][0];
      }
      const centroidLat = totalLat / target?.coordinates[0].length;
      const centroidLng = totalLng / target?.coordinates[0].length;
      longitude = centroidLng;
      latitude = centroidLat;
    }    
    
    const apiKey = environment.openCageApiKey;
    const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`;
    let place: any, country: any;
    let location = {
      longitude : longitude,
      latitude : latitude
    }

    this.apiService.checkIsWater(location).subscribe((res: any) => {      
    this.target.openWater = res?.water;
    this.http.get(url).subscribe((response: any) => {
      if (response.results.length > 0) {
        const firstResult = response.results[0];
                
        if (firstResult.components?.body_of_water) {
           country = firstResult.components.body_of_water;
           place = firstResult.components.body_of_water;
        } else {
           country = firstResult.components.country;
           place = firstResult.components.city || firstResult.components.state || firstResult.components.county;
        }

        this.target.place = place;
        this.target.country = country;
        this.updateTarget(this.target, '0');
      }
    });
  });
  }

  isLatitudeInRange(geoJSON: any, minValue: number, maxValue: number): boolean {
    if(this.target?.multiTarget?.length > 0) {
      for (const targets of this.target.multiTarget) {
        const geoJson = geoParse(targets);
        const latitude = geoJson.coordinates[1];
        if (latitude >= minValue && latitude <= maxValue) {
          return true; // If any latitude is within the range, return true
        }
      }
    } else if (geoJSON.type === 'Point') {
      const latitude = geoJSON.coordinates[1];
      return latitude >= minValue && latitude <= maxValue;
    } else if (geoJSON.type === 'Polygon') {
      const coordinates = geoJSON.coordinates[0];
      for (const coordinate of coordinates) {
        const latitude = coordinate[1];
        if (latitude >= minValue && latitude <= maxValue) {
          return true; // If any latitude is within the range, return true
        }
      }
    }
    return false;
  }

  strategyChange(data: any) {
    this.useCaseOption = null
    this.formGroup.reset();
    this.resetMap();
    this.onOptionChange('');
    this.formGroup.get('strategyRadio').setValue(data.value);
    this.formGroup.get('tipAndCueBasedOn').setValue('Observation');
    this.taskStrategy = data.value;
    this.selectedSensorTypes = '';
    if (this.taskStrategy != "Tip-and-Cue") {
      this.rule = null;
      this.target.rule = null;
      this.observation = null;
      this.target.observation = null;
    }
    this.tipAndCueBasedOn = 'Observation';
    this.target.tipAndCueBasedOn = 'Observation';
    this.target.taskStrategy = data.value;
    this.updateTarget(this.target, '0');
  }

  tipAndCueTypeChange(data: MatRadioChange) {
    this.tipAndCueBasedOn = data.value;
    this.target.tipAndCueBasedOn = data.value;
    this.observation = null;
    this.target.observation = null;
    this.rule = null;
    this.target.rule = null;
    this.updateTarget(this.target, '0');
  }

  onObservationChange(option: any) {
    this.observation = option;
    this.target.observation = option;
    this.rule = null;
    this.target.rule = null;
    this.updateTarget(this.target, '0');
  }

  onRulesChange(option: any) {
    if (option === 'rule-3') {
      const dialogRef = this.dialog.open(OpenconfirmwarningComponent, {
        width: '60%', backdropClass: 'blurr',
        data: 'rule',
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result >= 0) {
          this.formGroup.get('rule').setValue(option);
          this.rule = option;
          this.target.rule = option;
          this.observation = null;
          this.target.observation = null;
          this.target.cloudCover = result;
          this.updateTarget(this.target, '0');
        } else {
          this.formGroup.get('rule').setValue(null);
        }
      });
    } else {
      this.formGroup.get('rule').setValue(option);
      this.rule = option;
      this.target.rule = option;
      this.observation = null;
      this.target.observation = null;
      this.updateTarget(this.target, '0');
    }
  }

  drawPinOnMap() {
    this.drawingMode = 'point';
    let activeShapePoints: any = [];
    let pointEntity: any;
    let poiWkt: any;
    const accuracy = 15;
    this.isDrawingEnabled = true;
    this.buttonDisable = true;
    this.shapeSelected = true;
    this.viewer._container.style.cursor = 'auto';
    this.viewer.screenSpaceEventHandler.setInputAction((click) => {

      if (!this.isDrawingEnabled) {
        return;
      }

      var pickedObject = this.viewer.scene.pick(click.position);
      let lat: any, lng: any;
      const uniqueId = Date.now();

      var earthPosition = this.viewer.camera.pickEllipsoid(
        click.position,
        this.viewer.scene.globe.ellipsoid
      );

      if (Cesium.defined(earthPosition)) {
        activeShapePoints.push(earthPosition);
        let cartoPoint = Cesium.Cartographic.fromCartesian(earthPosition);
        lng = Cesium.Math.toDegrees(cartoPoint.longitude);
        lat = Cesium.Math.toDegrees(cartoPoint.latitude);
      }

      if (Cesium.defined(pickedObject) && pickedObject.id.name != 'allowedArea') {
        const clickedEntityId = pickedObject.id.id;
        const clickedEntity = this.viewer.entities.getById(clickedEntityId);       

        let measuringEntityIndex = this.measurmentTooltipEntities.findIndex((entity) => entity.id == clickedEntityId)
        if(measuringEntityIndex == -1) {
          if (clickedEntity) {
            const indexToRemove = this.pointEntities.findIndex(item => item.uniqueId === clickedEntityId);
            if (indexToRemove !== -1) {
              this.pointEntities.splice(indexToRemove, 1);
            }
            this.viewer.entities.remove(clickedEntity);
            this.updatePointLabels();
          }
        } else {
          var indexToRemove = this.measurmentTooltipEntities.findIndex(function (entity) {
            return entity.id === clickedEntityId;
          });

          if (indexToRemove !== -1) {           
            var entityToRemove = this.viewer.entities.getById(clickedEntityId+'line');           
            if (entityToRemove) {
              this.viewer.entities.remove(this.measurmentTooltipEntities[indexToRemove]);
              this.measurmentTooltipEntities.splice(indexToRemove, 1);
              this.measurmentEntities.splice(indexToRemove, 1);
              this.viewer.entities.remove(entityToRemove);
            }
          }
        }
      } else {        
        pointEntity = this.viewer.entities.add({
          id: uniqueId,
          position: Cesium.Cartesian3.fromDegrees(lng, lat),
          billboard: {
            image: 'assets/images/marker-icon.png',
            show: true,
            eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            alignedAxis: Cesium.Cartesian3.ZERO,
            width: 20,
            height: 30,
            pixelSize: 50,
          },
          label: {
            text: '',
            font: '14pt sans-serif',
            fillColor: Cesium.Color.WHITE,
            outlineColor: Cesium.Color.BLACK,
            outlineWidth: 2,
            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            pixelOffset: new Cesium.Cartesian2(0, -35) // Adjust this offset as needed
          }
        });
        pointEntity.type = 'POI';
        poiWkt = `POINT (${lng.toFixed(accuracy)} ${lat.toFixed(accuracy)})`;
        this.pointEntities.push({ poiWkt: poiWkt, uniqueId: pointEntity?.id });
        this.updatePointLabels();
      }

      this.pointArray = [];
      this.target.geoJSON = '';
      this.target.geotargets = null;

      for (const items of this.pointEntities) {
        this.pointArray.push(items.poiWkt);
      }

      if (this.currentUser?.isTaskableAoi) {
        let isInside = this.utilsService.pointInPolygon(geoParse(this.pointArray[this.pointArray.length-1]).coordinates, this.currentUser.taskableAoi.coordinates[0]);
        if (!isInside) {
          const message = `This tasking request is outside the authorised area. Please task within the allowed area.`;
          this.popupService.openPopup(WarningPopup, message,'', '50%');
          this.resetMap();
        }
      }
      
      this.target.area = 0;
      if (this.pointArray?.length === 1) {
        this.target.geoJSON = this.pointArray[0];
        this.target.multiTarget = [];
      } else {
        this.target.multiTarget = this.pointArray;
        this.target.geoJSON = this.pointArray.join(', ');
        const geoPoints = this.geoParsePoint(this.target.geoJSON);        
        this.target.geotargets = JSON.stringify(geoPoints);
        if (
          this.target?.multiTarget?.length === 4 &&
          !this.currentUser.pointsEligible &&
          !this.budget) {
          const message = `The maximum number of points for multiple points of interest (POIs) tasking is three.`;
          this.popupService.openPopup(CollectionPopupComponent, message, '', '50%');
        }
      }
      this.target.fileName = '';
      this.getCountryAndPlace(lat, lng,'search');
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
  }

  updatePointLabels() {
    this.removeCircles();  
    if (!this.currentUser.pointsEligible && !this.budget) {
      this.pointEntities.forEach((entity, index) => {
        const point = this.viewer.entities.getById(entity.uniqueId);
        if (point && point.label && point?.type === 'POI' && this.pointEntities?.length > 1) {
          point.label.text = (index + 1).toString();
        } else if (point && point.label && point?.type === 'POI' && this.pointEntities?.length === 1) {
          point.label.text = '';
        }
      });
    }


  // Add hover effect for each point
  const handler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas);
  handler.setInputAction((movement) => {
    var hoverEntity = this.viewer.scene.pick(movement.endPosition);
    
    if (Cesium.defined(hoverEntity) && hoverEntity.id) {
      const point = this.viewer.entities.getById(hoverEntity.id.id);
      if (point && point.label && point?.type === 'POI') {
        point.label.text = "Click to remove";
        point.label.show = true;
      }
    } else {
      // Revert labels to index numbers or empty based on the point count
      this.pointEntities.forEach((entity, index) => {
        const point = this.viewer.entities.getById(entity.uniqueId);
        if (point && point.label && point?.type === 'POI') {
          if (this.pointEntities?.length > 1) {
            point.label.text = (index + 1).toString();
          } else if (this.pointEntities?.length === 1) {
            point.label.text = '';
          }
        }
      });
    }
  }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  }

  geoParsePoint(wkt: string) {
    const regex = /POINT \(([^)]+)\)/g;
    const points: { type: string; coordinates: [number, number] }[] = [];
    let match;

    while ((match = regex.exec(wkt)) !== null) {
      const coordinates = match[1].split(' ').map(parseFloat);
      points.push({
        type: "Point",
        coordinates: [coordinates[0], coordinates[1]]
      });
    }

    return points;
  }

  sensorChange(event: any) {
    let target: any;
    this.outComeForm.patchValue({
      resolution: '',
      cloudCover: '',
      updateFrequency: '',
      latency: '',
      budget: '',
    });

    if (this.target.area > 0) {
      target = 'AOI';
    } else target = 'POI';

    let intent = this.target?.intent?.key ? this.target?.intent.key : this.target?.intent;

    if(event.value !== 'AIS') {
      this.apiService.getSensorDetails(event.value, target, intent).subscribe((res: any) => {
        this.outComesensor = res;
        const cloudC: any = [];
        if (res.cloudCover) {
          for (var key in res.cloudCover) {
            cloudC.push({ key, value: res.cloudCover[key] });
          }
          this.outComesensor.cloudCover = cloudC;

          if (this.outComesensor?.cloudCover?.length === 0) {
            this.outComeForm.get('cloudCover').disable();
          } else {
            this.outComeForm.get('cloudCover').enable();
          }
        }
      });
    }
  }
  budgetSensorChange(event: any) {
    let target: any;
    this.budgetForm.patchValue({
      resolution: '',
      cloudCover: '',
      taskingTier:'',
      holdBack: '',
    });

    if (this.target.area > 0) {
      target = 'AOI';
    } else target = 'POI';

    this.apiService.getBudgetResolutionDetails(event.value).subscribe((res: any) => {
      if (res?.msg === 'Success') {
        let resolutions = res.response;
        this.budgetInputs.resolutionArr = resolutions.sort((a, b) => this.sortResolutions(a, b));
        this.budgetInputs.resolutionArr = this.budgetInputs.resolutionArr.filter(item => item.key !== 'Both');
        this.updateOptions()
      }
     
    });
  }

  sortResolutions(a, b) {
    const aIsNumeric = /^[0-9.]+m$/.test(a.key);
    const bIsNumeric = /^[0-9.]+m$/.test(b.key);

    if (aIsNumeric && bIsNumeric) {
      return this.extractNumericValue(a.key) - this.extractNumericValue(b.key);
    } else if (aIsNumeric) {
      return -1;
    } else if (bIsNumeric) {
      return 1;
    } else {
      return this.extractNumericValue(a.key) - this.extractNumericValue(b.key);
    }
  }

  extractNumericValue(text: string): number {
    const match = text.match(/(\d+(\.\d+)?)/);
    return match ? parseFloat(match[0]) : Number.MAX_VALUE;
  }

  budgetResolutionChange(event: any) {
    this.budgetForm.patchValue({
      cloudCover: '',
      taskingTier:'',
      holdBack: '',
    });
    this.apiService.getBudgetTierDetails(this.budgetForm?.value?.sensorType,event.value?.key).subscribe((res: any) => {
      if (res?.msg === 'Success') {
        let seen = new Map();
        res.response.forEach(item => {
            let lowerItem = item?.name.toLowerCase();
            if (!seen.has(lowerItem)) {
                seen.set(lowerItem, item);
            }
        });
        let uniqueTierArr = Array.from(seen.values());
        this.budgetInputs.tierArr =uniqueTierArr;
        this.updateOptions()
      }
    });
  }

  budgetTierChange(event: any) {
    this.budgetForm.patchValue({
      cloudCover: '',
      holdBack: '',
    });
   
    this.apiService.getBudgetCcandHoldbackDetails(this.budgetForm?.value?.sensorType,this.budgetForm?.value?.resolution,event.value.key).subscribe((res: any) => {
      if (res?.msg === 'Success') {
        res.response.cloudCoverKeys =  res.response.cloudCoverKeys.sort((a, b) => parseInt(a) - parseInt(b));
        this.budgetInputs.cloudCoverKeys =   res.response.cloudCoverKeys.sort((a, b) => parseInt(a) - parseInt(b));
        this.budgetInputs.holdbackArr = this.sortArray([...res.response.holdbackArr]);
        this.updateOptions()
      }
    });
  }

  isAnyFieldEmpty(): boolean {
    const requiredFields = 
    this.target.taskStrategy === 'Outcome By Platform' && this.outComeForm.value.sensorType.name === 'AIS'
      ? ['sensorType', 'updateFrequency', 'budget', 'latency']
      : this.target.taskStrategy === 'Outcome By Platform' && this.outComeForm.value.sensorType.name !== 'AIS'
        ? ['sensorType', 'resolution', 'budget', 'latency']
        : Object.keys(this.outComeForm.controls).filter(key => key !== 'cloudCover');
  
    for (const key of requiredFields) {
      if (key != 'cloudCover') {
        const controlValue = this.outComeForm.get(key).value;
        if (controlValue === null || controlValue === undefined || controlValue === '') {
          return true;
        }
      }
    }
    return false;
  }

  submit() {
    let newTaskParams = {};
    let no_of_repeats = 1;
    let noOfMonths = 1;
    let no_of_weeks = 1;
    let startdate = moment(this.target.openWindow).utcOffset(0, true).format();
    let enddate =  moment(this.target.closeWindow).utcOffset(0, true).format();
    let time_difference = new Date(enddate).getTime() - new Date(startdate).getTime();
    let days_difference = Math.ceil(time_difference / (1000 * 60 * 60 * 24));
    noOfMonths = Math.ceil(days_difference / 30);
    const currentDate: any = new Date();
    this.outComeSubmit = true;

    if (this.target.selectedOption === 1) {
      if (this.target.freqOption === 'Weekly') {
        no_of_repeats = Math.ceil(days_difference / 7);
        this.expectedAge = '7 days, 00:00:00';
      } else if (this.target.freqOption === 'Monthly') {
        no_of_repeats = Math.ceil(days_difference / 30);
        this.expectedAge = '30 days, 00:00:00';
      }
      else if (this.target.freqOption === 'Daily') {
        no_of_repeats = days_difference;
        this.expectedAge = '1 day, 00:00:00';
      }
      else if (this.target.subDailyCount) {
        no_of_weeks = Math.ceil(days_difference / 7);
        no_of_repeats = days_difference * this.target.subDailyCount;
        this.expectedAge = '0 day, 04:00:00';
      }
      if (no_of_repeats === 0) {
        no_of_repeats = 1;
      }
    } else {
      no_of_repeats = 1;
    }
    newTaskParams['customerid'] = this.currentUser.id;
    newTaskParams['taskname'] = this.target.taskname;
    newTaskParams['purpose'] = this.target?.purposeOption;
    newTaskParams['area'] = this.target.area;
    newTaskParams['project'] = this.target.project;
    newTaskParams['fileName'] = this.target?.fileName;
    if (this.target.geotargets != null) {
      newTaskParams['target'] = this.target.geotargets;
    } else {
      newTaskParams['target'] = JSON.stringify(geoParse(this.target.geoJSON));
    }
    newTaskParams['geometryCentroid'] = this.target?.geometryCentroid;
    newTaskParams['aoiCountry'] = this.target.country;
    newTaskParams['aoiPlace'] = this.target.place;
    newTaskParams['frequency'] = this.target.selectedOption;
    if (this.target.selectedOption === 1) {
      newTaskParams['repeatFrequency'] = this.target.freqOption;
      newTaskParams['noOfRepeats'] = no_of_repeats;
      newTaskParams['subDailyCount'] = this.target.subDailyCount;
    } else {
      newTaskParams['repeatFrequency'] = '';
      newTaskParams['noOfRepeats'] = no_of_repeats;
      newTaskParams['subDailyCount'] = '';
    }
    newTaskParams['mode'] = this.target.mode;
    newTaskParams['expected_age'] = this.expectedAge;
    newTaskParams['circleCordinates'] = this.target.circleCordinates;

    newTaskParams['openWindow'] = moment(this.target.openWindow).utcOffset(0, true).format();
    newTaskParams['closeWindow'] = moment(this.target.closeWindow).utcOffset(0, true).format();
    newTaskParams['projectid'] = this.target?.projectId;
    newTaskParams['taskStrategy'] = this.target?.taskStrategy;
    newTaskParams['tipAndCueBasedOn'] = this.target?.tipAndCueBasedOn;
    newTaskParams['observation'] = this.target?.observation?.length > 0 ? this.target?.observation[0] : null;
    newTaskParams['rule'] = this.target?.rule;
    newTaskParams['intent'] = this.target?.intent;
    newTaskParams['cloudCover'] = this.target?.cloudCover;
    newTaskParams['openWater'] = this.target?.openWater
    if(this.target?.taskStrategy == "Help Select Sensor(s)"){
      newTaskParams['useCase'] = this.target?.useCase;
      newTaskParams['useCaseDescription'] = this.target?.useCaseDescription;
    }
    if (this.retask) {
      let totalAmount = 0;
      for (const item of this.selectedTasks.request) {
        item.feasibilityId = null;
        item.firstCapture = null;
        item.isQueuedTime = null;
        item.lastCapture = null;
        item.orderStatus = null;
        item.queuedTime = null;
        item.uuid = null;
        item.status = null;
        item.statusHistory = null;
        item.taskid = null;
        item.resolution = {
          value: item?.name === 'GRUS' ? item?.originalResolution : item?.resolution,
          mode_value: item?.resolutionTooltip,
          resolution_value: item?.name === 'GRUS' ? item?.originalResolution : item?.resolution,
          originalResolution:item?.originalResolution,
          ...( item?.originalResolution === 'Both' ? { combinedResolution : item?.resolution } : {} )
        },

        item.retaskingTooltip = [];
        item.feedData = [];
        item.pay = false;
        item.refund = false;

        if (item.sensortype === 'GHG') {         
          let annualCost = 0;
          let targetLength = 1
          if (this.target?.geotargets) {
            targetLength = JSON.parse(this.target?.geotargets)?.length > 0 ? this.target.multiTarget.length : 1;
          }

          const keyToMatch = item.latency.key; // Extract the key
          let ghgCost = null;

          // Find the matching cost
          for (const items of item.collectionMode[0].cost) {
            if (items[keyToMatch] !== undefined) {
              ghgCost = items[keyToMatch];
              break;
            }
          }

          if(ghgCost) {
          if (item.collectionMode[0].captureMode.includes('annual')) {
            let monthlyCost = ghgCost / 12;
            if (noOfMonths > 12) {
              if (noOfMonths % 12 === 0) {
                annualCost = (noOfMonths / 12) * ghgCost;
              } else {
                annualCost = ghgCost + ((noOfMonths - 12) * monthlyCost);
              }
            } else {
              annualCost = ghgCost;
            }
            item.cost = parseFloat((annualCost).toFixed(2))
          } else {
            annualCost = ghgCost * noOfMonths;
            item.cost = parseFloat((annualCost).toFixed(2))
          }
          if (!item.collectionMode[0].captureMode.includes('Complex')) {
            annualCost = annualCost * targetLength;
            item.cost = parseFloat((annualCost).toFixed(2))
          }
        }
        }
        else if (this.target.selectedOption === 1 && item.multiplierCost) {
          item.cost = parseFloat((item.multiplierCost * no_of_repeats).toFixed(2))
        }
      }

      this.selectedTasks.request = this.selectedTasks.request.map(sensor => {
        let tNumber = 0;
        if (this.currentUser?.allocated) {
            if (sensor.isPoint === true) {
                tNumber = 1 * no_of_repeats;
                this.totalTaskNumber += tNumber;
            } else {
                if (sensor.unit === 'sqkm' || sensor.unit === 'scene' || sensor.unit === 'week' || sensor.unit === 'day') {
                    tNumber = (Math.ceil(sensor.areacalc / sensor.minimumArea) * no_of_repeats);
                    this.totalTaskNumber += tNumber;
                }
            }
        }
        const p =  this.currentUser.pointsEligible ? this.convertCostToPoints(sensor.cost,false) : 0;
        return {
            ...sensor,
            point: p,
            taskNumber: tNumber
        };
    });

      totalAmount = this.selectedTasks.request.reduce((a, { cost }) => a + cost, 0);
      this.taskPoint = this.currentUser.pointsEligible ? this.selectedTasks.request.reduce((total, sensor) => {
        return total + this.convertCostToPoints(sensor.cost,false);
      }, 0) : 0;
      newTaskParams['taskcost'] = totalAmount.toFixed(2);
      newTaskParams['sensors'] = JSON.stringify(this.selectedTasks.request);
      newTaskParams['feasibilityTask'] = this.selectedTasks?.feasibilityTask;
      newTaskParams['collectionStart'] = this.selectedTasks?.collectionStart;
      newTaskParams['collectionEnd'] = this.selectedTasks?.collectionEnd;
      newTaskParams['taskPoints'] = this.taskPoint;
      newTaskParams['totalTaskNumber'] = this.totalTaskNumber;

      if (this.target?.taskStrategy === 'Outcome By Platform') {
        newTaskParams['outComeBudgetPoint'] = this.currentUser.pointsEligible ? this.selectedTasks?.outComeBudget : 0;
        newTaskParams['outComeBudget'] = this.currentUser.pointsEligible ? this.convertToCost(this.selectedTasks?.outComeBudget) : this.selectedTasks?.outComeBudget;
        // newTaskParams['outComeBudget'] = this.selectedTasks?.outComeBudget;
        newTaskParams['outComeCloudCover'] = this.selectedTasks?.outComeCloudCover;
        newTaskParams['outComeLatency'] = this.selectedTasks?.outComeLatency;
        newTaskParams['outComeResolution'] = this.selectedTasks?.outComeResolution;
        newTaskParams['outComesensorType'] = this.selectedTasks?.outComesensorType;
        newTaskParams['outcomeUpdateFrequency'] = this.outComeForm?.value?.updateFrequency;

        //Popup details
        newTaskParams['noOfRefreshes'] = this.outcomePopUpData?.noOfRefreshes;
        newTaskParams['isHistorical'] = this.outcomePopUpData?.isHistorical;
        newTaskParams['historicalCost'] = this.outcomePopUpData?.historicalCost;
        newTaskParams['noOfHistoricalData'] = this.outcomePopUpData?.noOfHistoricalData;
      }
      
      this.apiService.newTaskSubmit(newTaskParams).subscribe((res: any) => {
        if (res?.msg === 'Success') {
          let data = JSON.parse(res?.response);
          let taskid;
          let sensorList = this.matchUUIDs(newTaskParams['sensors'], data);
          newTaskParams['sensors'] = JSON.stringify(sensorList);
          if (data && data.length > 0) {
            taskid = res?.taskid;
            newTaskParams['taskId'] = taskid;
            this.apiService
              .genCollection(
                this.currentUser.id,
                this.currentUser.email,
                newTaskParams
              )
              .subscribe((res: any) => {
                const taskid = res?.id;
              });
          }
          const dialogRef = this.dialog.open(ConfirmDialog, {
            backdropClass: 'blurr', disableClose: true,
            data: { taskid: taskid, taskname: this.target.taskname, response: res, type: 'normal',from:'task' },
          });
        }
      }, (error: any) => {
        const dialogRef = this.dialog.open(CountrycheckComponent, {
          width: '50%', backdropClass: 'blurred', disableClose: true,
          data: { message: "fail",url:"/task" }
        });
      });

    } else {
      if (
      this.target?.multiTarget?.length > 1) { 
      const message = `Multiple points of interest (POIs) tasking is only available for user initiated tasking strategy at this time.`;
      this.popupService.openPopup(WarningPopup, message, '', '50%');
      this.outComeSubmit = false;
      return;
    }

      this.outComeForm.value.budget = parseFloat(this.outComeForm?.value?.budget)
      this.outComeForm.value.sensorType = this.outComeForm?.value?.sensorType.name;

      newTaskParams['outComeBudgetPoint'] = this.currentUser.pointsEligible ? parseFloat(this.outComeForm.value.budget.toFixed(2)) : 0;
      newTaskParams['outComeBudget'] = this.currentUser.pointsEligible ? this.convertToCost(parseFloat(this.outComeForm.value.budget.toFixed(2))): parseFloat(this.outComeForm.value.budget.toFixed(2));
      // newTaskParams['outComeBudget'] = parseFloat(this.outComeForm.value.budget.toFixed(2));
      newTaskParams['outComeCloudCover'] = this.outComeForm?.value?.cloudCover;
      newTaskParams['outComeLatency'] = this.outComeForm?.value?.latency;
      newTaskParams['outComeResolution'] = this.outComeForm?.value?.resolution;
      newTaskParams['outComesensorType'] = this.outComeForm?.value?.sensorType;
      newTaskParams['outcomeUpdateFrequency'] = this.outComeForm?.value?.updateFrequency;

      //Popup details
      newTaskParams['noOfRefreshes'] = this.outcomePopUpData?.noOfRefreshes;
      newTaskParams['isHistorical'] = this.outcomePopUpData?.isHistorical;
      newTaskParams['historicalCost'] = this.outcomePopUpData?.historicalCost;
      newTaskParams['noOfHistoricalData'] = this.outcomePopUpData?.noOfHistoricalData;
      this.apiService.outcomeTaskSubmit(newTaskParams).subscribe((res: any) => {
        if (res?.msg === 'Success') {
          let taskid;
          taskid = res?.taskid;
          newTaskParams['taskId'] = taskid;

          const dialogRef = this.dialog.open(ConfirmDialog, {
            backdropClass: 'blurr', disableClose: true, width: '40%',
            data: { taskid: taskid, taskname: this.target.taskname, response: res, type: 'outcome',from:'outcome' },
          });
        } else if (res?.msg === 'Failed' && res?.message) {
          const dialogRef = this.dialog.open(CountrycheckComponent, {
            width: '40%', backdropClass: 'blurred', disableClose: true,
            data: { message: "outcomeFail", response: res?.message ,url:"/task"}
          });
          this.resetOutcome();
        } else {
          const dialogRef = this.dialog.open(CountrycheckComponent, {
            width: '40%', backdropClass: 'blurred', disableClose: true,
            data: { message: "fail",url:"/task" }
          });
        }
        this.resetOutcome();
      }, (error: any) => {
        const dialogRef = this.dialog.open(CountrycheckComponent, {
          width: '40%', backdropClass: 'blurred', disableClose: true,
          data: { message: "fail",url:"/task" }
        });
        this.resetOutcome();
      });
    }
  }

  convertCostToPoints(cost:number, includeLabel: boolean = true)
  {
    const parts = this.currentUser.sensorConversionFactor.split(':');
    const conversionFactor =  parseFloat(parts[1]) / parseFloat(parts[0]);
    const points =  Math.round(cost * conversionFactor);      
    return includeLabel == true ? points + ' Points' : points;
  }
  

  getCloudData(panelOpenState,index) {
    let type;
    if (this.target.geoJSON && panelOpenState) {
    let sensorData = {};
    if (index === 0) {
      this.isChecked = false;
    }
    const currentDate: any = new Date();
    sensorData['target'] = JSON.stringify(geoParse(this.target.geoJSON));
    sensorData['openWindow'] = moment(this.target.openWindow).utcOffset(0, true).format();
    sensorData['closeWindow'] = moment(this.target.closeWindow).utcOffset(0, true).format();
 
    sensorData['type'] = 'days';
  
    const sensorObj = { sensorData: sensorData };
    this.cloudclicked = false;
  
    if (sensorObj?.sensorData['openWindow'] !== "Invalid date" && sensorObj?.sensorData['closeWindow'] !== "Invalid date" && sensorObj?.sensorData['target'] != 'null') {
      this.apiService.getCloudCoverData(sensorObj).subscribe((res: any) => {      
        this.weatherData = res?.result;
        this.hourly = true;
        this.cloudclicked = true;
      });
    }
  } 
  }
  
  getHourlyCloudData(panelOpenState,item) {
    if (this.target.geoJSON && panelOpenState) {
    let sensorData = {};
    sensorData['target'] = JSON.stringify(geoParse(this.target.geoJSON));
    sensorData['openWindow'] = item?.date;
    sensorData['closeWindow'] = item?.date;
    sensorData['type'] = 'hours';
  
    const sensorObj = { sensorData: sensorData };  
    this.cloudclicked = false;
  
    if (sensorObj?.sensorData['openWindow'] !== "Invalid date" && sensorObj?.sensorData['closeWindow'] !== "Invalid date" && sensorObj?.sensorData['target'] != 'null') {
      this.apiService.getCloudCoverData(sensorObj).subscribe((res: any) => {      
        this.weatherData = res?.result;
        this.hourly = false;
        this.cloudclicked = true;
      });
    }
  }
  }

  openFromIcon(timepicker: NgxMaterialTimepickerComponent) {
    if (!this.formGroup.get('startTime')?.value) {
      this.updateCurrentLocalTime();
      timepicker.defaultTime = this.currentLocalTime;
    } else {
      timepicker.defaultTime = this.currentLocalTime;
    }
    timepicker.open();
  }

  openDate(timepicker: NgxMaterialTimepickerComponent) {
    if (!this.formGroup.get('endTime')?.value) {
      this.updateCurrentLocalTime();
      timepicker.defaultTime = this.currentLocalTime;
    } else {
      timepicker.defaultTime = this.currentLocalTime;
    }
    timepicker.open();
  }

onTimeSet(event: any) {
  let ustDateTime = this.onStartTimeChange(event, this.range.value.start);
  this.date = new FormControl(ustDateTime.utcStartDate);
  this.range.patchValue({
    start: ustDateTime.utcStartDate,
    end: this.range.value.end
  });

  this.range.value.start = ustDateTime.utcStartDate
  this.target['openWindow'] = this.range.value.start;
  const selectedTimeStr = ustDateTime.utcTime;
  const selectedTime = new Date("2000-01-01 " + selectedTimeStr);

  
  const startHours = selectedTime.getHours();
  const startMinutes = selectedTime.getMinutes();
  const startSeconds = selectedTime.getSeconds();  
  
  const combinedDateTime = new Date(
    this.target['openWindow'].getFullYear(),
    this.target['openWindow'].getMonth(),
    this.target['openWindow'].getDate(),
    startHours,
    startMinutes,
    startSeconds
  ); 

  this.target['openWindow'] = combinedDateTime;
  this.isPast = this.isPastDateTime(combinedDateTime);
  this.getHoursDifference();
  this.apiService.updateTaskParams(this.target);

  setTimeout(() => {
    this.formGroup.patchValue({
      startTime: ustDateTime.utcTime
    });

    this.budgetForm.patchValue({
      startTime: ustDateTime.utcTime
    });
  }, 0);

}

onEndTimeSet(event: any) {
  let ustDateTime = this.onEndTimeChange(event, this.range.value.end);
  this.range.patchValue({
    end: ustDateTime.utcEndDate
  });
  this.range.value.end = ustDateTime.utcEndDate;
  if (this.selectedOption === 0) {
    this.range.value.end = this.range.value.start;
  }
  this.target['closeWindow'] = this.range.value.end;
  const selectedTimeStr = ustDateTime.utcTime;
  const selectedTime = new Date("2000-01-01 " + selectedTimeStr);

  const startHours = selectedTime.getHours();
  const startMinutes = selectedTime.getMinutes();
  const startSeconds = selectedTime.getSeconds();
  
  const combinedDateTime = new Date(
    this.target['closeWindow'].getFullYear(),
    this.target['closeWindow'].getMonth(),
    this.target['closeWindow'].getDate(),
    startHours,
    startMinutes,
    startSeconds
  );

  this.target['closeWindow'] = combinedDateTime;
  this.isEndPast = this.isEarlierThan(this.target['closeWindow'], this.target['openWindow']);

  this.getHoursDifference();
  this.apiService.updateTaskParams(this.target);

  setTimeout(() => {
    this.formGroup.patchValue({
      endTime: ustDateTime.utcTime
    });

    this.budgetForm.patchValue({
      endTime: ustDateTime.utcTime
    });
  }, 0);
}

getHoursDifference(): number {
  const millisecondsPerHour = 1000 * 60 * 60;
  const differenceInMilliseconds = this.target['closeWindow']?.getTime() - this.target['openWindow']?.getTime();
  this.timeError = differenceInMilliseconds / millisecondsPerHour;
  return differenceInMilliseconds / millisecondsPerHour;
}

isPastDateTime(dateTime: Date): boolean {
  const givenDate = new Date(dateTime);

  // Get the current date and time in UTC
  const nowUtc = new Date();

  // Convert the current date and time to UTC
  const currentUtcDate = new Date(
    nowUtc.getUTCFullYear(),
    nowUtc.getUTCMonth(),
    nowUtc.getUTCDate(),
    nowUtc.getUTCHours(),
    nowUtc.getUTCMinutes(),
    nowUtc.getUTCSeconds()
  );
  
  const hasPassed = givenDate.getTime() < currentUtcDate.getTime();
  return hasPassed;
}

// Method to check if one date-time is earlier than another
isEarlierThan(end: Date, start: Date): boolean {
  const date1 = new Date(end);
  const date2 = new Date(start);
  
  return date1.getTime() < date2.getTime();
}

drawTaskableAOI(positionArray) {
  this.polygons.push(positionArray[0]);
  this.polygons.forEach((coordinates) => {
    const polygon = this.viewer.entities.add({
      name: 'allowedArea',
      polygon: {
        hierarchy: Cesium.Cartesian3.fromDegreesArray(coordinates.flat()),
        material: Cesium.Color.ORANGERED.withAlpha(0.3)
      }
    });
  });
  this.viewer.zoomTo(this.viewer.entities);
}

  removeEntity () {
    const entities = this.viewer.entities.values;
    for (let i = entities.length - 1; i >= 0; i--) {
      if (entities[i]?.name !== 'allowedArea') {
        this.viewer.entities.remove(entities[i]);
      }
    }
  }

  checkPointInsidePolygon(mousePoint) {
    this.shapeSelected = true;
    let isInside = this.utilsService.pointInPolygon(mousePoint, this.currentUser.taskableAoi.coordinates[0]);
    if (!isInside) {
      const message = `This tasking request is outside the authorised area. Please task within the allowed area.`;
      this.popupService.openPopup(WarningPopup, message,'', '50%');
      this.resetMap();
    }
  }

  loadTaskCostDetails() {
    const validDate = (this.dateFlag && this.selectedOption != 0) || this.range.value.end === null || this.isPast || this.isEndPast;
    const tipAndCueObservation = this.taskStrategy === 'Tip-and-Cue' && this.target.observation && this.tipAndCueBasedOn === 'Observation';
    const tipAndCueRule = this.taskStrategy === 'Tip-and-Cue' && this.target.rule  && this.tipAndCueBasedOn === 'Rules';
    const toiRange = (this.timeError < 4 && this.selectedOption === 0 || this.timeError < 24 && this.selectedOption != 0);
    const noUsecase = (this.taskStrategy === 'Help Select Sensor(s)' && !this.target.useCase)
  
    return (this.formGroup.valid && this.target.geoJSON?.length > 10 && !validDate && (tipAndCueObservation || tipAndCueRule || this.taskStrategy != 'Tip-and-Cue')
            && !toiRange && !noUsecase);
  }

  matchUUIDs(selectedSensors,taskResponse) {
    let sensorList = JSON.parse(selectedSensors);
    
    const minLength = Math.min(sensorList.length, taskResponse.length);  
    for (let i = 0; i < minLength; i++) {
      sensorList[i].uuid = taskResponse[i].uuid;
    }

    return sensorList;
  }

  budgetEnable () {
    const validDate = (this.dateFlag && this.selectedOption != 0) || this.range.value.end === null || this.isPast;
    const toiRange = (this.timeError < 4 && this.selectedOption === 0 || this.timeError < 24 && this.selectedOption != 0);
    return validDate || toiRange;
  }

  resetForm () {
    this.budgetForm.reset();
    this.range.reset();
    this.resetMap();
    this.target.geoJSON = null;
   
    this.selectedOption = 0;
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();

    this.date = new FormControl(new Date(year, month - 1, day + 1));

    this.range = new FormGroup({
      start: new FormControl(new Date(year, month - 1, day + 1)),
      end: new FormControl(new Date(year, month - 1, day + this.defaultWindow)),
    });
    this.clickFrequency = false;
    
  }

  submitBudget () {
    let newTaskParams = {}; 
    let no_of_repeats = 1;
    let noOfMonths = 1;
    let no_of_weeks = 1;
    let startdate = moment(this.target.openWindow).utcOffset(0, true).format();
    let enddate =  moment(this.target.closeWindow).utcOffset(0, true).add(86399, 'seconds').format();
    let time_difference = new Date(enddate).getTime() - new Date(startdate).getTime();
    let days_difference = Math.ceil(time_difference / (1000 * 60 * 60 * 24));
    noOfMonths = Math.ceil(days_difference / 30);
    this.outComeSubmit = true;

    if (
      this.target?.multiTarget?.length > 1) { 
      const message = `Budgeting is only available for a point of interest (POI) or an area of interest (AOI).`;
      this.popupService.openPopup(WarningPopup, message, '', '50%');
      this.outComeSubmit = false;;
      return;
    }
  
    if (this.target.selectedOption === 1) {
      if (this.target.freqOption === 'Weekly') {
        no_of_repeats = Math.ceil(days_difference / 7);
        this.expectedAge = '7 days, 00:00:00';
      } else if (this.target.freqOption === 'Monthly') {
        no_of_repeats = Math.ceil(days_difference / 30);
        this.expectedAge = '30 days, 00:00:00';
      }
      else if (this.target.freqOption === 'Daily') {
        no_of_repeats = days_difference;
        this.expectedAge = '1 day, 00:00:00';
      }
      else if (this.target.subDailyCount) {
        no_of_weeks = Math.ceil(days_difference / 7);
        no_of_repeats = days_difference * this.target.subDailyCount;
        this.expectedAge = '0 day, 04:00:00';
      }
      if (no_of_repeats === 0) {
        no_of_repeats = 1;
      }
    } else {
      no_of_repeats = 1;
    }
  
    newTaskParams['customerid'] = this.currentUser.id;
    newTaskParams['area'] = this.target.area;
    newTaskParams['project'] = this.target.project;
    newTaskParams['fileName'] = this.target?.fileName;
    if (this.target.geotargets != null) {
      newTaskParams['target'] = this.target.geotargets;
    } else {
      newTaskParams['target'] = JSON.stringify(geoParse(this.target.geoJSON));
    }
    newTaskParams['aoiCountry'] = this.target.country;
    newTaskParams['aoiPlace'] = this.target.place;
    newTaskParams['frequency'] = this.target.selectedOption;    
    if (this.target.selectedOption === 1) {
      newTaskParams['repeatFrequency'] = this.target.freqOption;
      newTaskParams['noOfRepeats'] = no_of_repeats;
      newTaskParams['subDailyCount'] = this.target.subDailyCount;
    } else {
      newTaskParams['repeatFrequency'] = '';
      newTaskParams['noOfRepeats'] = no_of_repeats;
      newTaskParams['subDailyCount'] = '';
    }
    newTaskParams['mode'] = this.target.mode;
    newTaskParams['expected_age'] = this.expectedAge;
    newTaskParams['circleCordinates'] = this.target.circleCordinates;
  
    newTaskParams['openWindow'] = moment(this.target.openWindow).utcOffset(0, true).format();
    newTaskParams['closeWindow'] = moment(this.target.closeWindow).utcOffset(0, true).format();
    newTaskParams['resolution'] = this.budgetForm.value.resolution;
    newTaskParams['taskingTier'] = this.budgetForm.value.taskingTier;
    newTaskParams['cloudCover'] = this.budgetForm.value.cloudCover;
    newTaskParams['holdBack'] = this.budgetForm.value.holdBack;
    newTaskParams['sensorType'] = this.budgetForm.value.sensorType;
    newTaskParams['numOfWeeks'] = no_of_weeks;
    newTaskParams['noOfMonths'] = noOfMonths;
    newTaskParams['intent'] = this.budgetForm.value?.intent;
    this.apiService.createBudget(newTaskParams).subscribe((res: any) => {
      
      newTaskParams['budgetRange'] =  res?.response?.minMaxCost;
      newTaskParams['sensors'] =  res?.response?.sensors;
      newTaskParams['budget'] =  res?.response?.budget;
      newTaskParams['budgetPoints'] =  res?.response?.budgetPoints;

      if (res?.msg === 'Success') { 
        const dialogRef = this.dialog.open(BudgetpopupComponent, {
          backdropClass: 'blurr', disableClose: true, width: '40%',
          data: { data: res.response,strategy:this.strategyOptions[0], intent:this.budgetForm.value?.intent,purpose:this.purposeOption[0],
            pointUser: this.currentUser.pointsEligible
          },
        });

        dialogRef.afterClosed().subscribe((result: { action: string, data?: any }) => {
           if (result.action === 'save') {
            const budgetName = result.data;
            newTaskParams['budgetName'] = budgetName;
            this.apiService.saveBudget(newTaskParams).subscribe((res: any) => {
              if (res?.msg === 'Success') { 
                this.router.navigate(['/budget']);
              } else {
                const dialogRef = this.dialog.open(CountrycheckComponent, {
                  width: '40%', backdropClass: 'blurred', disableClose: true,
                  data: { message: "budgetFail",url:"/budget",response: res?.response}
                });
              }
            }); 
          } else if (result.action === 'save_task') {
           newTaskParams['project'] = result?.data?.project;
            newTaskParams['taskname'] = result?.data?.taskname;
            newTaskParams['taskStrategy'] = result?.data?.taskStrategy?.value;
            newTaskParams['sensors'] = result?.data?.sensors;
            newTaskParams['intent'] = result?.data?.intent;
            newTaskParams['purpose'] = result?.data?.purpose;
            newTaskParams['projectid'] = null;
            newTaskParams['feasibilityTask'] =false;
            newTaskParams['observation'] = null;
            newTaskParams['tipAndCueBasedOn'] = "";
            newTaskParams['openWater'] = this.target?.openWater

           delete newTaskParams['cloudCover'];
            this.apiService.newTaskSubmit(newTaskParams).subscribe((res: any) => {
             if (res?.msg === 'Success') { 
              let data = JSON.parse(res?.response);
              let taskid;
              let sensorList = this.matchUUIDs(newTaskParams['sensors'], data);
              newTaskParams['sensors'] = JSON.stringify(sensorList);
              if (data && data.length > 0) {
                taskid = res?.taskid;
                newTaskParams['taskId'] = taskid;
                this.apiService
                  .genCollection(
                    this.currentUser.id,
                    this.currentUser.email,
                    newTaskParams
                  )
                  .subscribe((res: any) => {
                    const taskid = res?.id;
                  });
              }
              const dialogRef = this.dialog.open(ConfirmDialog, {
                backdropClass: 'blurr', disableClose: true,
                data: { taskid: taskid, taskname:  newTaskParams['taskname'], response: res, type: 'normal',from:'budget' },
              });
               
              } else {
                this.outComeSubmit = false;
                const dialogRef = this.dialog.open(CountrycheckComponent, {
                  width: '40%', backdropClass: 'blurred', disableClose: true,
                  data: { message: "budgetFail",url:"/budget",response: res?.response}
                });
              }
            });
          } else{
            this.outComeSubmit = false;
          }
        });

      } else {
        this.outComeSubmit = false;
        const dialogRef = this.dialog.open(CountrycheckComponent, {
          width: '40%', backdropClass: 'blurred', disableClose: true,
          data: { message: "budgetFail",url:"/budget",response: res?.response}
        });
      }
    }, (error: any) => {
      this.outComeSubmit = false;
      const dialogRef = this.dialog.open(CountrycheckComponent, {
        width: '40%', backdropClass: 'blurred', disableClose: true,
        data: { message: "budgetFail",url:"/budget",response: error}
      });
    }); 
  }



  sortArray(array: string[]): string[] {
    return array.sort((a, b) => {
      return this.getPriority(a) - this.getPriority(b);
    });
  }
  sortResolution(a: { key: string, name: string }, b: { key: string, name: string }): number {
    const isNumeric = (value: string): boolean => !isNaN(parseFloat(value.charAt(0)));
    
    const parseResolution = (res: string): { value: number, text: string } => {
      const match = res.match(/(\d+(\.\d+)?)(.*)/);
      const value = match ? parseFloat(match[1]) : NaN;
      const text = match ? match[3].trim() : res;
      return { value, text };
    };

    const resA = parseResolution(a.name);
    const resB = parseResolution(b.name);

    if (isNumeric(a.name) && isNumeric(b.name)) {
      if (resA.value !== resB.value) {
        return resA.value - resB.value;
      }
      return resA.text.localeCompare(resB.text);
    } else if (isNumeric(a.name)) {
      return -1;
    } else if (isNumeric(b.name)) {
      return 1;
    }
    return a.name.localeCompare(b.name);
}
  
  getPriority(value: string): number {
    if (value.includes('days')) {
      return 1;
    } else if (value.includes('months')) {
      return 2;
    } else {
      return 3;
    }
  }
  setUTCTodayDate() {
    const now = new Date();
    const utcYear = now.getUTCFullYear();
    const utcMonth = now.getUTCMonth();
    const utcDate = now.getUTCDate();
    this.todayDate = new Date(Date.UTC(utcYear, utcMonth, utcDate));    
  }

  convertToPoints(cost:number) : number
  {
    const parts = this.currentUser.sensorConversionFactor.split(':');
    const conversionFactor =  parseFloat(parts[1]) / parseFloat(parts[0]);
    return  Math.round(cost * conversionFactor);      
  }
  convertToCost(point:number) : number
  {
    const parts = this.currentUser.sensorConversionFactor.split(':');
    const conversionFactor =  parseFloat(parts[1]) / parseFloat(parts[0]);
    return  Math.round(point / conversionFactor);      
  }
  capitalizeFirstLetter(value: string): string {
    if (!value) {
      return '';
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  updateOptions() {
    this.cloudCoverOptions = this.budgetInputs.cloudCoverKeys || [];
    this.resolutionOptions = this.budgetInputs.resolutionArr || [];
    this.taskingTierOptions = this.budgetInputs.tierArr || [];
    this.holdBackOptions = this.budgetInputs.holdbackArr || [];
    
    const isGreaterThan20 = this.cloudCoverOptions.some(option => {
      const percentage = parseInt(option, 10);
      return percentage > 20;
    });

    this.updateFormControlValidators('cloudCover', this.cloudCoverOptions);

    this.updateFormControlValidators('resolution', this.resolutionOptions);
    this.updateFormControlValidators('taskingTier', this.taskingTierOptions);
  }
  updateFormControlValidators(controlName: string, options: string[]) {
    const control = this.budgetForm.get(controlName);
    if (options.length > 0) {
      control.setValidators([Validators.required]);
    } else {
      control.clearValidators();
    }

    control.updateValueAndValidity();
  }

  resetDateDefault()
  {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();

    this.date = new FormControl(new Date(year, month - 1, day + 1));

    this.range = new FormGroup({
      start: new FormControl(new Date(year, month - 1, day + 1)),
      end: new FormControl(new Date(year, month - 1, day + this.defaultWindow)),
    });
  }

  onStartTimeChange(event:any, date:any)
  {
    let utcEndDate;
    const localStartDate = moment(date);
    const localTime = `${localStartDate.format('YYYY-MM-DD')} ${event}`;
    const localMoment = moment(localTime, 'YYYY-MM-DD HH:mm A');
    const utcMoment = localMoment.utc();
    const utcStartDate = new Date(utcMoment.format('YYYY-MM-DD'));
    const utcTime = utcMoment.format('hh:mm A');
    if(this.selectedFrequencyID !== 0) {
      utcEndDate = moment(utcStartDate).add(30, 'days').toDate();
    } else {
      utcEndDate = utcStartDate;
    }
    return { utcTime, utcStartDate, utcEndDate };
  }

  onEndTimeChange(event:any, date:any)
  {
    const localStartDate = moment(date);
    const localTime = `${localStartDate.format('YYYY-MM-DD')} ${event}`;
    const localMoment = moment(localTime, 'YYYY-MM-DD HH:mm A');
    const utcMoment = localMoment.utc();
    const utcEndDate = new Date(utcMoment.format('YYYY-MM-DD'));
    const utcTime = utcMoment.format('hh:mm A');
    return { utcTime, utcEndDate };
  }
  updateCurrentLocalTime() {
    this.currentLocalTime = moment().format('HH:mm A');
  }

  resetOutcome() {
    this.outComeForm.patchValue({
      sensorType: '',
      resolution: '',
      cloudCover: '',
      latency: '',
      budget: '',
      updateFrequency: ''
    });
    this.outComesensor = [];
    this.outComeSubmit = false;
  }

  drawCircles() {
    const radius = RF_RADIUS * 1000;
    this.removeCircles();
    for (let i = 0; i < this.pointArray.length; i++) {
      const coords = this.utilsService.extractCoordinates(this.pointArray[i]);

      // Create a circle entity for each point
      this.viewer.entities.add({
        name:'circles',
        position: Cartesian3.fromDegrees(coords.lng, coords.lat),
        ellipse: {
          semiMajorAxis: radius,
          semiMinorAxis: radius,
          material: Color.BLUE.withAlpha(0.5),  // Semi-transparent blue color
          outline: true,
          outlineColor: Color.BLACK,
          outlineWidth: 2,
        },
      });
    }

    if (this.pointArray.length === 1) {
      const coords = this.utilsService.extractCoordinates(this.pointArray[0]);
      let coordinates = this.utilsService.getCirclePolygon(coords,radius);
      this.target.geoJSON = coordinates;
      let area = Math.PI * Math.pow(RF_RADIUS, 2);      
      this.target.area = area.toFixed(2);
      this.target.targetPoints = this.pointArray;
      this.dataService.updateTarget(this.target);
    } else this.target.targetPoints = null;

    this.viewer.flyTo(this.viewer.entities);
    this.checkOverlap();
  }


  checkOverlap() {
    const radius = RF_RADIUS * 1000;
    let coords: any = [];  
    this.dataService.clearOverlapData();
    // Extract coordinates
    for (let i = 0; i < this.pointArray.length; i++) {
      const coord = this.utilsService.extractCoordinates(this.pointArray[i]);
      coords.push(coord);
    }
  
    if (coords.length === 2) {
      // Two points, check for overlap and calculate percentage
      const distance = this.computeDistance(coords[0], coords[1]);
      const overlap = distance < (2 * radius);
  
      if (overlap) {
        const overlapPercentage = this.utilsService.calculateOverlapPercentage(distance, radius);
        this.dataService.setOverlapValue(overlapPercentage.toFixed(2), 1)
      }
    } else if (coords.length === 3) {
      // Three points, check for overlaps and handle multiple overlaps
      const distance12 = this.computeDistance(coords[0], coords[1]);
      const distance23 = this.computeDistance(coords[1], coords[2]);
      const distance13 = this.computeDistance(coords[0], coords[2]);
  
      const overlap12 = distance12 < (2 * radius);
      const overlap23 = distance23 < (2 * radius);
      const overlap13 = distance13 < (2 * radius);
  
      const overlapCount = [overlap12, overlap23, overlap13].filter(Boolean).length;
      let overlapPercentage = 0;

      if (overlapCount === 1) {
        // Calculate overlap percentage if only one overlap exists
        if (overlap12) {
          overlapPercentage = this.utilsService.calculateOverlapPercentage(distance12, radius);
        } else if (overlap23) {
          overlapPercentage = this.utilsService.calculateOverlapPercentage(distance23, radius);
        } else if (overlap13) {
          overlapPercentage = this.utilsService.calculateOverlapPercentage(distance13, radius);
        }
      }
      this.dataService.setOverlapValue(overlapPercentage.toFixed(2),overlapCount)
    }
    return false;
  }


  // Calculate the distance between two geographic coordinates
  computeDistance(coords1: { lng: number, lat: number }, coords2: { lng: number, lat: number }): number {    
    const position1 = this.toCartesian3(coords1.lng, coords1.lat);
    const position2 = this.toCartesian3(coords2.lng, coords2.lat);
    return Cartesian3.distance(position1, position2);
  }
  
  // Convert geographic coordinates to Cartesian3
  toCartesian3(lng: number, lat: number): Cartesian3 {
    const cartographic = Cesium.Cartographic.fromDegrees(lng, lat);
    return this.viewer.scene.globe.ellipsoid.cartographicToCartesian(cartographic);
  }

  drawPoi(coordinates) {
    const lng = coordinates[0],
      lat = coordinates[1];

      this.viewer.entities.add({
        name:'circles',
        position: Cesium.Cartesian3.fromDegrees(lng, lat),
        billboard: {
          image: 'assets/images/marker-icon.png',
          show: true, // default
          eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0), // default
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // default
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // default: CENTER
          alignedAxis: Cesium.Cartesian3.ZERO, // default
          width: 20, // default: undefined
          height: 30, // default: undefined
        },
      });
  }

  drawCircle(coordinates) {
    const radius = RF_RADIUS * 1000;
    const lng = coordinates[0],
      lat = coordinates[1];

      // Create a circle entity for each point
      this.viewer.entities.add({
        name:'circles',
        position: Cesium.Cartesian3.fromDegrees(lng, lat),
        ellipse: {
          semiMajorAxis: radius,
          semiMinorAxis: radius,
          material: Color.BLUE.withAlpha(0.4)
        }});
      }

  updateFrequencyChange(value:any): void {
    const startTime = moment(this.target.openWindow).utcOffset(0, true);
    const endTime = moment(this.target.closeWindow).utcOffset(0, true);
    const clydePeriodDuration = endTime.diff(startTime, 'days', true); 

    if (value.operatorKey === "ClydeSpace") {
        if (clydePeriodDuration < 90) {
            const message = `We are unable to accept this task as this sensor requires TOI should be minimum 3 months. Please change your time of interest or choose an alternative provider.`;
            this.showWarningPopup(message);
            this.outComeForm.patchValue({ updateFrequency: '' });
            return; 
        }
    }
    this.outcomePopUpData = {};
    if (["ClydeSpace", "Spire"].includes(value.operatorKey)) {
        this.openOperatorDialog(value.operatorKey);
    }
  }

  private showWarningPopup(message:string): void {
      
      this.popupService.openPopup(WarningPopup, message, '', '50%');
  }

  private openOperatorDialog(operatorKey: string): void {
      const dialog = this.dialog.open(OperatorPopupComponent, {
          minWidth: '50%',
          backdropClass: 'blurred',
          disableClose: true,
          data: { selectedRow: {}, operatorKey, daysCount: '', subscriptionAmount: null, hideTooltip: true },
      });

      dialog.afterClosed().subscribe((result) => {
        if (result != 'close') {
          const historicalCost = result.isHistorical? 25 : 0;
          if(operatorKey === "Spire") {
           this.outcomePopUpData.noOfRefreshes = result?.refreshCount;
           this.outcomePopUpData.isHistorical = result?.isHistorical;
           this.outcomePopUpData.historicalCost = historicalCost;
           this.outcomePopUpData.noOfHistoricalData = result?.fetchCount;
          } else if (operatorKey === "ClydeSpace") {
           this.outcomePopUpData.isHistorical = result?.isHistorical;
           this.outcomePopUpData.noOfHistoricalData = result?.daysCount;
          }
        }
        else {
          this.outComeForm.patchValue({ updateFrequency: '' });
        }
      });
  }

  openSensorPreview() {
    const dialog = this.dialog.open(TaskcostSensorPreviewComponent, {
      minWidth: '50%',
      minHeight: '40%',
      maxHeight: '87vh',
      backdropClass: 'blurred',
      disableClose: true,
    });
  }
}
