<div class="container">
  <div class="card">
    <div class="task-head">
      <h4>Add to Balance</h4>
    </div>

    <div class="form-vertical">
      <form action="">
        <div>
          <label for="amount">Amount</label>
          <input
            type="number"
            placeholder="Enter amount"
            (keyup)="somethingChanged($event)"
          />
          <div
            style="color: #f44336; font-size: 13px; margin-top: -17px"
            *ngIf="error"
          >
            Invalid amount format. Only two decimal points allowed.
          </div>
        </div>
        <div>
          <label for="currency">Currency</label>
          <input type="text" placeholder="USD" readonly />
        </div>
      </form>

      <!-- <div style="color: red;font-size: 15px;
      font-weight: 700; margin-bottom: 10px;">
      Alert: The platform is undergoing an online payment processor test. <br/> We will be
      back soon to accept online payments via Stripe and PayPal.
    </div> -->

      <div class="left-align">
        <div class="row-align">
          <span class="option">Select option : </span>
          <mat-radio-group
            [(ngModel)]="selectedPaymentOption"
            (change)="select()"
          >
            <mat-radio-button value="PayPal" style="padding-right: 10px" [disabled]="isInIframe">
              <img
                width="73px"
                src="../../../assets/images/paypal-logo.png"
                alt=""
                style="cursor: default"
              />
            </mat-radio-button>
            <mat-radio-button value="Stripe" [disabled]="isInIframe">
              <img
                width="45px"
                src="../../../assets/images/stripe-logo.png"
                alt=""
                style="cursor: default"
              />
            </mat-radio-button>
            &nbsp;&nbsp;
            <mat-radio-button *ngIf="!payment || isInIframe" value="Bank Transfer" class="text"> 
              Bank Transfer
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>

    <div class="btns">
      <button
        mat-flat-button
        (click)="deposit()"
        [disabled]="amountAdded || error || methodSelected"
      >
        Deposit
      </button>
      <button mat-stroked-button (click)="onNoClick()">Close</button>
    </div>
  </div>
</div>
