<div class="container">
    <div *ngIf="data.type === 'vessel'" class="card" [formGroup]="formGroup">
        <div class="task-head">
            <h4>Required!</h4>
        </div>
        <p>
            Filter by vessel length
            <span>
                <mat-icon (click)="selectGreaterThan()"
                    [class.highlight]="isRightHighlighted">keyboard_arrow_right</mat-icon>
                <mat-icon (click)="selectLessThan()"
                    [class.highlight]="isLeftHighlighted">keyboard_arrow_left</mat-icon>
            </span>
            <span style="padding-left: 5px;">
                <mat-form-field appearance="outline" class="option" style="width: 15%;">
                    <input matInput formControlName="vesselLength" class="matOption" type="number" #inputField />
                </mat-form-field>
            </span>
            <span style="padding-left: 10px;">meters</span>
        </p>

        <div class="btns">
            <button (click)="onClick($event, 1)" class="custom-button">Close</button>
            <button mat-flat-button (click)="onClick($event, 2)" style="width: 120px;"
                [disabled]="this.error || (formGroup.value.vesselLength <= 0)" type="submit">Submit</button>
        </div>
    </div>

    <div *ngIf="data.type === 'ais'" class="card" [formGroup]="aisFormGroup">
        <div class="task-head">
            <h4>Required!</h4>
        </div>
        <p>
            What is the historical period required for the trail in the past seven days?
        </p>

        <span style="display: flex;flex-direction: column;align-items: center;">
            <div>
                <mat-form-field appearance="outline" style="width: 75%;">
                    <input matInput formControlName="historicalTime" class="matOption" type="number" #inputField />
                </mat-form-field>
                <span style="padding-left: 10px;">minutes</span>
            </div>

        </span>

        <div class="btns">
            <button (click)="onSubmit($event, 1)" class="custom-button">Close</button>
            <button mat-flat-button (click)="onSubmit($event, 2)" style="width: 120px;"
                [disabled]="this.error || (!aisFormGroup.value.historicalTime)" type="submit">Submit</button>
        </div>
    </div>

    <div *ngIf="data.type === 'clyde'" class="card" [formGroup]="clydeAisFormGroup">
        <div class="task-head">
            <h4>Required!</h4>
        </div>
        <p>
            What is the historical period required for the trail?
        </p>

        <span style="display: flex;flex-direction: column;align-items: center;">

            <div>
                <mat-checkbox
                    [checked]="selectedOption === 'minutes'"
                    (change)="onCheckboxChange($event, 'minutes')"
                    style="margin: 0 0 8px 10px">
                    <mat-label class="label">Minutes</mat-label>
                </mat-checkbox>
                <mat-checkbox
                    [checked]="selectedOption === 'days'"
                    (change)="onCheckboxChange($event, 'days')"
                    style="margin: 0 0 8px 10px">
                    <mat-label class="label">Days</mat-label>
                </mat-checkbox>
            </div>

            <div>
                <div>
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="historicalTime" class="matOption" type="number" #inputField />
                    </mat-form-field>
                    <span style="padding-left: 10px;">{{selectedOption}}</span>
                </div>
                <div *ngIf="this.duration > this.data?.historicalDays" class="error-container">
                    <span class="error-message">
                        Your historical data request exceeds the historical period included in the
                        tasking. You can access historical data within the past {{this.data?.historicalDays}} days.
                    </span>
                </div>
            </div>

        </span>

        <div class="btns">
            <button (click)="onSubmit($event, 1)" class="custom-button">Close</button>
            <button mat-flat-button (click)="onSubmit($event, 2)" style="width: 120px;"
                [disabled]="this.error || (!clydeAisFormGroup.value.historicalTime)" type="submit">Submit</button>
        </div>
    </div>

    <div *ngIf="data.type === 'audio'" class="card">
        <div class="task-head">
            <h4>Audio Control</h4>
        </div>

        <div *ngFor="let item of audioFiles; let i = index">
            <p style="display: flex; align-items: center; justify-content: center;" fxLayoutGap="30px">
                <span>
                    <img width="20px" src="../../../assets/images/audio.png" />
                   {{data?.signals.product }}
                </span>
                <span>
                   {{item?.timeStamp}}
                </span>
                <!-- Play/Pause button -->
                <button mat-icon-button class="align-icon" (click)="togglePlayPause(item, i)">
                    <mat-icon style="color: #1059A9; font-size: 26px;"
                    class="emails">
                        {{ item?.isPlaying ? 'pause_circle' : 'play_circle' }}
                    </mat-icon>
                </button>

                <!-- Download button -->
                <button mat-icon-button class="align-icon" (click)="getAudio(item, 'download')">
                    <mat-icon *ngIf="item.isDownloading" style="color: #1059A9; font-size: 26px;" class="emails">download</mat-icon>
                    <img *ngIf="!item.isDownloading" style="cursor: pointer;" [src]="downloadImage" />
                </button>
            </p>
        </div>
    
        <div class="btns">
            <button mat-flat-button (click)="onClick($event, 2)" style="width: 120px;padding: 2px 30px;height: 40px;" [disabled]="this.error"
                type="submit">Close</button>
        </div>
    </div>

    <div *ngIf="data.type === 'date'" class="card" [formGroup]="formGroup">
        <div class="task-head">
            <h4>Required!</h4>
        </div>
        <p>
            What is the period required for the trail?
        </p>
    
        <span style="display: flex;flex-direction: column;align-items: center;">
            <div>
                <mat-form-field appearance="outline">
                    <mat-date-range-input placeholder="Select dates" [formGroup]="range" [rangePicker]="picker"
                        [min]="minDate" [max]="maxDate" [comparisonStart]="range.value.start"
                        [comparisonEnd]="range.value.end" style="padding: 0">
                        <input matStartDate formControlName="start" placeholder="Start date"
                            style="border: 0; height: 100%; padding: 0" />
    
                        <input matEndDate formControlName="end" placeholder="End date"
                            style="border-right: 0;border-left: 0; padding: 0;" />
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>
    
        </span>
    
        <div class="btns">
            <button (click)="onSubmit($event, 1)" class="custom-button">Close</button>
            <button mat-flat-button (click)="submitDate()" style="width: 120px;" type="submit"
            [disabled]="!this.range.value.start || !this.range.value.end">Submit</button>
        </div>
    </div>

    <div *ngIf="data.type === 'aisVessel'" class="card" [formGroup]="vesselFormGroup">
        <div class="task-head" style="text-align: center;">
            <h4>Required!</h4>
        </div>
        <div style="padding-left: 37px;padding-right: 30px;">
            <p style="display: flex; align-items: baseline;gap: 19px;">
                Filter by vessel length
                <span>
                    <span>
                        <mat-icon (click)="selectGreaterThan()"
                            [class.highlight]="isRightHighlighted">keyboard_arrow_right</mat-icon>
                        <mat-icon (click)="selectLessThan()"
                            [class.highlight]="isLeftHighlighted">keyboard_arrow_left</mat-icon>
                    </span>
    
                    <span style="padding-left: 5px;">
                    <mat-form-field appearance="outline" class="option" style="width: 61%; height: 50px;">
                        <input matInput formControlName="vesselLength" class="matOption" type="number" #inputField />
                    </mat-form-field>
                    <span style="padding-left: 10px;">meters</span>
                    </span>
                </span>
            </p>

            <p style="display: flex; align-items: baseline;gap: 19px;">
                Filter by vessel speed
                <span>
                    <span>
                        <mat-icon (click)="selectSpeedGreaterThan()"
                            [class.highlight]="isRightSpeed">keyboard_arrow_right</mat-icon>
                        <mat-icon (click)="selectSpeedLessThan()"
                            [class.highlight]="isLeftSpeed">keyboard_arrow_left</mat-icon>
                    </span>
    
                    <span style="padding-left: 5px;">
                    <mat-form-field appearance="outline" class="option" style="width: 61%; height: 50px;">
                        <input matInput formControlName="vesselSpeed" class="matOption" type="number" #inputField />
                    </mat-form-field>
                    <span style="padding-left: 10px;">knot</span>
                    </span>
                </span>
            </p>
    
            <p style="display: flex; align-items: baseline;gap: 13px;">
                Filter by country of registration
                <span>
                        <mat-form-field appearance="outline" class="optionDetails">
                            <mat-select [disableOptionCentering]="true" panelClass="matopenDropdown"
                              placeholder=" Select country " class="selectDetails" formControlName="flag"
                              >
                              <mat-option *ngFor="let option of vesselCountries" [value]="option">
                                {{option}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                </span>
            </p>
    
            <p style="display: flex; align-items: baseline;gap: 90px;">
                Filter by destination
                <span>
                        <mat-form-field appearance="outline" class="optionDetails">
                            <mat-select [disableOptionCentering]="true" panelClass="matopenDropdown"
                              placeholder=" Select destination " class="selectDetails" formControlName="destination"
                              >
                              <mat-option *ngFor="let option of vesselDestinations" [value]="option">
                                {{option}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                </span>
            </p>

            <p style="display: flex; align-items: baseline;gap: 90px;">
                Filter by vessel type
                <span>
                        <mat-form-field appearance="outline" class="optionDetails">
                            <mat-select [disableOptionCentering]="true" panelClass="matopenDropdown"
                              placeholder=" Select vessel type " class="selectDetails" formControlName="vesselType"
                              >
                              <mat-option *ngFor="let option of vesselTypes" [value]="option">
                                {{option}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                </span>
            </p>
        </div>

        <div class="btns">
            <button (click)="onClick($event, 1)" class="custom-button">Close</button>
            <button (click)="resetForm()" type="reset" class="custom-button">Clear</button>
            <button mat-flat-button (click)="onSave()" style="width: 120px;"
            [disabled]="isFormEmpty()" type="submit">Submit</button>
        </div>
    </div>

    <div *ngIf="data.type === 'refreshDateFilter'" class="card">
        <div class="task-head">
            <h4>Required!</h4>
        </div>
    
        <span style="display: flex;flex-direction: column;align-items: center;">
            <div [formGroup]="DateformGroup" style="display: flex;flex-direction: column;
                gap: 7px;">
                <div style="gap: 40px;display: flex;align-items: baseline;">
                    <div class="align-label">
                        <label class="label-style">Start Date<span>*</span></label>
                        <mat-form-field appearance="outline">
                            <input class="dateField" matInput [matDatepicker]="datepicker"
                                [matDatepickerFilter]="DateFilter" readonly [min]="this.minStartDate"
                                [max]="this.maxEndDate" placeholder="Select start date" formControlName="startDate"
                                (dateChange)="handleDateChange(1)" />
                            <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                            <mat-datepicker #datepicker></mat-datepicker>

                            <mat-error *ngIf="dateFileds.startDate.touched">
                                <mat-error *ngIf="dateFileds.startDate.errors && dateFileds.startDate.errors.required" class="error-valid">Start date is required</mat-error>
                                <mat-error *ngIf="DateformGroup.value.startDate && DateformGroup.value.startDate < this.minStartDate" class="error-valid">
                                    Invalid start date
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
    
                    <div class="align-label">
                        <label class="label-style">Start Time (UTC)<span>*</span></label>
                        <mat-form-field appearance="outline">
                            <input class="dateField" type="text" matInput [ngxTimepicker]="timepicker" readonly
                                style="border: none !important; height: 18px;padding-left: 1px !important;"
                                placeholder="Select start time" formControlName="startTime" />
    
                            <mat-icon matSuffix (click)="openDate(timepicker)" [ngClass]="{'icon-date' : darkTheme}"
                                style="color: #7f7f7f; cursor: pointer;padding-top: 0px;"
                                matTooltip="Local time to UTC converter">schedule</mat-icon>
                            <ngx-material-timepicker #timepicker (timeSet)="onTimeSet($event)"
                                [appendToInput]="false"></ngx-material-timepicker>

                                <mat-error *ngIf="dateFileds.startTime.touched">
                                    <mat-error *ngIf="dateFileds.startTime.errors && dateFileds.startTime.errors.required" class="error-valid">Start time is required</mat-error>
                                </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div style="gap: 40px;display: flex;align-items: baseline;">
                    <div class="align-label">
                        <label class="label-style">End Date<span>*</span></label>
                        <mat-form-field appearance="outline">
                            <input class="dateField" matInput [matDatepicker]="endDatepicker"
                                [matDatepickerFilter]="DateFilter" readonly [min]="this.minStartDate"
                                [max]="this.maxEndDate" placeholder="Select end date" formControlName="endDate"
                                (dateChange)="handleDateChange(2)" />
                            <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDatepicker></mat-datepicker>
                            <mat-error *ngIf="dateFileds.endDate.touched">
                                <mat-error *ngIf="dateFileds.endDate.errors && dateFileds.endDate.errors.required" class="error-valid">End date is required</mat-error>
                            </mat-error>
                            <mat-error *ngIf="dateFileds.endDate.errors?.endDateInvalid" class="error-valid">
                                End date should be greater than start date
                            </mat-error>
                        </mat-form-field>
    
                    </div>
                    <div class="align-label">
                        <label class="label-style">End Time (UTC)<span>*</span></label>
                        <mat-form-field appearance="outline">
                            <input class="dateField" type="text" matInput [ngxTimepicker]="pickers" readonly
                                style="border: none !important; height: 18px;padding-left: 1px !important;"
                                placeholder="Select end time" formControlName="endTime" />
    
                            <mat-icon matSuffix (click)="openDate(pickers)"
                                style="color: #7f7f7f; cursor: pointer;padding-top: 0px;"
                                [ngClass]="{'icon-date' : darkTheme}"
                                matTooltip="Local time to UTC converter">schedule</mat-icon>
                            <ngx-material-timepicker #pickers (timeSet)="onEndTimeSet($event)"
                                [defaultTime]="currentLocalTime" [defaultTime]="currentLocalTime"></ngx-material-timepicker>

                                <mat-error *ngIf="dateFileds.endTime.touched">
                                    <mat-error *ngIf="dateFileds.endTime.errors && dateFileds.endTime.errors.required" class="error-valid">End time is required</mat-error>
                                </mat-error>
                        </mat-form-field>
                    </div>
    
                </div>
    
            </div>
    
        </span>
    
        <div class="btns">
            <button (click)="onSubmit($event, 1)" class="custom-button">Close</button>
            <button mat-flat-button (click)="applyFilter()" style="width: 120px;" type="submit"
            [disabled]="!DateformGroup.valid">Submit</button>
        </div>
    </div>
</div>