import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';

export interface ChangeLog {
  id: number;
  title: string;
  description: string;
  what: string;
  why: string;
  how: string;
  date: string;
  expanded: boolean;
  category: string;
  imageLinks: Array<{ link: string, route: any}>;
  order: number;
  sensorDetails?: SensorDetail[];
  itemList?: any;
}

interface SensorDetail {
  sensorType: string;
  resolution: string;
  numberOfBands?: number | string;
  availableBands?: string[];
  taskingTiers?: string[];
  modes?: string[];
  frequency?: string;
  polarisation?: string;
  screenshot: string;
  numberOfModes?: any;
  route?: string;
  expanded: boolean
}

@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.scss'],
  animations: [
    trigger('expandCollapse', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('300ms ease-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class WhatsNewComponent implements OnInit {
  features: ChangeLog[];
  activeDate: string = '';
  @ViewChild('timelineContainer') timelineContainer: ElementRef;
  activeIndex = 0;
  categories = [
    {
      featureKey : 'all',
      featureName: 'All Features'
    },
    {
      featureKey : 'platform',
      featureName: 'Platform Features'
    },    {
      featureKey : 'sensors',
      featureName: 'Sensors'
    }
  ];
  selectedIndex = 0;
  filteredFeatures: ChangeLog[];
  darkTheme: boolean;
  currentUser: any;

  constructor(
    private localStorageService: DataService,
    private authService: AuthService,
    private router: Router
  ) {
    const castUser = this.authService.castCurrentUser.subscribe(
      async (res: any) => {        
        this.currentUser = await res;
        if (!this.currentUser?.verified) {
          this.router.navigate(['login']);
          this.authService.logout();
        }
        castUser.unsubscribe();
      }
    );

    this.getFeatures();
    this.localStorageService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;
    });
  }

  getFeatures() {
    this.features = [
      {
        id: 1,
        title: 'Annotation',
        description: 'Make notes on the collection page map.',
        what: `Users can annotate the data delivered on the map with text.`,
        why:`Making notes on objects or features of interest in the tasked data, including imaging and non-imaging data, assists in capturing the user's interpretation of the data for further analysis and collaboration.`,
        how: `Users can right-click on any point in the data and annotate within the pop-up dialogue. When saving the notes made, an icon is placed on the coordinate. Left-clicking the icon brings back the notes for editing or deleting.
         Once annotated, the split screen also shows the notes, comparing an area or point of interest with different sensors or periods.`,
        date: '2024-07-15',
        imageLinks: [
          {link: '../../../assets/feature-images/annotation.png', route: '/collections'},        
        ],
        expanded: false,
        category: 'platform',
        order: 1
      },
      {
        id: 2,
        title: 'Tasking over a point of interest on the data delivered',
        description: 'Task from the collection screen.',
        what: `Users can now task from the map in the collection screen, where the data and the feeds delivered necessitate further investigation.`,
        why:`The data and/or feeds delivered, say vessels detected, may have pointed to a specific vessel of interest. Tasking over the vessel now with another sensor, such as a high-resolution optical or SAR, would give the user further information about the vessel, depending on the weather and time.`,
        how: `Only point tasking is enabled so that users can right-click on a coordinate of interest on the tasked- data delivered. They then provide a minimum set of requirements, and the platform will assign tasks instantly based on availability. The ordered task cannot be canceled because it is submitted for immediate tasking.`,
        date: '2024-09-06',
        imageLinks: [
          {link: '../../../assets/feature-images/collection-task.png', route: '/collections'},
        ],
        expanded: false,
        category: 'platform',
        order: 2
      },
      {
        id: 3,
        title: 'Value Added Data Products: Elevation Products, Super Resolution and Indices',
        description: 'When tasking, multiple value-added data products (VADPs) can now be ordered.',
        what:'When tasking, users can order multiple value-added data products such as elevation models, super-resolution and indices.',
        why:'Value-added data products enhance the use of Earth observation data. Today, users experience significant friction and time lag for these products, produced internally or through their third-party service provider.',
        how: `Users can add one or more VADPs from the cost section of the new task screen to their tasking workflow. When added, the cost of the products is calculated and made available on the dashboard and further in the payment
         screen with their detailed breakdown, along with the sensor cost. The cost calculation varies based on either sqkm or task. For example, an elevation product is based on sqkm, whereas indices are charged for each delivery.
         Based on their service level agreements, the VADPs are delivered soon after the data is delivered.
         
         It is important to note that users must actively release the data for VADPs after they are happy with the data delivered. If they no longer want the VADPs, they could ask for a refund of the price paid for the VADP. 
         The products can also be returned for credit if they do not meet the requisite quality.`,
        date: '2024-10-07',
        imageLinks: [
          {link: '../../../assets/feature-images/vadp.png', route: '/newtask'},
        ],
        expanded: false,
        category: 'platform',
        order: 3
      },
      {
        id: 4,
        title: 'New Tasking Strategy: Help Select Sensors',
        description: `Guiding users to a select set of sensors based on their use case.`,
        what:'A tasking workflow to narrow down the selection of sensors for a given use case.',
        why:'The platform has several sensors, which can overwhelm some users.',
        how:'The platform shows a curated set of pre-defined use cases as users provide their purposes. When a use case is selected, the recommended sensors are down-selected.',
        date: '2024-10-17',
        imageLinks: [
          {link: '../../../assets/feature-images/help-select.png', route: '/newtask'}
        ],
        expanded: false,
        category: 'platform',
        order: 4
      },
      {
        id: 5,
        title: 'Sensor updates',
        description: 'The platform now includes the following sensors:',
        what: `Users can look back into their past AIS refreshes within the area and time of interest.`,
        why:`Revisiting past refreshes is a requirement to understand the steps taken to detect vessel(s) of interest.`,
        how: `Each AIS feed refresh within the area of interest triggers the saving of the location GeoJSON, which then becomes available for map display and download.`,
        date: '2024-11-02',
        imageLinks: [
        ],
        expanded: false,
        category: 'sensors',
        sensorDetails: [
          {
            sensorType: 'Hyperspectral Imaging (HSI)',
            resolution: '5.3m',
            numberOfBands: 23,
            availableBands: ['G (503-570nm)', 'Y (585-615nm)', 'R (635-700nm)', 'RE (712-800nm)'],
            taskingTiers: ['Priority ', 'Standard'],
            screenshot: '../../../assets/feature-images/hsi.png',
            route: '/newtask',
            expanded: false
          },
          {
            sensorType: 'Multispectral Imaging (MSI)',
            resolution: '0.5m PAN / 2m MSI',
            numberOfBands: 5,
            availableBands: ['R', 'G', 'B', 'NIR', 'PAN'],
            taskingTiers: ['Rush', 'Priority ', 'Standard'],
            screenshot: '../../../assets/feature-images/msi-gok.png',
            route: '/newtask',
            expanded: false
          },
          {
            sensorType: 'Stereo-2D/3D',
            resolution: '0.5m PAN / 2m MSI',
            numberOfBands: 5,
            availableBands: ['R', 'G', 'B', 'NIR', 'PAN'],
            taskingTiers: ['Rush', 'Priority ', 'Standard'],
            screenshot: '../../../assets/feature-images/stereo-gok.png',
            route: '/newtask',
            expanded: false
          },
          {
            sensorType: 'C-band SAR',
            resolution: '1 to 20m (depending on the imaging mode)',
            numberOfModes: 4,
            modes: ['Spotlight', 'StripMap', 'Narrow ScanSAR', 'Extra ScanSAR '],
            frequency: 'C-band',
            polarisation: 'VV',
            taskingTiers: ['Rush', 'Priority ', 'Standard'],
            screenshot: '../../../assets/feature-images/c-sar.png',
            route: '/newtask',
            expanded: false
          },
        ],
        order: 5
      },
      {
        id: 6,
        title: 'AIS refresh history',
        description: 'You can recall and visualise refreshed AIS.',
        what: `Users can look back into their past AIS refreshes within the area and time of interest.`,
        why:`Revisiting past refreshes is a requirement to understand the steps taken to detect vessel(s) of interest.`,
        how: `Each AIS feed refresh within the area of interest triggers the saving of the location GeoJSON, which then becomes available for map display and download.`,
        date: '2024-11-25',
        imageLinks: [
          {link: '../../../assets/feature-images/ais-refresh.png', route: '/collections'},        
        ],
        expanded: false,
        category: 'platform',
        order: 6
      },
      {
        id: 7,
        title: 'Flood inundation',
        description: 'SAR sensor tasking results in flood inundation information.',
        what: `Users can receive flood extent information when tasking over floods.`,
        why:`Turning SAR data into accurately identifying flood inundation is laborious. We have made it easy by automatically extracting the information.`,
        how: `Users can request a flood inundation feed like any other feed when tasking any SAR sensor on the platform. The platform delivers a series of polygons as a GeoJson when extracting the flood map from the SAR data. The information delivered automatically removes any existing water bodies.`,
        date: '2024-12-27',
        imageLinks: [
          {link: '../../../assets/feature-images/flood-detection.png', route: '/collections'},        
        ],
        expanded: false,
        category: 'platform',
        order: 7
      },
      {
        id: 8,
        title: 'Multi-user management',
        description: 'The registered user on the platform can create additional users for tasking access to the platform.',
        what: `The registered user can create additional users without sharing his/her email address and eliminating the need to create a generic email for group sharing.`,
        why:`Organisations have multiple tasking users, and email sharing does not identify the tasker. The same email may not be used to complete simultaneous tasks if different individuals share it. Besides, organisational users may have different tasking requirements that must be tracked separately.`,
        how: `The primary user has access to user management under the users menu on the left menu panel. The registered user can create additional users, and all newly created users, including the registered user, will now share the account and any balance attached to it. The account is now at the organisational level. All tasks will continue to generate invoices, receipts, and credit notes regardless of who tasked within the organisation.
        Only the registered user can deposit money into the account and generate a quotation if required before doing so. Only the registered user can add additional users within the organisation.`,
        date: '2025-01-28',
        imageLinks: [
          {link: '../../../assets/feature-images/multiuser.png', route: '/user-manangement'},        
        ],
        expanded: false,
        category: 'platform',
        order: 8
      },
      {
        id: 9,
        title: 'Access platform without deposit',
        description: 'Access to the Eartheye tasking and feeds platform is now available without a minimum deposit.',
        what: `Customers can now register to access a limited number of sensors and platform capabilities without any deposit. Six sensors are available via the pay-per-task, no-deposit method using payment gateways.
        These sensors are:`,
        itemList: [
          {
            name: '2, sub-meter optical sensors'
          },
          {
            name: '2, sub-meter SAR sensors'
          },
          {
            name: '1, over a meter optical sensor'
          },
          {
            name: '1, 2-min refresh AIS sensor'
          },
        ],
        why:`We enabled the no-deposit access method to make earth observation data widely accessible. This method also serves customers who want to “dip their toes in the water” before using the platform fully.`,
        how: `When registering on the platform, customers can choose between with and without deposit options. 
        Regardless of the choice made to join the platform, a know-your-customer (KYC) verification process will follow. 
        Once approved, a secure link will be sent to complete the registration. When signing up as no-deposit customers, limited platform capabilities such as a single workflow will also be enabled, in addition to the limited number of sensors.  `,
        date: '2025-02-03',
        imageLinks: [
          {link: '../../../assets/feature-images/ndc.png', route: '/register'},        
        ],
        expanded: false,
        category: 'platform',
        order: 9
      },
      {
        id: 10,
        title: 'Information Feeds Refund',
        description: 'Feeds can be returned if they do not meet the accuracy metric.',
        what: `Feeds are information extracted from tasked data. We build one model per feed that is expected to perform with the same accuracy on multiple sensors. 
        This is challenging, and it will take repeated and multi-sensor training to achieve the same accuracy across all the sensors universally.`,
        why:`Every model delivering a feed is shown an accuracy score when attaching the feed to a task. This sets a confident expectation. When this expectation is not met, a refund is possible.`,
        how: `Customers can return a feed ordered from the collection page and have the cost of the feed refunded. Customers will justify the return. Once a feed is returned, it should not be used.`,
        date: '2025-02-10',
        imageLinks: [
          {link: '../../../assets/feature-images/feed-refund.png', route: '/collections'},        
        ],
        expanded: false,
        category: 'platform',
        order: 10
      },  
      {
        id: 11,
        title: 'Value Added Data Products Refund',
        description: 'Value-added data products (VADP) can be returned if they do not meet the expected quality.',
        what: `VADP includes elevation products, super-resolution products, and indices. These products are source-dependent. At times, some of these products may not meet the required usability, 
        which may be due to processing or the quality of the data. If so, they can be returned for a full refund.`,
        why:`Providing a VADP with a return option gives customers confidence in attaching them to their tasking workflow. 
        These products are meant to improve the usability of satellite data by removing all friction associated with processing, including specialised software and a higher level of specialised technical skills.`,
        how: `Customers can return a VADP ordered from the collection page and have the product cost refunded. Customers will justify the return. Once a VADP is returned, it should not be used.`,
        date: '2025-02-14',
        imageLinks: [
          {link: '../../../assets/feature-images/vadp-refund.png', route: '/collections'},        
        ],
        expanded: false,
        category: 'platform',
        order: 11
      },  
      {
        id: 12,
        title: 'Sensor preview',
        description: 'Customers can see all the sensors on the platform before starting a tasking project.',
        what: `Previously, customers only saw the available sensors on the platform after they set up the project. This was inconvenient when a quick preview of available sensors was required.`,
        why:`A customer pointed to the friction the previous design had created. We created the sensor preview option right at the beginning of the new task page. `,
        how: `Customers can now click on the sensor preview option to see all the sensors available on the platform. 
        The sensors that will eventually be validated based on the area or point of interest (AOI/POI), time of interest (TOI), 
        and frequencies will continue to be shown in the sensor cost section of the new task page.`,
        date: '2025-03-11',
        imageLinks: [
          {link: '../../../assets/feature-images/sensor-preview.png', route: '/newtask'},        
        ],
        expanded: false,
        category: 'platform',
        order: 12
      },
    ];

    this.filteredFeatures = [...this.features.reverse()];
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // Listen for scroll events on the timeline container
    this.timelineContainer.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
  }

  onScroll(): void {
    const container = this.timelineContainer.nativeElement;
    const scrollPosition = container.scrollTop;
    const timelineItems = container.querySelectorAll('.update-item');

    timelineItems.forEach((item: any, index: number) => {
      const itemOffsetTop = item.offsetTop;
      const itemOffsetBottom = itemOffsetTop + item.clientHeight;

      // Adjust the scrollPosition based on container's height
      if (scrollPosition + container.clientHeight / 2 > itemOffsetTop && scrollPosition < itemOffsetBottom) {
        this.activeIndex = index;
      }
    });
  }

  // Function to toggle the expand/collapse state for the specific feature
  toggleExpand(index: number) {
    this.filteredFeatures[index].expanded = !this.filteredFeatures[index].expanded;
  }

  toggleSensor(featureIndex: number, sensorIndex: number) {
    const feature = this.filteredFeatures[featureIndex];
    if (feature && feature.sensorDetails) {
      feature.sensorDetails[sensorIndex].expanded = !feature.sensorDetails[sensorIndex].expanded;
    }
  }

  selectCategory(index: number,category: any) {
    this.selectedIndex = index;
    // Filter features based on the selected category
    if (category.featureKey === 'all') {
      this.filteredFeatures = [...this.features]; // Show all features
    } else {
      this.filteredFeatures = this.features.filter(feature => feature.category === category.featureKey);
    }
  }

}
