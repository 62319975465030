import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { PopupService } from 'src/app/services/popup.service';
import { CollectionPopupComponent } from '../collection-popup/collection-popup.component';
import { NgxMaterialTimepickerComponent } from 'ngx-material-timepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.scss']
})
export class FilterPopupComponent implements OnInit {
  formGroup: FormGroup | any;
  aisFormGroup: FormGroup | any;
  error: boolean;
  isLeftHighlighted: boolean = false;
  isRightHighlighted: boolean = true;
  maxValue = 10080;
  minValue = 10;
  clydeAisFormGroup: FormGroup | any;
  duration: any;
  isPlaying: boolean = false;
  downloadImage: string;
  darkTheme: any;
  previousIndex: number | null = null;
  currentAudio: HTMLAudioElement | null = null;
  audioFiles: any;
  minDate: Date;
  maxDate: Date;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  vesselCountries: any[];
  vesselDestinations: any[];
  vesselFormGroup: FormGroup | any;
  DateformGroup: FormGroup | any;
  vesselTypes: any[];
  isLeftSpeed: boolean = false;
  isRightSpeed: boolean = true;
  minStartDate!: Date;
  maxEndDate!: Date;
  currentLocalTime: string;
  DateFilter: any;
  isTimePickerOpen: boolean;
  @ViewChild('timepicker') timepicker: NgxMaterialTimepickerComponent;
  @ViewChild('pickers') pickers: NgxMaterialTimepickerComponent;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localStorageService: DataService,
    private apiService: ApiService,
    private popupService: PopupService
  ) {
    this.localStorageService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;
    this.downloadImage = this.darkTheme ? '../../../assets/images/icon-dark.svg' : '../../../assets/images/icon-img.svg';
    });

    if(data.type === 'audio') {
      this.audioFiles = this.data.signals
      .flatMap(item => item.audioFiles || []);
    }

    if (data.type === 'aisVessel') {
      this.vesselFormGroup = new FormGroup({
        vesselLength: new FormControl(''),
        flag: new FormControl(''),
        destination: new FormControl(''),
        vesselType: new FormControl(''),
        vesselSpeed: new FormControl(''),
      });

      if (this.data.refreshData) {
        this.vesselCountries = this.data.flagArray.sort();
        this.vesselDestinations = this.data.detinationArray.sort();
        this.vesselTypes = this.data.vesselTypeArray.sort();
      } else {
        // Extract unique flags
        const uniqueFlags = [...new Set(
          this.data.vesselData.features
            .map(feature => feature.properties.Flag)
            .filter(flag =>
              flag !== null &&
              flag !== undefined &&
              flag !== 'N/A' &&
              flag.trim() !== ''
            )
        )].sort();

        const uniqueDestinations = [...new Set(
          this.data.vesselData.features
            .map(feature => feature.properties.Destination)
            .filter(destination =>
              destination !== null &&
              destination !== undefined &&
              destination !== 'N/A' &&
              destination.trim() !== ''
            )
        )].sort();

        const uniqueVesselTypes = [...new Set(
          this.data.vesselData.features
            .map(feature => feature.properties['Ship Type'])
            .filter(type =>
              type !== null &&
              type !== undefined &&
              type !== 'N/A' &&
              type.trim() !== ''
            )
        )].sort();

        this.vesselCountries = uniqueFlags;
        this.vesselDestinations = uniqueDestinations;
        this.vesselTypes = uniqueVesselTypes;
      }

      if (this.data.filterObject != undefined || this.data.filterObject != null) {
        this.vesselFormGroup.get('vesselLength').setValue(this.data.filterObject?.length);
        this.vesselFormGroup.get('flag').setValue(this.data.filterObject?.flag);
        this.vesselFormGroup.get('destination').setValue(this.data.filterObject?.destination);
        this.vesselFormGroup.get('vesselType').setValue(this.data.filterObject?.vesselType);
        this.vesselFormGroup.get('vesselSpeed').setValue(this.data.filterObject?.vesselSpeed);

        this.isLeftHighlighted = !this.data.filterObject?.isGreater;
        this.isRightHighlighted = this.data.filterObject?.isGreater || false;

        this.isLeftSpeed = !this.data.filterObject?.isSpeedGreaterThan;
        this.isRightSpeed = this.data.filterObject?.isSpeedGreaterThan || false;
        

      }
    }

  }

  ngOnInit(): void {

    this.formGroup = new FormGroup({
      vesselLength: new FormControl('')
    });

    this.aisFormGroup = new FormGroup({
      historicalTime: new FormControl('')
    });

    this.clydeAisFormGroup = new FormGroup({
      historicalTime: new FormControl('')
    });

    this.formGroup.valueChanges.subscribe(() => {
      this.error = false;
      const length = this.formGroup.get('vesselLength').value;

      if (length < 0) {
        this.error = true;
      }
    });

    this.aisFormGroup.valueChanges.subscribe(() => {
      this.error = false;
      const duration = this.aisFormGroup.get('historicalTime').value;
      if (duration < this.minValue || duration > this.maxValue || !this.isPositiveWholeNumber(duration)) {
        this.error = true;
      }
    });

    this.clydeAisFormGroup.valueChanges.subscribe(() => {
      this.error = false;
      this.duration = '';
      this.duration = this.clydeAisFormGroup.get('historicalTime').value;
      if (this.selectedOption === 'minutes') {
        const minutesPerDay = 1440;
        this.duration = Math.ceil(this.duration/minutesPerDay);
      }
      
      if (this.duration < 0 || this.duration > this.data?.historicalDays || !this.isPositiveWholeNumber(this.duration)) {
        this.error = true;
      }
    });

    if (this.data.type === 'date') {
        const openWindow = new Date(this.data.startDate);
        const closeWindow = new Date(this.data.endDate);

        this.minDate = openWindow;
        this.maxDate = closeWindow;
    }

    if (this.data.type === 'refreshDateFilter') {
      this.setDateRange();
      this.DateformGroup = new FormGroup({
        startDate: new FormControl('',[Validators.required]),
        endDate: new FormControl('',[Validators.required]),
        startTime: new FormControl('',[Validators.required]),
        endTime: new FormControl('',[Validators.required]),
      });

      this.DateformGroup.valueChanges.subscribe(() => {
        this.isEndDateInvalid();
      });

      this.isTimePickerOpen = false;
    }
  }

  onClick(e: any, option: any) {
    let result: any = undefined;
    if (option == 2) {
      result = {
        length: this.formGroup.value.vesselLength,
        isGreater: this.isRightHighlighted
      }
    }
    this.dialogRef.close(result);
  }

  selectGreaterThan() {
    this.isLeftHighlighted = false;
    this.isRightHighlighted = true;
  }

  selectLessThan() {
    this.isLeftHighlighted = true;
    this.isRightHighlighted = false;
  }

  onSubmit(e: any, option: any) {
    let result: any = undefined;
    if (option == 2) {
      if(this.data.type == 'ais') {
        result = {
          duration: this.aisFormGroup.value.historicalTime,
        }
      } else {
        result = {
          duration: this.clydeAisFormGroup.value.historicalTime,
          type: this.selectedOption
        }
      }
    }
    this.dialogRef.close(result);
  }

  selectedOption: string | null = 'minutes';

  onCheckboxChange(event: any, option: string) {
    if (event.checked) {
      this.selectedOption = option;
    } else {
      this.selectedOption = null;
    }
    this.clydeAisFormGroup.get('historicalTime').setValue(null);
  }

  isPositiveWholeNumber(value: number): boolean {    
    return value !== null && value >= 1 && value % 1 === 0;
  }

  togglePlayPause(item: any, index: number) {
    // Check if the current audio is already playing
    if (item.isPlaying) {
      this.pauseAudio();
      item.isPlaying = false; // Set the current item's isPlaying to false
    } else {
      // If a previous audio was playing, pause it and set its isPlaying to false
      if (this.previousIndex !== null && this.previousIndex !== index) {
        this.audioFiles[this.previousIndex].isPlaying = false;
        this.pauseAudio();
      }
  
      // Get the audio URL from API and play the current audio
      this.getAudioUrlFromApi(item).then((audioUrl) => {
        this.playAudio(audioUrl, item, index);
      });
    }
  }
  
  playAudio(url: string, item: any, index: number) {
    this.currentAudio = new Audio(url); // Create a new audio element
    this.currentAudio.play();
  
    // Set the current audio's isPlaying state to true
    item.isPlaying = true;
    this.previousIndex = index;
  
    // When the audio ends, reset the isPlaying state
    this.currentAudio.onended = () => {
      item.isPlaying = false;
    };
  }

  pauseAudio() {
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio.currentTime = 0; // Reset audio to the beginning
    }
  }

  getAudio(item: any, action: string) {
    if (action === 'download') {
      // Fetch the audio file URL and trigger download
      this.getAudioUrlFromApi(item).then((audioUrl) => {
        this.downloadAudio(audioUrl,item);
      });
    }
  }

  getAudioUrlFromApi(item: any): Promise<string> {
    let taskId = this.data.sensorData.taskId;
    let sensorObj = this.data.sensorData;
    sensorObj.fileName = item.fileName;
  
    return new Promise((resolve, reject) => {
      this.apiService.getCollectionImage(taskId, sensorObj).subscribe(
        (res: any) => {
          if (res?.msg === 'Failed') {
            const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
            this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
            reject(message);
          } else if (res?.msg === 'user storage') {
            const message = 'We cannot access the audio as the delivery was made to your storage. You can access the audio via your storage.';
            this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
            reject(message);
          } else if (res?.result?.audioUrl) {
            resolve(res.result.audioUrl);
          }
        },
        (error) => reject('API error occurred')
      );
    });
  }

  getFileNameFromS3SignedUrl(signedUrl) {
    const filename = signedUrl.split('/')[signedUrl.split('/').length - 1].split('?')[0];    
    return `${filename}`;
  }
  

  downloadAudio(url: string,item) {  
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    item.isDownloading = true;

    xhr.onload = () => {
      if (xhr.status === 200) {        
        const blob = xhr.response;
        const fileName = this.getFileNameFromS3SignedUrl(url);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      }
      item.isDownloading = false;
    };

    xhr.onerror = () => {
      item.isDownloading = false;
    };
    xhr.send();
  }
  
  submitDate() {
    this.dialogRef.close(this.range.value);
  }

  onSave() {
    let result: any = undefined;
    result = {
      length: this.vesselFormGroup.value.vesselLength,
      isGreater: this.isRightHighlighted,
      destination: this.vesselFormGroup.value.destination,
      flag: this.vesselFormGroup.value.flag,
      vesselType: this.vesselFormGroup.value.vesselType,
      vesselSpeed: this.vesselFormGroup.value.vesselSpeed,
      isSpeedGreaterThan: this.isRightSpeed
    }
    
    this.dialogRef.close(result);
  }

  isFormEmpty(): boolean {
    const length = this.vesselFormGroup.get('vesselLength').value;
    const speed = this.vesselFormGroup.get('vesselSpeed').value;
    let error;
    if ((length && length < 0) || (speed && speed < 0)) {
      error = true;
    }

    return (Object.values(this.vesselFormGroup.value).every(value => value === null || value === '')) || error;
  }

  resetForm() {
    this.vesselFormGroup.reset();
  }

  selectSpeedGreaterThan() {
    this.isLeftSpeed = false;
    this.isRightSpeed = true;
  }

  selectSpeedLessThan() {
    this.isLeftSpeed = true;
    this.isRightSpeed = false;
  }

  applyFilter() {
    let dateValues = this.combineDateTime();
    if (dateValues) {
      let response = {
        startDateTime: dateValues.startDateTime,
        endDateTime: dateValues.endDateTime
      }
      this.dialogRef.close(response);
    }
  }

  setDateRange() {
    const refreshHistory = this.data.refreshHistory;
   
    if (refreshHistory.length) {
      // Convert history names to Date objects
    const dates = refreshHistory.map(item => new Date(item.name));

    // Get min and max timestamps
    const minTime = Math.min(...dates.map(d => d.getTime()));
    const maxTime = Math.max(...dates.map(d => d.getTime()));

    // Assign correctly: minStartDate should be the earliest, maxEndDate the latest
    this.minStartDate = new Date(minTime);
    this.maxEndDate = new Date(maxTime);
    }
  }

  ngAfterViewInit() {
    if (this.timepicker) {

      // Subscribe to the closed event
      this.timepicker.timeSet.subscribe(() => {
        this.isTimePickerOpen = false;
      });

      // If available, also subscribe to a cancel or closed event
      if (this.timepicker.closed) {
        this.timepicker.closed.subscribe(() => {
          this.isTimePickerOpen = false;
        });
      }

      this.pickers.timeSet.subscribe(() => {
        this.isTimePickerOpen = false;
      });

      if (this.pickers.closed) {
        this.pickers.closed.subscribe(() => {
          this.isTimePickerOpen = false;
        });
      }
    }
  }
  
  openDate(timepicker: NgxMaterialTimepickerComponent) {
    if (this.isTimePickerOpen) {
      timepicker.close();
      this.isTimePickerOpen = false;
    } else {
      this.updateCurrentLocalTime();
      timepicker.defaultTime = this.currentLocalTime;
      timepicker.open();
      this.isTimePickerOpen = true;
    }
  }

  updateCurrentLocalTime() {
    this.currentLocalTime = moment().format('HH:mm A');
  }

  onTimeSet(event: any) {
    let selectedDate = this.DateformGroup.get('startDate')?.value;
    let utcDateTime = this.onTimeChange(event, selectedDate);

    setTimeout(() => {
      this.DateformGroup.patchValue({
        startTime: utcDateTime.utcTime
      });
    }, 0);

    let startDate = utcDateTime.utcDate
    this.DateformGroup.get('startDate').setValue(startDate);
  }

  onEndTimeSet(event: any) {
    let selectedDate = this.DateformGroup.get('endDate')?.value;
    let utcDateTime = this.onTimeChange(event, selectedDate);
    
    setTimeout(() => {
      this.DateformGroup.patchValue({
        endTime: utcDateTime.utcTime
      });
    }, 0);

    let endDate = utcDateTime.utcDate
    this.DateformGroup.get('endDate').setValue(endDate);
  }

  onTimeChange(event: any, date: any) {
    const localStartDate = moment(date);
    const localTime = `${localStartDate.format('YYYY-MM-DD')} ${event}`;
    const localMoment = moment(localTime, 'YYYY-MM-DD HH:mm A');
    const utcMoment = localMoment.utc();
    const utcDate = new Date(utcMoment.format('YYYY-MM-DD'));
    const utcTime = utcMoment.format('hh:mm A');
    return { utcTime, utcDate };
  }

  combineDateTime() {
    const startDate = new Date(this.DateformGroup.value.startDate); // Already UTC
    const startTime = this.DateformGroup.value.startTime;
    const endDate = new Date(this.DateformGroup.value.endDate); // Already UTC
    const endTime = this.DateformGroup.value.endTime;
  
    // Extract hours and minutes from startTime
    const [startHours, startMinutes] = startTime.split(/[: ]/);
    const startHours24 = startTime.includes('PM') ? (+startHours % 12) + 12 : +startHours % 12;
  
    // Set UTC hours and minutes for startDate
    startDate.setUTCHours(startHours24, +startMinutes, 0, 0);
  
    // Extract hours and minutes from endTime
    const [endHours, endMinutes] = endTime.split(/[: ]/);
    const endHours24 = endTime.includes('PM') ? (+endHours % 12) + 12 : +endHours % 12;
  
    // Set UTC hours and minutes for endDate
    endDate.setUTCHours(endHours24, +endMinutes, 0, 0);
  
    return { 
      startDateTime: startDate.toISOString(), 
      endDateTime: endDate.toISOString() 
    };
  }

  handleDateChange(type) {
    if(type === 1) this.DateformGroup.get('startTime').setValue(null);
    if(type === 2) this.DateformGroup.get('endTime').setValue(null);
  }
  
  get dateFileds() {
    return this.DateformGroup.controls;
  }

  isEndDateInvalid() {
    if (this.DateformGroup.value.endDate && this.DateformGroup.value.startDate && 
      this.DateformGroup.value.startTime && this.DateformGroup.value.endTime) {
      let dateValues = this.combineDateTime();
      if (new Date(dateValues.endDateTime) <= new Date(dateValues.startDateTime)) {
        this.DateformGroup.controls['endDate'].setErrors({ endDateInvalid: true });
      } else {
        this.DateformGroup.controls['endDate'].setErrors(null);
      }
    }
  }
  
}
