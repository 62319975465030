<div class="container" *ngIf="data?.type === 'advance'">
  <div class="card pt-0 pr-0">
    <div class="main-head">
      Advanced Settings 
    </div>
    <form [formGroup]="formGroup" fxLayoutAlign="center center" class="form-vertical"  *ngIf="this.data.selectedRow?.key === OPERATOR.CAPELLA">
      <div fxLyout="column">
        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center">
          <div>
            <mat-label class="card-label">Orbital Plane </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose"
                placeholder=" Select orbital plane " class="selectDetails" formControlName="orbitalPlane">
                <mat-option *ngFor="let option of orbitalPlaneOptions"
                  [value]="option">{{option.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-label class="card-label">Orbit State </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select orbit state"
                class="selectDetails" formControlName="orbitalState">
                <mat-option *ngFor="let option of orbitalStateOptions"
                  [value]="option">{{option.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center">
          <div>
            <mat-label class="card-label">Observation Direction </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose"
                placeholder=" Select observation direction " class="selectDetails"
                formControlName="observationDirection">
                <mat-option *ngFor="let option of observationDirections"
                  [value]="option">{{option.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-label class="card-label">Polarization </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select polarization"
                class="selectDetails" formControlName="polarization">
                <mat-option *ngFor="let option of polarizations" [value]="option">{{option.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign = "start center">
          <div>
            <mat-label class="card-label">Look Angle</mat-label>
            <div fxLayout="row" fxLayoutGap="11px">
              <mat-form-field appearance="outline" class="option">
                <input matInput formControlName="lookAngleStart" placeholder="5&deg;" class="matOption" type="number" #inputField/>
              </mat-form-field>
              <span class="max-min">-</span>
              <mat-form-field appearance="outline" class="option">
                <input matInput formControlName="lookAngleEnd" placeholder="50&deg;" class="matOption" type="number"
                [min]="firstInput + 1" #inputField/>
              </mat-form-field>
            </div>
            <div class="error-container">
              <span class="error-message" *ngIf="formGroup.get('lookAngleStart').hasError('min') || formGroup.get('lookAngleStart').hasError('max') || formGroup.get('lookAngleEnd').hasError('max') || this.maxValue">
                Min and max values for Look Angle is between 5 to 50
              </span>
              <span class="error-message" *ngIf="error && (!formGroup.get('lookAngleStart').hasError('min') && !formGroup.get('lookAngleStart').hasError('max') && !formGroup.get('lookAngleEnd').hasError('max') && !this.maxValue)">
                Min value should not be greater than max value
              </span>
              <span class="error-message" *ngIf="this.wholeNumber && (!formGroup.get('lookAngleStart').hasError('min') && !formGroup.get('lookAngleStart').hasError('max') && !formGroup.get('lookAngleEnd').hasError('max') && !this.maxValue) && !error">
                Look Angle should be a whole number
              </span>
            </div>
          </div>
        </div>

        <div class="btns" fxLayoutAlign="center center" fxLyout="row">
          <button mat-stroked-button (click)="onNoClick()">Close</button>
          <button style="margin-left: 13px !important;" mat-stroked-button [disabled]="formGroup.invalid || maxValue || data?.openFrom === 'preview'"
           (click)="submit()" class="submit">Save</button>
        </div>
      </div>
    </form>

    <form [formGroup]="advanceForm" fxLayoutAlign="center center" class="form-vertical" *ngIf="this.data.selectedRow?.key === OPERATOR.KOMPSAT">
      <div fxLyout="column">
        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center"
        *ngIf="(this.data.selectedRow?.sensor === 'SAR' || data.selectedRow?.sensor === 'InSAR')">
          <div>
            <mat-label class="card-label">Polarization </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose"
                placeholder=" Select polarization " class="selectDetails" formControlName="sarPolarization">
                <mat-option *ngFor="let option of data.selectedRow?.sarPolarization"
                  [value]="option.value">{{option.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-label class="card-label">Orbit Direction </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select orbit direction"
                class="selectDetails" formControlName="sarOrbitDirection">
                <mat-option *ngFor="let option of data.selectedRow?.sarOrbitDirection"
                  [value]="option.value">{{option.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLyout="row" fxLayoutGap="30px" *ngIf="(this.data.selectedRow?.sensor === 'SAR' || data.selectedRow?.sensor === 'InSAR')">
          <div>
            <mat-label class="card-label">Product Ancillary </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpanel" placeholder="Select product ancillary"
                class="selectDetails" formControlName="productAnciallary">
                <mat-option *ngFor="let option of data.selectedRow?.productAnciallary" [value]="option.value">{{option.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div fxLyout="row" fxLayoutGap="30px" *ngIf="(this.data.selectedRow?.sensor === 'MSI' || data.selectedRow?.sensor === 'Stereo')">
          <div>
            <mat-label class="card-label">Bit Depth </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpanel" placeholder="Select bit depth"
                class="selectDetails" formControlName="bitDepth">
                <mat-option *ngFor="let option of data.selectedRow?.bitDepth" [value]="option.value">{{option.value}}
                  <span>
                    <img style="margin-left: 3px" width="15px" [src]="tooltipImagePath"
                      [matTooltip]="option.tooltip"
                      matTooltipPosition="right" /></span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="btns" fxLayoutAlign="center center" fxLyout="row">
          <button mat-stroked-button (click)="onNoClick()">Close</button>
          <button style="margin-left: 13px !important;" mat-stroked-button
           (click)="save()" class="submit" [disabled]="data?.openFrom === 'preview'">Save</button>
        </div>
      </div>
    </form>

    <form [formGroup]="umbraAdvanceForm" fxLayoutAlign="center center" class="form-vertical" *ngIf="this.data.selectedRow?.key === OPERATOR.UMBRA">
      <div fxLyout="column">
        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center">
          <div>
            <mat-label class="card-label">Polarization</mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpanel" placeholder="Select polarization"
                class="selectDetails" formControlName="sarPolarization">
                <mat-option *ngFor="let option of data.selectedRow?.sarPolarization" [value]="option.value">{{option.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-label class="card-label">Target Azimuth Angle</mat-label>
            <div fxLayout="row" fxLayoutGap="11px">
              <mat-form-field appearance="outline" class="option">
                <input matInput formControlName="targetAzimuthAngleMin" placeholder="0&deg;" class="matOption" type="number" #inputField/>
              </mat-form-field>
              <span class="max-min">-</span>
              <mat-form-field appearance="outline" class="option">
                <input matInput formControlName="targetAzimuthAngleMax" placeholder="360&deg;" class="matOption" type="number"
                [min]="firstInput + 1" #inputField/>
              </mat-form-field>
            </div>
            <div class="error-container">
              <span class="error-message" *ngIf="umbraAdvanceForm.get('targetAzimuthAngleMin').hasError('min') || umbraAdvanceForm.get('targetAzimuthAngleMin').hasError('max') || umbraAdvanceForm.get('targetAzimuthAngleMax').hasError('max') || this.maxValue">
                Min and max values for Azimuth Angle is between 0 to 360
              </span>
              <span class="error-message" *ngIf="error && (!umbraAdvanceForm.get('targetAzimuthAngleMin').hasError('min') && !umbraAdvanceForm.get('targetAzimuthAngleMin').hasError('max') && !umbraAdvanceForm.get('targetAzimuthAngleMax').hasError('max') && !this.maxValue)">
                Min value should not be greater than max value
              </span>
              <span class="error-message" *ngIf="this.wholeNumber && (!umbraAdvanceForm.get('targetAzimuthAngleMin').hasError('min') && !umbraAdvanceForm.get('targetAzimuthAngleMin').hasError('max') && !umbraAdvanceForm.get('targetAzimuthAngleMax').hasError('max') && !this.maxValue) && !error">
                Target Azimuth Angle should be a whole number
              </span>
            </div>
          </div>
        </div>

        <div class="btns" fxLayoutAlign="center center" fxLyout="row">
          <button mat-stroked-button (click)="onNoClick()">Close</button>
          <button style="margin-left: 13px !important;" mat-stroked-button [disabled]="umbraAdvanceForm.invalid || maxValue || data?.openFrom === 'preview'"
           (click)="saveChanges()" class="submit">Save</button>
        </div>
      </div>
    </form>

    <form [formGroup]="ecursFormGroup" fxLayoutAlign="center center" class="form-vertical"  *ngIf="this.data.selectedRow?.key === OPERATOR.ECURS">
      <div fxLyout="column">
        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center">
          <div>
            <mat-label class="card-label">Looking Direction </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select looking direction"
                class="selectDetails" formControlName="sarOrbitDirection">
                <mat-option *ngFor="let option of data.selectedRow?.sarOrbitDirection"
                  [value]="option.value">{{option.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-label class="card-label">Orbit Type </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select orbit type"
                class="selectDetails" formControlName="sarOrbitType">
                <mat-option *ngFor="let option of data.selectedRow?.sarOrbitType"
                  [value]="option.value">{{option.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="btns" fxLayoutAlign="center center" fxLyout="row">
          <button mat-stroked-button (click)="onNoClick()">Close</button>
          <button style="margin-left: 13px !important;" mat-stroked-button
           (click)="onSave()" class="submit" [disabled]="data?.openFrom === 'preview'">Save</button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="container" *ngIf="data?.type === 'allocation'">
  <div class="card pt-0 pr-0">
    <div class="main-head">
      Allocation Settings
    </div>

    <form [formGroup]="allocationForm" fxLayoutAlign="center center" class="allocation-form-vertical">
      <div fxLyout="column">
        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="">
          <div>
            <mat-label class="balance-label">Balance Amount: <span>{{availableBalance}}</span></mat-label>
          </div> 
        </div>
        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center" style="margin-top:30px">
          <div>
            <mat-label class="card-label">Sensor Type </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matselectopenpanel"
                placeholder=" Select sensor type " class="selectDetails" formControlName="sensorType"
                (selectionChange)="sensorChange($event.value, false)">
                <mat-option *ngFor="let option of SensorsTypes"
                  [value]="option">{{option}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-label class="card-label">Sensor Name </mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matselectopenpanel" [placeholder]="selectedSensorType ? selectedSensorType : 'Select sensor name'"
                class="selectDetails" formControlName="sensorName" (selectionChange)="selectSensor($event.value, false)">
                <mat-select-trigger>{{selectedSensorType}}</mat-select-trigger>
                <mat-option *ngFor="let option of sensorList"
                  [value]="option">
                  <div class="align">
                    <span>{{ option.name }}{{option.sensor === "Tristereo" || option.sensor === "Stereo" ? ' - ' + option.sensor : ''}}</span>
                    <span *ngIf="option.resolution"> ({{
                      option.resolution
                      }}) </span>
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center">
          <div>
            <mat-label class="card-label">Tasking Tiers</mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <mat-select [disableOptionCentering]="true" panelClass="matselectopenpanel"
                placeholder="Select tasking tiers" class="selectDetails" formControlName="taskingTier"
                (selectionChange)="taskingTierChange($event.value, false)">
                <mat-option *ngFor="let option of taskingTiers"
                  [value]="option">{{ option.name.charAt(0).toUpperCase() + option.name.slice(1) }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-label class="card-label">Unit Cost</mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <input matInput formControlName="unitCost" placeholder="Unit cost" class="matOption" type="name"  readonly/> 
            </mat-form-field>  
          </div>
        </div>
        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center">
          <div>
            <mat-label class="card-label">Amount (%)</mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <input matInput formControlName="amountInPercentage" placeholder="1% - 100%" class="matOption" type="name" 
              (keyup)="valChng($event)" (keypress)="numberOnly($event)"  (focusout)="onFocusOut()" />
              <mat-error *ngIf="fields.amountInPercentage.touched && fields.amountInPercentage.errors"
                style="color: #EF3061;font-size: 12px; font-weight: 600;margin-left: -10px;">
                <mat-error *ngIf="fields.amountInPercentage.errors.pattern"> Please enter a valid number</mat-error>
                <mat-error *ngIf="fields.amountInPercentage.errors.required"> Please enter a valid number</mat-error>
                <mat-error *ngIf="allocationForm.get('amountInPercentage').hasError('min')"> Minimum amount allocation should be 1%</mat-error>
                <mat-error *ngIf="allocationForm.get('amountInPercentage').hasError('max')"> Maximum amount allocation should be
                  {{this.availableAmount.toFixed(2) }}%</mat-error>
                <mat-error *ngIf="allocationForm.get('amountInPercentage').hasError('exceededAmount')">
                  No sufficient amount to allocate this sensor
                </mat-error>
              </mat-error>
            </mat-form-field>          
          </div>

          <div>
            <mat-label class="card-label">Number of Tasks</mat-label>
            <mat-form-field appearance="outline" class="optionDetails">
              <input matInput formControlName="noOfTasks" placeholder="Number of tasks" class="matOption" type="name" #inputField readonly />
              <mat-error *ngIf="fields.noOfTasks.touched && fields.noOfTasks.errors" style="color: #EF3061;font-size: 12px; font-weight: 600;margin-left: -10px;">
                <mat-error *ngIf="allocationForm.get('noOfTasks').hasError('min')">
                  Number of tasks must be greater than or equal to 1
                </mat-error>
            </mat-error>
            </mat-form-field>
           
          </div>
        </div>
       
        <div class="buttons" fxLayoutAlign="center center" fxLyout="row">
          <button mat-stroked-button (click)="onNoClick()">Close</button>
          <button style="margin-left: 13px !important;" mat-stroked-button
           (click)="saveData()" class="submit" [disabled]="allocationForm.invalid || allocationError || saveClick || data?.openFrom === 'preview'">Save</button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="container" *ngIf="data?.type === 'userManagement'">
  <div class="card pt-0 pr-0">
    <div class="main-head" style="margin-top: 10px;">
      <span *ngIf="!this.data.selectedUser" style="margin-right: 590px;">Add New User</span>
      <span *ngIf="this.data?.selectedUser" style="margin-right: 629px;">Edit User</span>
    </div>

    <form [formGroup]="usersForm" fxLayoutAlign="center center" class="form-vertical">
      <div fxLyout="column">
        <div *ngIf="!data.selectedUser" fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center">
          <div>
            <mat-label class="card-label">First Name<span>*</span></mat-label>
            <mat-form-field appearance="outline" class="custom-width-height">
              <input matInput placeholder="Enter first name" formControlName="firstName" (keydown)="Space($event)"
                (focus)="onFocusProject('firstName')" (keypress)="keyPressName($event)" />
              <mat-error *ngIf="usersForm.get('firstName').hasError('required') && (usersForm.get('firstName').touched || usersForm.get('firstName').dirty)"
                class="error-messages">
                First Name is required</mat-error>
              <mat-error *ngIf="usersForm.get('firstName').hasError('minlength')&& (usersForm.get('firstName').touched || usersForm.get('firstName').dirty)"
                class="error-messages">At
                least 3 characters long</mat-error>
              <mat-error *ngIf="usersForm.get('firstName').hasError('maxlength')&& (usersForm.get('firstName').touched || usersForm.get('firstName').dirty)"
                class="error-messages">Maximum 50 characters long</mat-error>
            </mat-form-field>
          </div>

          <div>
            <mat-label class="card-label">Last Name<span>*</span></mat-label>
            <mat-form-field appearance="outline" class="custom-width-height">
              <input matInput placeholder="Enter last name" formControlName="lastName" (keydown)="Space($event)"
                (focus)="onFocusProject('lastName')" (keypress)="keyPressName($event)" />
              <mat-error *ngIf="usersForm.get('lastName').hasError('required') && usersForm.get('lastName').touched"
                class="error-messages">
                Last Name is required</mat-error>
              <mat-error *ngIf="usersForm.get('lastName').hasError('maxlength')&& usersForm.get('lastName').touched"
                class="error-messages">Maximum 50 characters long</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center">
          <div>
            <mat-label class="card-label">Display Name<span>*</span></mat-label>
            <mat-form-field appearance="outline" class="custom-width-height">
              <input matInput placeholder="Enter display name" formControlName="displayName" (keydown)="Space($event)"
                (focus)="onFocusProject('displayName')" (keypress)="keyPressName($event)" />
              <mat-error
                *ngIf="usersForm.get('displayName').hasError('required') && usersForm.get('displayName').touched"
                class="error-messages">
                Display Name is required</mat-error>
              <mat-error
                *ngIf="usersForm.get('displayName').hasError('minlength')&& usersForm.get('displayName').touched"
                class="error-messages">At
                least 3 characters long</mat-error>
              <mat-error
                *ngIf="usersForm.get('displayName').hasError('maxlength')&& usersForm.get('displayName').touched"
                class="error-messages">Maximum 15 characters long</mat-error>
            </mat-form-field>
          </div>

          <div>
            <mat-label class="card-label">Department<span>*</span></mat-label>
            <mat-form-field appearance="outline" class="custom-width-height">
              <input matInput placeholder="Enter department" formControlName="department" (keydown)="Space($event)"
                (focus)="onFocusProject('department')"/>
              <mat-error *ngIf="usersForm.get('department').hasError('required') && usersForm.get('department').touched"
                class="error-messages">
                Department is required</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="!data.selectedUser" fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center">
          <div>
            <mat-label class="card-label">Email<span>*</span></mat-label>
            <mat-form-field appearance="outline" class="custom-width-height">
              <input matInput placeholder="Enter email" formControlName="email" (keydown)="Space($event)"
                (focus)="onFocusProject('email')" (keyup)="emailCheck()" />
              <mat-error *ngIf="usersForm.get('email').hasError('required') && usersForm.get('email').touched"
                class="error-messages">
                Email is required</mat-error>
              <mat-error *ngIf="usersForm.get('email').hasError('pattern')&& usersForm.get('email').touched"
                class="error-messages">
                Please use an official email address</mat-error>
                <mat-error *ngIf="usersForm.get('email').hasError('domainMismatch') && usersForm.get('email').touched
                && !usersForm.get('email').hasError('pattern') && !usersForm.get('email').hasError('exist')"
                class="error-messages">
                Please ensure that each additional user's email address domain is the same as yours.
                <a href="javascript:void(0)" (click)="toggleMore()" class="more-link" *ngIf="!showMore">More</a>
                <span *ngIf="showMore" @expandCollapse>
                  For example, if yours were abc@xyz.com, each additional user’s email must also end with @xyz.com
                </span>
                <a href="javascript:void(0)" (click)="toggleMore()" class="less-link" *ngIf="showMore">Less</a>
                </mat-error>
                <mat-error *ngIf="usersForm.get('email').hasError('exist')"
                class="error-messages">
                A user exists with this email address</mat-error>
            </mat-form-field>
          </div>

          <div>
            <mat-label class="card-label">Password<span>*</span></mat-label>
            <mat-form-field appearance="outline" class="custom-width-height">
              <input matInput [type]="showPassword ? 'password' : 'text'" placeholder="Enter password" formControlName="password" (keydown)="Space($event)"
                (focus)="onFocusProject('password')" autocomplete="new-password" />
                <mat-icon style="opacity: 0.3;" matSuffix (click)="togglePasswordVisibility()">
                  {{ showPassword ? "visibility_off" : "visibility" }}
                </mat-icon>
              <mat-error *ngIf="usersForm.get('password').hasError('required') && usersForm.get('password').touched"
                class="error-messages">
                Password is required</mat-error>
              <mat-error
                *ngIf="(usersForm.get('password').hasError('minlength') || usersForm.get('password').hasError('pattern')) && usersForm.get('password').touched"
                class="error-messages">
                <span
                  *ngIf="(usersForm.get('password').hasError('minlength') && usersForm.get('password').hasError('pattern'))">Must
                  contain mimimum of 8 characters with at least one capital letter and a special
                  character</span>
                <span
                  *ngIf="(usersForm.get('password').hasError('minlength') && !usersForm.get('password').hasError('pattern'))">Must
                  contain mimimum of 8 characters</span>
                <span
                  *ngIf="(!usersForm.get('password').hasError('minlength') && usersForm.get('password').hasError('pattern'))">Must
                  contain at least one capital letter and a special
                  character</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="btns" fxLayoutAlign="center center" fxLyout="row"
        [ngStyle]="{'padding-top': showMore ? '25px' : ''}">
          <button mat-stroked-button (click)="onNoClick()">Close</button>
          <button style="margin-left: 13px !important;" mat-stroked-button (click)="saveUser()"
           [disabled]="usersForm.invalid" class="submit">Save</button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="container" *ngIf="data?.type === 'bandSelection'">
  <div class="card pt-0 pr-0">
    <div class="main-head task-head">
      <h4>Required!</h4>
    </div>
    <p class="para">
      Please choose number of bands and number of windows from the drop-down below. 
    </p>

    <form [formGroup]="bandForm" style="margin-top: 3%;">
      <div fxLyout="row" fxLayoutGap="30px" fxLayoutAlign="center center"
      style="margin-bottom: 35px;">
        <div>
          <mat-label class="card-label">Number of bands </mat-label>
          <mat-form-field appearance="outline" class="optionDetails">
            <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose"
              class="selectDetails" formControlName="bandCount">
              <mat-option *ngFor="let option of bands"
                [value]="option">{{option}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-label class="card-label">Number of windows </mat-label>
          <mat-form-field appearance="outline" class="optionDetails">
            <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose"
              class="selectDetails" formControlName="windowCount"
              (selectionChange)="onWindowCountChange()">
              <mat-option *ngFor="let option of windowOptions"
                [value]="option">{{option}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <table mat-table [dataSource]="rows">
  
        <!-- Window No. Column -->
        <ng-container matColumnDef="windowNo">
          <th mat-header-cell *matHeaderCellDef style="width: 12%;text-align: center;"> Window </th>
          <td mat-cell *matCellDef="let row" style="width: 12%;text-align: center;"> {{ row.windowNo }} </td>
        </ng-container>
        
        <!-- No. of Bands Column -->
        <ng-container matColumnDef="numBands">
          <th mat-header-cell *matHeaderCellDef style="width: 16%;"> Number of Bands </th>
          <td mat-cell *matCellDef="let row; let i = index" style="width: 16%;">
            <input [value]="row.numBands" (input)="onNumBandsChange($event, i)" 
                   class="border p-1 w-20" (keypress)="keyPressCheck($event)" 
                   [disabled]="!isNumBandsEditable(i)" style="width: 70px;">
          </td>
        </ng-container>
        
        <!-- Band Selection Column -->
        <ng-container matColumnDef="bandSelection">
          <th mat-header-cell *matHeaderCellDef style="width: 20%;"> Band Names </th>
          <td mat-cell *matCellDef="let row; let i = index" style="width: 20%;">
            <mat-select *ngIf="isRowEditable(i)" 
                        [disableOptionCentering]="true" panelClass="matopenpurpose custom-placeholder" 
                        class="selectDetails" placeholder="Select Band" 
                        (selectionChange)="onBandSelect($event.value, i)">
              <mat-option *ngFor="let band of getAvailableBands(i)" 
                          [value]="band" [disabled]="isDisabled(band) || !row.numBands"
                          [ngClass]="{'allocated-band': row.allocatedBands.includes(band)}">
                <div style="display: flex; justify-content: space-between;">
                  <span>{{ band?.bandName }}</span>
                  <span>{{ band?.centralWavelength }} (nm)</span>
                </div>
              </mat-option>
            </mat-select>
            <span *ngIf="!isRowEditable(i)">
              <mat-select 
                        [disableOptionCentering]="true" panelClass="matopenpurpose custom-placeholder" 
                        class="selectDetails" [placeholder]="row.allocatedBands.length ? 
                        (row.allocatedBandNames.length > 2 ? 
                        (row.allocatedBandNames[0] + ' - ' + row.allocatedBandNames[row.allocatedBandNames.length - 1]) :
                        row.allocatedBandNames.join(', '))
                        : 'Complete previous row first'">
              <mat-option *ngFor="let band of availableBands" 
                          [value]="band" disabled="true">
                <div style="display: flex; justify-content: space-between;">
                  <span>{{ band?.bandName }}</span>
                  <span>{{ band?.centralWavelength }} (nm)</span>
                </div>
              </mat-option>
            </mat-select>
            </span>
          </td>
        </ng-container>
        
        <!-- Allocated Bands Column -->
        <ng-container matColumnDef="allocatedBands">
          <th mat-header-cell *matHeaderCellDef style="width: 20%;"> Allocated Bands </th>
          <td mat-cell *matCellDef="let row" style="width: 20%;">
            <ng-container *ngIf="row.allocatedBandNames.length > 2; else normalDisplay">
              {{ row.allocatedBandNames[0] }} - {{ row.allocatedBandNames[row.allocatedBandNames.length - 1] }}
            </ng-container>
            <ng-template #normalDisplay>
              {{ row.allocatedBandNames.join(', ') }}
            </ng-template>
          </td>
        </ng-container>
        
        <!-- Allocated Wavelengths Column -->
        <ng-container matColumnDef="allocatedWavelengths">
          <th mat-header-cell *matHeaderCellDef style="width: 20%;"> Central Wavelengths (nm) </th>
          <td mat-cell *matCellDef="let row" style="width: 20%;">
            <ng-container *ngIf="row.allocatedWavelengths.length > 2; else normalDisplay">
              {{ row.allocatedWavelengths[0] }} - {{ row.allocatedWavelengths[row.allocatedWavelengths.length - 1] }}
            </ng-container>
            <ng-template #normalDisplay>
              {{ row.allocatedWavelengths.join(', ') }}
            </ng-template>
          </td>
        </ng-container>
        
        <!-- Table Header and Row Definition -->
        <tr mat-header-row *matHeaderRowDef="['windowNo', 'numBands', 'bandSelection', 'allocatedBands', 'allocatedWavelengths']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['windowNo', 'numBands', 'bandSelection', 'allocatedBands', 'allocatedWavelengths'];"></tr>
      </table>

      <div class="error-container">
        <span class="error-message" *ngIf="bandeError">
          {{this.errorMessage}}
        </span>
        <span class="error-message" *ngIf="selectedBandNames && selectedBandNames.length > 0 && selectedBandNames?.length != bandForm.value.bandCount && !bandeError">
          You chose {{bandForm.value.bandCount}} bands; however, you are attempting to add  more or less.
        </span>
      </div>

      <div class="btns" fxLayoutAlign="center center" fxLyout="row">
        <button mat-stroked-button (click)="resetForm()">Clear</button>
        <button style="margin-left: 13px !important;" mat-stroked-button (click)="onNoClick()">Close</button>
        <button style="margin-left: 13px !important;" mat-stroked-button
         class="submit" (click)="saveBands()" [disabled]="(selectedBandNames?.length != bandForm.value.bandCount) || 
         bandeError">Save</button>
      </div>
    </form>
  </div>
</div>