import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { CollectionPopupComponent } from '../collection-popup/collection-popup.component';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-return-refund',
  templateUrl: './return-refund.component.html',
  styleUrls: ['./return-refund.component.scss']
})
export class ReturnRefundComponent implements OnInit {
  formGroup: any = FormGroup;
  archiveFormGroup: any = FormGroup;
  refundReason:any;
  content = '';
  phone = '';
  option = '';
  minMsgLength = 5;
  maxMsgLength = 500;
  refundRequestScenes: any = [];
  disabled = false;
  restoreOptions = [
    { label: 'Restore for view', name: 'view', value: 0, checked: false },
    { label: ' Restore for view and download', name: 'both', value: 1, checked: false }
  ];
  selectedItems: any[] = [];
  storage: boolean = true;
  demoView: boolean = false;

  constructor(private dialogRef: MatDialogRef<ReturnRefundComponent>, private formBuilder: FormBuilder, private apiService: ApiService, private popupService: PopupService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  ngOnInit(): void {
    this.formGroup = new FormGroup({
      contentField: new FormControl(this.content, [
        Validators.required,
        Validators.minLength(this.minMsgLength),
        Validators.maxLength(this.maxMsgLength),
      ]),
    });

    this.archiveFormGroup = new FormGroup({
      restoreReason: new FormControl('', [
        Validators.required,
        Validators.minLength(this.minMsgLength),
        Validators.maxLength(250),
      ]),
      restoreOption: new FormControl('', [
        Validators.required]),
    });

    if (this.data?.product === 'AIS') {
      this.formGroup.get('contentField').setValue('I would like to request the remainder of the refreshes/fetches to be refunded.');
    }
  }

  get contentField(): AbstractControl {
    return this.formGroup.get('contentField');
  }

  get restoreReason(): AbstractControl {
    return this.archiveFormGroup.get('restoreReason');
  }

  getErrorMessage(control: AbstractControl): string {
    // Don't say anything if control doesn't exist, or is valid
    if (!control || control.valid) {
      return '';
    }
    // Required always comes first
    if (control.hasError('required')) {
      return 'Cannot be empty';
    }
    if (control.hasError('minlength')) {
      const limit = control.getError('minlength').requiredLength;
      return `Must be at least ${limit} characters`;
    }
    if (control.hasError('maxlength')) {
      const limit = control.getError('maxlength').requiredLength;
      return `Must be no more than ${limit} characters`;
    }
    // Default general error message
    return 'Must be valid';
  }
  
  onCancel(): void {
    this.dialogRef.close('close');
  }

  onSubmit() {
    this.disabled = true;
    const refundRequestReason = this.contentField.value;
    const refundRequest = true;
    const sensorObj = { taskId: this.data.taskId, sensorId: this.data.sensorId, sceneId: this.data?.sceneId, refundRequest: refundRequest, refundRequestReason: refundRequestReason, product: this.data?.product,
      action: this.data?.action, feedId: this.data?.feedId};

    this.apiService.returnRefundRequest(sensorObj).subscribe((res: any) => {
      if (res?.msg === 'Success') {
        localStorage.setItem('refund-request', this.data.sceneId);
        this.dialogRef.close(this.formGroup.value);
      } else {
        const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
        this.popupService.openPopup(CollectionPopupComponent, message, '', '40%');
      }
    });

  }

  onSave() {
    this.dialogRef.close(this.archiveFormGroup.value);       
  }

  onChange(value: any) {
    this.storage = false;
    this.archiveFormGroup.value.restoreOption = value;
  }
}

