import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    selectedSensors: any[];
    selectedTask: any;
    paymentData: any;
    private valueSubject = new BehaviorSubject<boolean>(false);
    darkTheme = this.valueSubject.asObservable();
    private subscribeSubject = new BehaviorSubject<boolean>(false);
    subscribedUser = this.subscribeSubject.asObservable();
    private refreshUserDetailsSubject = new Subject<number>();
    refreshUserDetails$ = this.refreshUserDetailsSubject.asObservable();
    overlapPercentage: 0;
    overlapCount: number = 0;
    updatedTarget: any;
    private selectedSensorsKey = 'selectedSensors';

    setSelectedSensors(sensors: any[]) {
        this.selectedSensors = sensors;
        localStorage.setItem(this.selectedSensorsKey, JSON.stringify(sensors));
    }

    getSelectedSensors() {
        const sensors = localStorage.getItem(this.selectedSensorsKey);
        return sensors ? JSON.parse(sensors) : [];
    }

    setSelectedTask(task: any) {
        this.selectedTask = task;
    }

    getSelectedTask() {
        return this.selectedTask;
    }

    updateTheme(newValue: boolean) {
    this.valueSubject.next(newValue);
    }

    updateSubscription(newValue: boolean) {
        this.subscribeSubject.next(newValue);
    }
    
    setPayment (data: any) {
     this.paymentData = data;
    }

    getPayment () {
        return this.paymentData;
    }

    clearData() {
        this.paymentData = null;
    }

    triggerRefreshUserDetails(userId) {
     this.refreshUserDetailsSubject.next(userId);
    }

    setOverlapValue (data: any, count: number) {
     this.overlapPercentage = data;
     this.overlapCount = count;
    }

    getOverlapValue () {
     return this.overlapPercentage;
    }

    clearOverlapData() {
        this.overlapPercentage = 0;
        this.overlapCount = 0;
    }

    getOverlapCount() {
        return this.overlapCount;
    }

    updateTarget(target) {
     this.updatedTarget = target;     
    }

    getTarget() {
     return this.updatedTarget; 
    }

    removeTarget() {
        this.updatedTarget = null;
    }

    clearSelectedSensors() {
        this.selectedSensors = [];
        localStorage.removeItem(this.selectedSensorsKey);
    }
   
}