<div class="container" *ngIf="data.message != 'restore'">
    <div class="card">
      <div class="task-head" *ngIf="data.message === 'return-refund-success'">
        <h4>Success!</h4>
      </div>
      <div class="task-head" *ngIf="data.message !== 'return-refund-success' && data.message !== 'return-refund-accepted' && data.message !== 'return-refund-rejected'">
        <h4>Warning!</h4>
      </div>
      <div class="task-head" *ngIf=" data.message === 'return-refund-accepted' || data.message === 'return-refund-rejected'">
        <h4>Acknowledgment!</h4>
      </div>

      <p *ngIf="data.message != 'download' && data.message != 'return-refund' && data.message != 'return-refund-vadp' && data.message != 'return-refund-success' && data.message != 'return-refund-accepted' && data.message != 'return-refund-rejected' && data.message !== 'download_vadp'
      ">{{data.message}}</p>
      <p *ngIf="data.message === 'download'">
        This scene or project has already been downloaded. 
        If you need to download it again, please get in touch with <a href="mailto:support@eartheye.space" (click)="onClose()"> support@eartheye.space </a>
        or use the <a routerLink="/support" (click)="onClose()"> Contact Us </a> form.
      </p>

      <p *ngIf="data.message === 'return-refund'">
        <span *ngIf="data.type.msg === 'scene' || data.type.msg === 'Super Resolution'">The return request for the scene has already been submitted and is being reviewed.</span>
        <span *ngIf="data.type.msg === 'AIS'">The refund request for the refresh/fetch has already been submitted and is being reviewed.</span>
        <span *ngIf="data.type.msg === 'IS'">The refund request for the feed has already been submitted and is being reviewed.</span>
        <span *ngIf="data.type.msg === 'indices'">The refund request for the indices has already been submitted and is being reviewed.</span>
        Please get in touch with <a href="mailto:support@eartheye.space" (click)="onClose()"> support@eartheye.space </a> or use the<a routerLink="/support" (click)="onClose()"> Contact Us </a> form.
      </p>

      <p *ngIf="data.message === 'return-refund-vadp'">
        The refund request for the value added data product has already been submitted and is being reviewed. Please get in touch with <a href="mailto:support@eartheye.space" (click)="onClose()"> support@eartheye.space </a> or use the<a routerLink="/support" (click)="onClose()"> Contact Us </a> form.
        </p>

      <p *ngIf="data.message === 'return-refund-success'">
        Thanks, refund requested successfully.
      </p>

      <p *ngIf="data.message === 'return-refund-accepted'">
        Refund successfully credited.
      </p>

      <p *ngIf="data.message === 'return-refund-rejected'">
        Refund request is rejected.
      </p>

      <p  *ngIf="data.message === 'download_vadp'">
        This value added data product has already been downloaded. 
        If you need to download it again, please get in touch with <a href="mailto:support@eartheye.space" (click)="onClose()"> support@eartheye.space </a>
        or use the <a routerLink="/support" (click)="onClose()"> Contact Us </a> form.
      </p>
      
    </div>
  
    <div class="btns">
      <button mat-flat-button (click)="onClose()">Ok, Thanks</button>
    </div>
  </div>


  <div class="container" *ngIf="data.message === 'restore'">
    <div class="card">
      <div class="task-head">
        <h4 *ngIf="data.type.msg === 'restore-success'">Success!</h4>
        <h4 *ngIf="data.type.msg === 'restore-prerequested'">Warning!</h4>
        <h4 *ngIf="data.type.msg === 'rejected'">Acknowledgment!</h4>
      </div>

      <p *ngIf="data.type.msg === 'restore-success'">
        Your restore request has been submitted and is being reviewed. You will be notified when the requested data is restored.
      </p>

      <p *ngIf="data.type.msg === 'rejected'">
        Your restore request for <strong style="color: #1059a9" class="emails">'{{ data.type?.taskname }}'</strong> has been rejected for the following the reason: {{ data.type?.reason }}.
      </p>

      <p *ngIf="data.type.msg === 'restore-prerequested'">
        A restore request has already been made and is awaiting action from the administrator. 
        Please wait for the intimation of the outcome or contact <a href="mailto:support@eartheye.space" (click)="onClose()"> support@eartheye.space </a>
        or contact using the <a routerLink="/support" (click)="onClose()"> Contact Us </a> form.
      </p>
      
    </div>
  
    <div class="btns">
      <button mat-flat-button (click)="onClose()">Ok, Thanks</button>
    </div>
  </div>