import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { OPERATORS } from 'src/app/services/constants';
import { UserService } from 'src/app/services/user.service';
import { ValidationService } from 'src/app/services/validation.service';
import { DataService } from 'src/app/services/data.service';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?!gmail|yahoo|outlook|hotmail)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.([a-zA-Z]{2,})$/;

interface UserObj {
  userId: any;
  subUserId: any;
  action: string;
  firstName: any;
  lastName: any;
  displayName: any;
  department: any;
  email?: string;
}

interface BandInfo {
  bandName: string;
  centralWavelength: string;
  FWHM: string;
  SNR: string;
}

interface BandRow {
  windowNo: number;
  numBands: any;
  selectedBand: string;
  allocatedBands: BandInfo[];
  allocatedBandNames: string[];
  allocatedWavelengths: string[];
  isLocked: boolean
}

@Component({
  selector: 'app-advance-settings',
  templateUrl: './advance-settings.component.html',
  styleUrls: ['./advance-settings.component.scss'],
  animations: [
      trigger('expandCollapse', [
        transition(':enter', [
          style({ height: 0, opacity: 0 }),
          animate('300ms ease-out', style({ height: '*', opacity: 1 }))
        ]),
        transition(':leave', [
          animate('300ms ease-in', style({ height: 0, opacity: 0 }))
        ])
      ])
    ]
})
export class AdvanceSettingsComponent implements OnInit {
  formGroup: FormGroup | any;
  advanceForm: FormGroup | any;
  umbraAdvanceForm: FormGroup | any;
  ecursFormGroup: FormGroup | any;
  orbitalPlaneOptions: any;
  orbitalStateOptions: any;
  observationDirections: any;
  polarizations: any;
  imagingMode: any;
  productCategory: any;
  numOfLooks: any;
  minNumber = 5;
  maxNumber = 50;
  minWidth = 5;
  maxWidth = 10;
  minLength = 20;
  maxLength = 200;
  spotLooks = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  slideLooks = [1, 2, 3, 4, 5];
  firstInput: number;
  secondInput: number;
  error: boolean = false; maxValue: boolean;
  wholeNumber: boolean;
  OPERATOR: any;
  allocationForm: FormGroup | any;
  usersForm: FormGroup | any;
  allocationError: boolean = true;
  sensorData: any;
  actualValue: number;
  SensorsTypes: any;
  selectedSensorType: any;
  resolution: any;
  top_up: number = 0;
  sensorList: any;
  taskId: string | null;
  saveClick: boolean = false;
  availableAmount: number = 0;
  selectedSensor: any;
  taskingTiers: any;
  selectedOperator: any;
  selectedResolution: any;
  selectedTaskingTier: any;
  selectedTaskingTierCost: any;
  exceededAmount: boolean = false;
  insufficientData: any;
  percentageOfTopUp: number = 0;
  selectedResolutionCost: any;
  availableBalance: any;
  resolutionValue: any;
  domain: any;
  exist: boolean = false;
  showMore = false;
  tooltipImagePath: string;
  darkTheme: boolean;
  showPassword: boolean = true;

  windowOptions = Array.from({ length: 7 }, (_, i) => i + 1);
  bands = [50,95]
  bandForm: FormGroup;
  rows: BandRow[] = [];
  availableBands: BandInfo[] = [];
  lastUsedBand = 0;
  activeRowIndex: number = 0;
  selectedBandNames: string[];
  errorMessage: string;
  bandeError: boolean;

  constructor(private dialogRef: MatDialogRef<AdvanceSettingsComponent>, private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private validation: ValidationService,
    private userService: UserService,
    private localStorageService: DataService,
    private formBuilder: FormBuilder
    ) {
      if (this.data?.type === 'userManagement') {
        const existingEmail = this.data.user.email
        this.domain = existingEmail.split('@')[1];
      }

    this.localStorageService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;
      this.tooltipImagePath = this.darkTheme
        ? '../../../assets/images/union-dark.svg'
        : '../../../assets/images/union.svg';
    });

    if(this.data?.type === 'bandSelection') {
      this.bandForm = this.formBuilder.group({
        bandCount: [this.bands?.length ? this.bands[0] : null],
        windowCount: [this.windowOptions?.length ? this.windowOptions[0] : null],
      });

      this.availableBands = this.data.selectedRow.sensorBands;      
      this.onWindowCountChange();
    }   
  }

  ngOnInit(): void {
    this.OPERATOR = OPERATORS;
    this.imagingMode = this.data?.selectedRow?.selectedModes[0]?.key;
    this.productCategory = this.data?.selectedRow?.selectedONAOption?.category;
    this.availableAmount = this.data?.balance;
    this.availableBalance = this.data?.topUpBalance;
    this.resolutionValue = this.data?.selectedRow?.selectedResolution?.value;

    this.formGroup = new FormGroup({
      orbitalPlane: new FormControl(''),
      orbitalState: new FormControl(''),
      observationDirection: new FormControl(''),
      polarization: new FormControl(''),
      numOfLooks: new FormControl(''),
      lookAngleStart: new FormControl('', [Validators.min(this.minNumber), Validators.max(this.maxNumber), Validators.pattern(/^[0-9]\d*$/)]),
      lookAngleEnd: new FormControl('', [Validators.max(this.maxNumber), Validators.pattern(/^[0-9]\d*$/)]),
      lookAngle: new FormControl('')
    });

    this.advanceForm = new FormGroup({
      sarPolarization: new FormControl(''),
      sarOrbitDirection: new FormControl(''),
      productAnciallary: new FormControl(''),
      bitDepth: new FormControl('')
    });

    this.umbraAdvanceForm = new FormGroup({
      targetAzimuthAngleMin: new FormControl('', [Validators.min(0), Validators.max(360), Validators.pattern(/^[0-9]\d*$/)]),
      targetAzimuthAngleMax: new FormControl('', [Validators.max(360), Validators.pattern(/^[0-9]\d*$/)]),
      sarPolarization: new FormControl('')
    });

    this.ecursFormGroup = new FormGroup({
      sarOrbitDirection: new FormControl(''),
      sarOrbitType: new FormControl('')
    });

    this.allocationForm = new FormGroup({
      id: new FormControl(''),
      sensorType: new FormControl(''),
      sensorName: new FormControl(),
      actualAmount: new FormControl(''),
      resolution: new FormControl(''),
      operator: new FormControl(''),
      taskingTier: new FormControl(''),
      unitCost: new FormControl('', [Validators.min(1), Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
      amountInPercentage: new FormControl('', [Validators.min(1), Validators.max(this.availableAmount), Validators.minLength(1), Validators.required,
      Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
      noOfTasks: new FormControl('', [Validators.min(1), Validators.minLength(1), Validators.required,
      Validators.pattern(/^[0-9]\d*(\.\d+)?$/)])
    });

    this.usersForm = new FormGroup({
      firstName: new FormControl('',[
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        this.validation.userNameValidator,
      ]),
      lastName: new FormControl('',[
        Validators.required,
        Validators.maxLength(50),
        this.validation.userNameValidator,
      ]),
      displayName: new FormControl('',[
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(15),
        this.validation.userNameValidator,
      ]),
      department: new FormControl('',[Validators.required]),
      email: new FormControl('',[Validators.required, Validators.pattern(EMAIL_REGEX),
        this.matchDomainValidator(this.domain),
      ],),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^(?=.*[A-Z])(?=.*[!@#$%^&_+=]).*$'),
      ]),
    });

    if (this.data?.type === 'userManagement' && this.data?.selectedUser) {
       this.updateUser(this.data?.selectedUser);
    }

    this.formGroup.valueChanges.subscribe(() => {
      this.error = false;
      this.maxValue = false;
      this.wholeNumber = false;
      const startValue = this.formGroup.get('lookAngleStart').value;
      const endValue = this.formGroup.get('lookAngleEnd').value;
      this.firstInput = startValue;

      if (endValue > 50) this.maxValue = true;
      else this.maxValue = false;

      if ((endValue && !this.isPositiveWholeNumber(endValue)) || (startValue && !this.isPositiveWholeNumber(startValue))) {
        this.wholeNumber = true;
      } else this.wholeNumber = false;

      if ((startValue && startValue >= endValue) || (endValue && !startValue)) {
        this.formGroup.get('lookAngleEnd').setErrors({ 'range': true });
        this.error = true;
      } else {
        this.error = false;
        this.formGroup.get('lookAngleEnd').setErrors(null);
      }
    });

    this.umbraAdvanceForm.valueChanges.subscribe(() => {
      this.error = false;
      this.maxValue = false;
      this.wholeNumber = false;
      const startValue = this.umbraAdvanceForm.get('targetAzimuthAngleMin').value;
      const endValue = this.umbraAdvanceForm.get('targetAzimuthAngleMax').value;
      this.firstInput = startValue;

      if (endValue > 360) this.maxValue = true;
      else this.maxValue = false;

      if ((endValue && !this.isPositiveWholeNumber(endValue)) || (startValue && !this.isPositiveWholeNumber(startValue))) {
        this.wholeNumber = true;
      } else this.wholeNumber = false;

      if (((startValue && startValue >= endValue) || (endValue && startValue === null))) {
        this.umbraAdvanceForm.get('targetAzimuthAngleMax').setErrors({ 'range': true });
        this.error = true;
      } else {
        this.error = false;
        this.umbraAdvanceForm.get('targetAzimuthAngleMax').setErrors(null);
      }
    });

    this.orbitalPlaneOptions = this.apiService.orbitalPlane;
    this.orbitalStateOptions = this.apiService.orbitalState;
    this.observationDirections = this.apiService.observationDirections;
    this.polarizations = this.apiService.polarization;

    if (this.data?.selectedRow?.advanceOptions) {
      this.selectRecord(this.data.selectedRow);
    }

    this.allocationForm.valueChanges.subscribe(() => {
      if (this.isAnyFieldEmpty()) {
        this.allocationError = true;
      } else {
        this.allocationError = false;
      }
    });

    if (this.data?.type === 'allocation') {
      this.taskId = '';
      this.taskId = localStorage.getItem('taskData')
      this.getSensorTypes();

      if (this.data?.row) {
        this.editRecord(this.data.row);
      }

    }
  }

  isPositiveWholeNumber(value: number): boolean {
    return value !== null && value >= 1 && value % 1 === 0;
  }

  selectRecord(advanceOptions: any) {
    this.formGroup.get('orbitalPlane').setValue(advanceOptions?.orbitalPlane);
    this.formGroup.get('orbitalState').setValue(advanceOptions?.orbitalState);
    this.formGroup.get('observationDirection').setValue(advanceOptions?.observationDirection);
    this.formGroup.get('polarization').setValue(advanceOptions?.polarization);
    this.formGroup.get('lookAngleStart').setValue(advanceOptions?.selectedONAOption?.minONA);
    this.formGroup.get('lookAngleEnd').setValue(advanceOptions?.selectedONAOption?.maxONA);
    this.advanceForm.get('sarPolarization').setValue(advanceOptions?.selectedPolarization);
    this.advanceForm.get('sarOrbitDirection').setValue(advanceOptions?.selectedOrbitDirection);
    this.advanceForm.get('productAnciallary').setValue(advanceOptions?.selectedAnciallary);
    this.advanceForm.get('bitDepth').setValue(advanceOptions?.selectedbitDepth);
    this.umbraAdvanceForm.get('targetAzimuthAngleMin').setValue(advanceOptions?.selectedAzimuthAngleMin);
    this.umbraAdvanceForm.get('targetAzimuthAngleMax').setValue(advanceOptions?.selectedAzimuthAngleMax);
    this.umbraAdvanceForm.get('sarPolarization').setValue(advanceOptions?.selectedPolarization);
    this.ecursFormGroup.get('sarOrbitDirection').setValue(advanceOptions?.selectedOrbitDirection);
    this.ecursFormGroup.get('sarOrbitType').setValue(advanceOptions?.selectedOrbitType);
  }

  onNoClick(): void {
    this.dialogRef.close('close');
  }

  submit() {
    let lookAngle: any;

    if (this.formGroup.value?.lookAngleStart && this.formGroup.value?.lookAngleEnd) {
      lookAngle = {
        category: this.productCategory,
        minONA: parseInt(this.formGroup.value?.lookAngleStart),
        maxONA: parseInt(this.formGroup.value?.lookAngleEnd),
        value: '1',
        key: this.formGroup.value?.lookAngleStart+'-'+this.formGroup.value?.lookAngleEnd+'°'
      }
      this.formGroup.controls.lookAngle.setValue(lookAngle);
    }

    if (this.resolutionValue === '0.25m') {
      this.formGroup.controls.numOfLooks.setValue(5);
    } else if (this.resolutionValue === '0.5m' || this.resolutionValue === '1m') {
      this.formGroup.controls.numOfLooks.setValue(3);
    } else this.formGroup.controls.numOfLooks.setValue(1);

    this.dialogRef.close(this.formGroup.value);
  }

  save() {
    this.dialogRef.close(this.advanceForm.value);
  }

  saveChanges() {
    this.dialogRef.close(this.umbraAdvanceForm.value);
  }

  get fields() { return this.allocationForm.controls; }

  isAnyFieldEmpty(): boolean {
    for (const key of Object.keys(this.allocationForm.controls)) {
      if (key != 'actualAmount' && key != 'resolution' && key != 'id' && key != 'operator') {
        const controlValue = this.allocationForm.get(key).value;
        if (controlValue === null || controlValue === undefined || controlValue === '') {
          return true;
        }
      }
    }
    return false;
  }

  saveData() {
    this.saveClick = true;
    this.allocationForm.value.amountInPercentage = parseFloat(this.allocationForm?.value?.amountInPercentage);
    this.allocationForm.value.actualAmount = this.convertPercentage(this.percentageOfTopUp);
    this.allocationForm.value.operator = this.allocationForm?.value?.sensorName.name;
    this.allocationForm.value.resolution = this.allocationForm?.value?.sensorName.resolution;
    this.allocationForm.value.noOfTasks = this.allocationForm?.value?.noOfTasks;
    this.allocationForm.value.taskingTier = this.allocationForm?.value?.taskingTier?.key;
    this.allocationForm.value.unitCost = this.allocationForm?.value?.unitCost;
    if (this.allocationForm.value.id) {
      this.apiService.editAllocation(this.allocationForm.value).subscribe((res: any) => {
        this.dialogRef.close(this.allocationForm.value);
      });
    } else {
      this.apiService.addAllocation(this.allocationForm.value).subscribe((res: any) => {
        this.dialogRef.close(this.allocationForm.value);
      });
    }
  }

  sensorChange(event: any, edit) {
    this.sensorData = [];
    this.sensorList = [];
    if (!edit) {
      this.resetOnchange();
    }
    this.selectedSensorType = '';
    this.selectedSensor = event;
    this.apiService.getSensorData(event, this.taskId).subscribe((res: any) => {
      for (const [sensorIndex, sensors] of res.sensors.entries()) {
        for (const [idx, items] of sensors.resolution.entries()) {
          const operator = { name: sensors.name, resolution: items.value, cost: items.cost, sensor: sensors.sensor };
          this.sensorData.push(operator);
        }
      }

      if (this.data.allocatedData?.length > 0) {
        for (const sensor of this.sensorData) {
          const isExist = this.data.allocatedData.find(
            tableData => tableData.operator === sensor.name && tableData.resolution === sensor.resolution
          );
          if (isExist) {
            sensor.disabled = true;
          }
          this.sensorList.push(sensor);
        }
      } else {
        this.sensorList = this.sensorData;
      }
    });
  }

  getSensorTypes() {
    this.apiService.getSensorTypes(this.taskId).subscribe((res: any) => {
      if (res) {
        this.SensorsTypes = res.sensorTypes;
      }
    });
  }

  convertPercentage(percentage: number) {
    const decimalValue = percentage / 100;
    const baseValue = this.data?.topUpAmount;
    this.actualValue = decimalValue * baseValue;
    return this.actualValue;
  }

  editRecord(data: any) {
    let event = { name: data?.operator, resolution: data?.resolution }
    this.allocationForm.get('id').setValue(data._id);
    this.allocationForm.get('sensorType').setValue(data?.sensor);
    this.allocationForm.get('sensorName').setValue(event);
    this.allocationForm.get('amountInPercentage').setValue(data?.amountPercentage);
    this.allocationForm.get('actualAmount').setValue(data?.amountDollar);
    this.allocationForm.get('resolution').setValue(data?.resolution);
    this.allocationForm.get('operator').setValue(data?.operator);
    this.allocationForm.get('taskingTier').setValue(data?.taskingTier);
    this.allocationForm.get('noOfTasks').setValue(data?.numberOfTasks);
    this.allocationForm.get('unitCost').setValue(data?.unitCost);
    const edit = true;

    this.sensorChange(data?.sensor, edit);
    const eventData = {
      name: data?.operator,
      resolution: data?.resolution,
      taskingTier: data?.taskingTier,
      isTristereo: data.isTristereo,
      cost: data?.unitCost
    };
    this.selectSensor(eventData, edit);
    if (data.sensor === "Stereo") {
      this.selectedSensorType = event.name + ' - Stereo (' + event.resolution + ')';
    }
    if (data.isTristereo) {
      this.selectedSensorType = event.name + ' - Tristereo (' + event.resolution + ')';
    } else {
      this.selectedSensorType = event.name + ' (' + event.resolution + ')';
    }
  }

  resetOnchange() {
    this.allocationForm.get('taskingTier').setValue('');
    this.allocationForm.get('unitCost').setValue('');
    this.allocationForm.get('amountInPercentage').setValue('');
    this.allocationForm.get('noOfTasks').setValue('');
  }

  selectSensor(event: any, edit) {
    if (!edit) {
      this.resetOnchange();
    }
    this.selectedOperator = event.name;
    this.selectedResolution = event.resolution;
    if (this.selectedOperator == "Umbra") {
      this.selectedResolutionCost = event.cost;
    }
    if (event.sensor === "Stereo") {
      this.selectedSensorType = event.name + ' - Stereo (' + event.resolution + ')';
    } else if (event.sensor === "Tristereo" || event.isTristereo) {
      this.selectedSensorType = event.name + ' - Tristereo (' + event.resolution + ')';
    } else {
      this.selectedSensorType = event.name + ' (' + event.resolution + ')';
    }
    this.apiService.getTaskingTiersData(this.selectedSensor, event).subscribe((res: any) => {
      if (res) {
        this.taskingTiers = res.taskingTiers;
        if (edit) {
          const result = this.taskingTiers.find(tier => tier.key === event?.taskingTier);
          this.allocationForm.get('taskingTier').setValue(result);
          this.taskingTierChange(result, edit);
        }
      }

    });
  }
  taskingTierChange(event: any, edit) {
    this.selectedTaskingTier = event.value;
    this.selectedTaskingTierCost = this.selectedOperator !== "Umbra" ? event.unitcost : this.selectedResolutionCost;
    this.allocationForm.get('unitCost').setValue(this.selectedTaskingTierCost);
    if (!edit) {
      this.allocationForm.get('amountInPercentage').setValue();
      this.allocationForm.get('noOfTasks').setValue();
    }
  }

  valChng(keyInword: any): void {
    const topUpAmount = this.data.topUpAmount;
    const amountInPercentage = this.allocationForm.get('amountInPercentage').value;
    const amount = (amountInPercentage / 100) * topUpAmount;
    // Calculate noOfTasks
    const noOfTasks = amount / this.selectedTaskingTierCost;
    // Round noOfTasks to the nearest whole number
    const roundedNoOfTasks = Math.floor(noOfTasks);

    // Calculate the amount based on the rounded noOfTasks
    const roundedAmount = roundedNoOfTasks * this.selectedTaskingTierCost;
    const percentageOfTopUp = (roundedAmount / topUpAmount) * 100;
    if (percentageOfTopUp > 0) {
      this.percentageOfTopUp = percentageOfTopUp;
    } else {
      this.percentageOfTopUp = 0;
    }
    if (amount < this.selectedTaskingTierCost) {
      this.allocationForm.get('amountInPercentage').setErrors({ exceededAmount: true });
    }
    if (noOfTasks !== null && (isNaN(noOfTasks) || noOfTasks <= 0)) {
      this.allocationForm.get('noOfTasks').setValidators([Validators.min(1), Validators.required]);
    }
    if (noOfTasks !== null && (!isNaN(noOfTasks) || noOfTasks > 0)) {
      this.allocationForm.get('noOfTasks').setValue(roundedNoOfTasks);
    }
    if (this.data?.row) {
      this.availableAmount = 0;
      this.availableAmount = this.data.balance + this.data.row.amountPercentage;
      this.allocationForm.get('amountInPercentage').setValidators([
        Validators.min(1),
        Validators.max(this.availableAmount),
        Validators.minLength(1),
        Validators.required,
        Validators.pattern(/^\d+(\.\d{1,2})?$/)
      ]);
    }
  }

  onFocusOut() {
    if (Number(this.percentageOfTopUp.toFixed(2)) > 0) {
      this.allocationForm.get('amountInPercentage').setValue(Number(this.percentageOfTopUp.toFixed(2)));
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    // Check if the pressed key is a number
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      // Prevent the default action if it's not a number
      event.preventDefault();
      return false;
    }
    return true;
  }

  onSave() {
    this.dialogRef.close(this.ecursFormGroup.value);
  }

  onFocusProject(controlName: string) {
    this.usersForm.get(controlName).markAsTouched();
  }

  Space(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space") {
      event.preventDefault();
    }
  }

  // Custom validator to match the domain
  matchDomainValidator(domain: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const email = control.value;
      if (email) {
        const emailParts = email.split('@');
        if (emailParts.length === 2) { // Ensure the email has a single '@'
          const emailDomain = emailParts[1]; // Extract the domain of the new email
          const domainRegex = new RegExp(`(^|\\.)${domain.replace('.', '\\.')}$`); // Match exact or subdomain
          if (domain && !domainRegex.test(emailDomain)) {
            return { domainMismatch: true }; // Validation fails
          }
        } else {
          return { invalidEmailFormat: true }; // Handle cases without a valid '@'
        }
      }
      return null; // Validation passes
    };
  }


  saveUser() {
      this.usersForm.value.firstName = this.usersForm.value.firstName.replace(/\w\S*/g, function (txt: any) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
      this.usersForm.value.lastName = this.usersForm.value.lastName.replace(/\w\S*/g, function (txt: any) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
      this.usersForm.value.email = this.usersForm.value.email.toLowerCase();

    if (this.data?.selectedUser) {
      let userObj: UserObj = {
        userId: this.data?.user?.id,
        subUserId: this.data.selectedUser._id,
        action: 'edit',
        firstName: this.usersForm.value.firstName,
        lastName:this.usersForm.value.lastName,
        displayName: this.usersForm.value.displayName,
        department: this.usersForm.value.department,
       }
       if (this.usersForm.value.email.toLowerCase() !== this.data?.selectedUser?.email.toLowerCase()) {
        userObj.email = this.usersForm.value.email.toLowerCase();
      }
      this.userService.updateUser(userObj).subscribe((res: any) => {
        if (res?.msg === 'Email already exists') {
          this.exist = true;
          this.usersForm.get('email')?.setErrors({ exist: true });      
         } else this.dialogRef.close();   
       });
    } else {
      this.userService.addNewUser(this.usersForm.value,this.data?.user?.id).subscribe((res: any) => {
        if (res?.msg === 'Email already exists') {
         this.exist = true;
         this.usersForm.get('email')?.setErrors({ exist: true });      
        } else this.dialogRef.close();    
       });
    }
  }

  emailCheck() {
    this.exist = false;
  }

  updateUser(selectedUser: any) {    
    this.usersForm.get('firstName').setValue(selectedUser.name);
    this.usersForm.get('lastName').setValue(selectedUser.lastName);
    this.usersForm.get('displayName').setValue(selectedUser.displayname);
    this.usersForm.get('department').setValue(selectedUser.department);
    this.usersForm.get('email').setValue(selectedUser.email);
    this.usersForm.get('password').setValue(selectedUser.password);
  }

  toggleMore(): void {
    this.showMore = !this.showMore;
  }

  keyPressName(event: any) {
    let value = event.target.value;
    if (value.length === 1) {
      value = value.toUpperCase();
    }
    const pattern = /[ a-z + \ A-Z\ + \ \ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (
      (event.keyCode != 8 && !pattern.test(inputChar)) ||
      value.length > 50 ||
      (inputChar === '0' && value.length === 0)
    ) {
      event.preventDefault();
    }
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  //Band selection start
  onWindowCountChange() {
    const count = parseInt(this.bandForm.get('windowCount')?.value || '0');
    this.rows = Array.from({length: count}, (_, i) => ({
      windowNo: i + 1,
      numBands: '',
      selectedBand: '',
      allocatedBands: [],
      allocatedBandNames: [],
      allocatedWavelengths: [],
      isLocked: false // Add this to track if row is editable
    }));
    
    this.lastUsedBand = -1; // Change to -1 so first band isn't disabled
    this.activeRowIndex = 0; // Reset active row to first row
    this.bandeError = false;
    this.selectedBandNames = [];
  }

  isRowEditable(rowIndex: number): boolean {
    if (rowIndex === 0) return !this.rows[0].isLocked;
    // A row is editable until the next row's numBands is entered
    return !this.rows[rowIndex].isLocked || this.rows[rowIndex + 1]?.numBands === 0;
  }
  

  onNumBandsChange(event: any, rowIndex: number) {
    const numBands = parseInt(event.target.value || '0');
    if (numBands <= 0) return;
  
    const row = this.rows[rowIndex];
    row.numBands = numBands;
  
    // Lock the previous row when the next row gets its number of windows entered
    if (rowIndex > 0) {
      this.rows[rowIndex - 1].isLocked = true;
    }
  }
  
  onBandSelect(event: any, rowIndex: number) {
    if (!this.isRowEditable(rowIndex)) return;
    
    const selectedBandName = event.bandName;
    if (!selectedBandName) return;
    
    const row = this.rows[rowIndex];
    const bandIndex = this.availableBands.findIndex(band => band.bandName === selectedBandName);
    
    if (bandIndex === -1) return;
    
    row.selectedBand = selectedBandName;
    row.allocatedBands = this.availableBands.slice(bandIndex, bandIndex + row.numBands);
    
    row.allocatedBandNames = row.allocatedBands.map(band => band.bandName);
    row.allocatedWavelengths = row.allocatedBands.map(band => band.centralWavelength);
  
    // Only lock the row when the next row's numWindows is entered
    if (rowIndex + 1 < this.rows.length && this.rows[rowIndex + 1].numBands > 0) {
      row.isLocked = true;
      this.rows[rowIndex + 1].isLocked = false;
    }
  
    this.activeRowIndex = rowIndex + 1; // Move to the next row
    this.updateSubsequentRows(rowIndex);
    this.validateWindows();
    this.selectedBandNames = this.rows.flatMap(row => row.allocatedBandNames || []);
  }

  isNumBandsEditable(rowIndex: number): boolean {
    if (rowIndex === 0) return this.rows[rowIndex].allocatedBandNames?.length === 0;
    return (this.rows[rowIndex - 1].allocatedBandNames?.length > 0 && this.rows[rowIndex].allocatedBandNames?.length === 0); // Enable only if previous row has selected bands
  }
  
  getAvailableBands(rowIndex: number): any[] {
    if (rowIndex === 0) {
      return this.availableBands; // First row can select any band
    }
  
    const prevRowBands = this.rows[rowIndex - 1].allocatedBandNames;
    if (!prevRowBands || prevRowBands.length === 0) {
      return []; // No selection allowed if previous row has no selected bands
    }
  
    const highestSelectedBand = Math.max(...prevRowBands.map(band => parseInt(band.replace("B", ""), 10)));
  
    return this.availableBands.filter(band => parseInt(band.bandName.replace("B", ""), 10) > highestSelectedBand);
  }
  
  autoAllocateBands(rowIndex: number) {
    const row = this.rows[rowIndex];
    const startBandIndex = this.lastUsedBand + 1;
    
    if (startBandIndex + row.numBands > this.availableBands.length) {
      return; // Prevent allocation if not enough bands available
    }
    
    row.allocatedBands = this.availableBands.slice(
      startBandIndex, 
      startBandIndex + row.numBands
    );

    row.allocatedBandNames = row.allocatedBands.map(band => band.bandName);
    row.allocatedWavelengths = row.allocatedBands.map(band => band.centralWavelength);
    row.isLocked = true; // Lock the current row
    this.activeRowIndex = rowIndex + 1; // Move to next row

    this.lastUsedBand = startBandIndex + row.numBands - 1;
    this.updateSubsequentRows(rowIndex);
  }
  
  updateSubsequentRows(currentRowIndex: number) {
    for (let i = currentRowIndex + 1; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (row.numBands > 0) {
        this.autoAllocateBands(i);
      }
    }
  }
  
  isDisabled(band: BandInfo): boolean {
    const bandIndex = this.availableBands.findIndex(b => b.bandName === band.bandName);
    return bandIndex <= this.lastUsedBand;
  }

  resetForm() {
    this.lastUsedBand = 0;
    this.onWindowCountChange();
    this.bandeError = false;
    this.selectedBandNames = [];
  }

  keyPressCheck(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;

    if (!target) return; // Prevents null errors

    if (!/^[0-9]$/.test(event.key) || target.value.length >= 3) {
      event.preventDefault();
    }
  }
  
  saveBands() {
    let selectedBands = this.rows.map(({ windowNo, allocatedBandNames }) => ({ windowNo, allocatedBandNames }));

    let bandInfo = {
      bandCount : this.bandForm.value.bandCount,
      windowCount : this.bandForm.value.windowCount,
      bands: selectedBands
    }

    this.dialogRef.close(bandInfo);
  }

  validateWindows(): boolean {
    const restrictedPairs = [
      ['B44', 'B45'],
    ];
    let selectedBands = this.rows.map(({ allocatedBandNames }) => ({ allocatedBandNames }));


    for (let i = 0; i < selectedBands.length; i++) {
      const window = selectedBands[i];
      const bandNumbers = window.allocatedBandNames
        .map(band => this.getBandNumber(band))
        .sort((a, b) => a - b);

      // Check if restricted pairs exist in the same window
      for (let [band1, band2] of restrictedPairs) {
        if (window.allocatedBandNames.includes(band1) && 
            window.allocatedBandNames.includes(band2)) {
          this.errorMessage = `${band1} and ${band2} cannot be in the same window.`;
          this.bandeError = true;
          return false;
        }
      }
    }    

    this.errorMessage = '';
    this.bandeError = false;
    return true;
  }

  getBandNumber(bandName: string): number {
    return parseInt(bandName.substring(1));
  }

  //Band selection end 
}
